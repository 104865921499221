import React from "react";
import PropTypes from 'prop-types';

import { PractitionerLayoutAnonymous } from "@rdcs/dap-front-library";

const ErrorScreen = ({ title, content }) => (
    <PractitionerLayoutAnonymous>
        <div className="appLoading">
            <div className="appLoading__container">
                <h1 className="title-1">{title}</h1>
                <p className="text-3">{content}</p>
            </div>
        </div>
    </PractitionerLayoutAnonymous>
);

ErrorScreen.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

export default React.memo(ErrorScreen);
