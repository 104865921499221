import * as types from "./types";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from '../store';

export const tryFetchTreatments = () => dispatch => {
    const {
        auth: {
            access_token,
        },
        treatments: {
            list,
            listLoaded,
        }
    } = store.getState();

    if (listLoaded) {
        return Promise.resolve(list);
    }

    dispatch({ type: types.TREATMENTS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/treatment_groups" : "/dap-hcp-patient/api/v1/clinical-data/treatment-groups",
        queryParams: {
            pagination: false,
            active: true
        },
        token: access_token
    })
    .then(response => {
        const treatments = response["hydra:member"].sort((a, b) => (a.order < b.order ? -1 : 1));

        dispatch({ type: types.TREATMENTS_FETCH_SUCCESS, data: treatments });
        return treatments;
    })
    .catch(error => dispatch({ type: types.TREATMENTS_FETCH_FAILURE, data: error }));
};
