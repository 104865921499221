import React from "react";
import PropTypes from 'prop-types';

import { Animated } from "react-animated-css";
import { SelectDiagnosticComplications, Form } from "@rdcs/dap-front-library";

import { StepButtons } from "@rdcs/dap-front-library";

const DiagnosisStep2 = ({
    setFormValue,
    defaultValues,
    complications,
    prevStep,
    nextStep,
    isLoading
}) => {
    const setComplicationInstances = (selectedComplications) => (
        setFormValue(
            "complicationInstances",
            complications.map(c => ({
                complication: c["@id"],
                value: selectedComplications.includes(c.id)
            }))
        )
    );
    const getDefaultSelectedComplications = React.useMemo(() => (
        defaultValues.complicationInstances
        .filter(({ value }) => value)
        .map(c =>  complications.find(complication => complication['@id'] === c.complication)?.id || null)
        .filter(c => c !== null)
    ), []);

    return (
        <Animated animationIn="fadeIn">
            <Form className="formJustified" onSubmit={nextStep}>
                <div className="form__group">
                    <h2 className="title-3 text-uppercase">Le patient entre-t-il dans les cas suivants ?</h2>
                    <p className="text-3 text-gray mt-1">
                        Tous les cas et complications proposés sont issus de la HAS.
                    </p>
                    <SelectDiagnosticComplications
                        complications={complications}
                        defaultSelected={getDefaultSelectedComplications}
                        onChange={setComplicationInstances}
                        showInfos
                    />
                    <StepButtons
                        prevStep={prevStep}
                        isLoadingNext={isLoading}
                        prevButtonClassName='text-uppercase'
                        nextButtonClassName='text-uppercase'
                    />
                </div>
            </Form>
        </Animated>
    );
}

DiagnosisStep2.propTypes = {
    setFormValue: PropTypes.func.isRequired,
    defaultValues: PropTypes.shape({
        complicationInstances: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.bool,
            complication: PropTypes.string.isRequired,
        })).isRequired,
    }).isRequired,
    complications: PropTypes.arrayOf(PropTypes.shape({
        '@id': PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
    })).isRequired,
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default DiagnosisStep2;
