import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    profile: null,
    profileLoading: false,
    profileLoaded: 0,
    profileId: -1,

    profileCreateLoading: false,
    profileCreateError: false,
    profileEditLoading: false,
    profileEditError: false,
    profileConsentsLoading: false,
    profileConsents: []
};

function profile(state = initialState, action) {
    switch (action.type) {
        // FETCH CONSENTS
        case types.PROFILE_FETCH_CONSENTS_REQUESTED: {
            return Object.assign({}, state, { profileConsentsLoading: true });
        }
        case types.PROFILE_FETCH_CONSENTS_SUCCESS: {
            return Object.assign({}, state, {
                profileConsentsLoading: false,
                profileConsents: action.data
            });
        }
        case types.PROFILE_FETCH_CONSENTS_FAILURE: {
            return Object.assign({}, state, {
                profileConsentsLoading: false
            });
        }
        // FETCH
        case types.PROFILE_FETCH_REQUESTED: {
            return Object.assign({}, state, {
                profileLoading: true,
                profileLoaded: 0,
                profileId: -1,
            });
        }
        case types.PROFILE_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                profileLoading: false,
                profile: action.data,
                profileLoaded: Date.now(),
                profileId: action.id,
            });
        }
        case types.PROFILE_FETCH_FAILURE: {
            return Object.assign({}, state, {
                profileLoading: false,
                profile: null
            });
        }
        // CREATE
        case types.PROFILE_CREATE_REQUESTED: {
            return Object.assign({}, state, {
                profileCreateLoading: true,
                profileCreateError: false
            });
        }
        case types.PROFILE_CREATE_SUCCESS: {
            toast.success(
                "Votre profil a bien été créé. Veuillez vérifier dans votre boîte mail : " + action.data.email,
                {
                    autoClose: 10000
                }
            );
            return Object.assign({}, state, {
                profileCreateLoading: false,
                profile: action.data,
                profileCreateError: false
            });
        }
        case types.PROFILE_CREATE_FAILURE: {
            const matches = action.data.body["hydra:description"].match(/Gigya error (\d+) : {.*}/);

            if (matches) {
                action.data.violations.gigya = parseInt(matches[1]);
            }

            toast.error("Le profil n'a pas pu être créé");
            return Object.assign({}, state, {
                profileCreateLoading: false,
                profileCreateError: action.data
            });
        }
        // FINALIZE
        case types.PROFILE_FINALIZE_REQUESTED: {
            return Object.assign({}, state, {
                profileFinalizeLoading: true,
                profileFinalizeError: false
            });
        }
        case types.PROFILE_FINALIZE_SUCCESS: {
            toast.success("Votre profil a bien été finalisé : " + action.data.email);
            return Object.assign({}, state, {
                profileFinalizeLoading: false,
                profile: action.data,
                profileCreateError: false
            });
        }
        case types.PROFILE_FINALIZE_FAILURE: {
            toast.error("Le profil n'a pas pu être finalisé");
            return Object.assign({}, state, {
                profileFinalizeLoading: false,
                profileFinalizeError: action.data
            });
        }
        // EDIT
        case types.PROFILE_EDIT_REQUESTED: {
            return Object.assign({}, state, {
                profileEditLoading: true,
                profileEditError: false
            });
        }
        case types.PROFILE_EDIT_SUCCESS: {
            toast.success("Le profil a été modifié");
            return Object.assign({}, state, {
                profileEditLoading: false,
                profile: action.data,
                profileEditError: false
            });
        }
        case types.PROFILE_EDIT_FAILURE: {
            toast.error("Le profil n'a pas pu être modifié");
            return Object.assign({}, state, {
                profileEditLoading: false,
                profileEditError: action.data
            });
        }
        // DEFAULT
        default:
            return state;
    }
}

export default profile;
