import React from "react";
import PropTypes from 'prop-types';

import { connect } from "react-redux";

import { PageTitle } from "@rdcs/dap-front-library";

import AppLayout from "../../../components/Layout/AppLayout";

import Content from './Content';

const NotFoundExceptionScreen = ({ user }) => (
    <PageTitle title='Timkl - Page non trouvée'>
        {user ? (
            <AppLayout navActive={null}>
                <Content />
            </AppLayout>
        ) : (
            <div>
                <Content />
            </div>
        )}
    </PageTitle>
);

NotFoundExceptionScreen.propTypes = {
    user: PropTypes.object,
};

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(NotFoundExceptionScreen);
