import { StyleSheet, Font } from "@react-pdf/renderer";


import NunitoRegular from "../../../assets/fonts/Nunito-Regular.ttf";
import NunitoBold from "../../../assets/fonts/Nunito-Bold.ttf";
import NunitoItalic from "../../../assets/fonts/Nunito-BlackItalic.ttf";

Font.register({
  family: "Nunito",
  fonts: [
    {
      src: NunitoRegular,
      format: "truetype",
      fontWeight: 400,
      fontStyle: "normal",
      alias: "NunitoRegular",
    },
    {
      src: NunitoBold,
      format: "truetype",
      fontWeight: 700,
      fontStyle: "normal",
      alias: "NunitoBold",
    },
    {
      src: NunitoItalic,
      format: "truetype",
      fontWeight: 400,
      fontStyle: "italic",
      alias: "NunitoItalic",
    },
  ],
});

const colors = {
    /***** TIMKL chart colors *****/
    "timkl-blue": "#0069B4",
    "timkl-blue-light": "#8AB5E1",
    "timkl-blue-dark": "#00253F",
    /***** SmartPixOnline chart colors *****/
    "spo-gray-5": "#D8D8D8",
    "spo-gray-7": "#F7F7F7",
    "timkl-blue-light2" : "#e6eff8"
};

const PDFConsultsStyle = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
      },
    page: {
        fontFamily: "Nunito",
    },
    bold: {
        fontWeight : "700"
    },
    light: {
        fontWeight : "400"
    },
    container: { padding: "16px" },
    logo: { width: "auto", height: "30px"},
    title: { fontSize: "22px", fontWeight: "bold", fontFamily: "Nunito" },
    subTitle: { fontSize: "16px", fontFamily: "Nunito" },
    section: { margin: "5px 0" },
    sectionTitle: {
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: "700",
        marginBottom: "16px",
        color : colors["timkl-blue-dark"]
    },
    header:{
        width : "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    date : {
        fontSize : "10px",
        fontWeight : "400",
    },
    subHeader:{
        display : "flex",
        width : "100%",
        flexDirection: "row",
        justifyContent : "space-between",
    },
    legendContainer: {
        display : "flex",
        flexDirection: "row",
        alignItems : "center",
    },
    legendItem: {
        display : "flex",
        flexDirection: "row",
        alignItems : "center",
        marginRight : "8px"
    },
    logoLegend: {
        width :"15px",
        marginRight : "8px"
    },
    font12 : {
        fontSize : "12px"
    },
    monthContainer: {
        border: `1px solid ${colors["timkl-blue-light2"]}`,
        marginBottom : "15px",
    },
    monthTextContainer: {
        backgroundColor: colors["timkl-blue-light2"],
        fontSize: "12px",
        padding : "5px 10px"
    },
    visitContainer: {
        borderBottom: `1px solid ${colors["timkl-blue-light2"]}`,
        display: "flex",
        flexDirection: "row",
        padding : "5px 10px"
    },
    logoLine: {
        width : "15px",
        marginRight: "10px"
    },
    lineText:{
        fontSize : "12px",
        fontWeight: "400"
    },
    consult:{
        fontSize : "10px",
        fontFamily : "Nunito",
        fontWeight: "400",
        marginBottom : "14px"
    },
    rdv:{
        backgroundColor : "#FEEBDC",
        padding : "15px"
    },
    lastSection:{
        gap : "10px"
    },
    contact: {
        backgroundColor: colors["spo-gray-7"], 
        padding : "10px"
    },

    row: {
        display: "flex",
        //flex: 1, /* Cause error on ramda version 0.26.1  */
        flexDirection: "row",
        flexGrow: 1,
        width: "100%",
        flexWrap: "wrap"
    },
    flex: {
        display: "flex",
        //flex: 1, /* Cause error on ramda version 0.26.1  */
        flexDirection: "row",
        flexGrow: 1,
        marginLeft : "4px"
    },
    cell: { position: "relative", width: "50%" },
    notes: {
        padding: "8px",
        marginLeft: "10px",
        backgroundColor: colors["spo-gray-7"]
    },
    line: {
        width: "100%",
        height: "1px",
        backgroundColor: colors["spo-gray-5"],
        marginBottom: "20px"
    },
    checkbox: {
        width: "13px",
        height: "13px",
        border: `1px solid ${colors["timkl-blue"]}`,
        backgroundColor : "white",
        marginRight: "4px"
    },
    nextConsultText: {
        fontSize: "12px",
        fontFamily: "Nunito"
    }
});

export default PDFConsultsStyle;
