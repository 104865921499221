import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Select } from "@rdcs/dap-front-library";

import BaseNavList from "./BaseNavList.json";

const Nav = ({ canAccessStudyQuestion, medicalPatient, studyQuestions }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const navList = React.useMemo(() => {
        if (!medicalPatient) {
            return [];
        }

        const constructedNavList = [];

        for (const baseNavGroups of BaseNavList) {
            constructedNavList.push({
                ...baseNavGroups,
                listLink: baseNavGroups.listLink.map((itemLink) => ({ ...itemLink })),
            });
        }

        if (!(canAccessStudyQuestion && studyQuestions.filter((studyQuestion) => studyQuestion.active).length > 0)) {
            constructedNavList.forEach((navItem) => {
                navItem.listLink = navItem.listLink.filter((item) => item.id !== "study-questions");
            });
        }

        constructedNavList.forEach((navItem) => {
            navItem.listLink = navItem.listLink.map((itemLink) => ({
                ...itemLink,
                link: `/mes-patients/${medicalPatient.patient.id}/${itemLink.link}`,
            }));
        });

        return constructedNavList.map((constructedNavItem) => ({
            groupName: constructedNavItem.groupTitle,
            groupItems: constructedNavItem.listLink.map(({ title, link, icon }) => ({
                label: title,
                value: link,
                icon,
            })),
        }));
    }, [medicalPatient, studyQuestions]);

    const icon = React.useMemo(() => {
        let iconTmp = undefined;

        navList.some(({ groupItems }) => {
            iconTmp = groupItems.find(({ value }) => value === location.pathname)?.icon;

            return iconTmp !== undefined;
        });
        return iconTmp;
    }, [navList, location.pathname]);

    return (
        <nav className="headerPage__nav">
            <Select options={navList} icon={icon} defaultValue={location.pathname} onChange={navigate} round />
        </nav>
    );
};

Nav.propTypes = {
    canAccessStudyQuestion: PropTypes.bool.isRequired,
    medicalPatient: PropTypes.object,
    studyQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ questions: { studyQuestions } }) => ({ studyQuestions });

export default React.memo(connect(mapStateToProps)(Nav));
