import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { DefaultLoader, Icon, PageTitle } from "@rdcs/dap-front-library";

import { tryFetchDiagnosisRecos } from "../../../actions/diagnosis";

import PatientNavLayout from "../../../components/Patients/PatientNavLayout";

const PatientsNavInfosScreen = ({ recos, tryFetchDiagnosisRecos }) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);

    const [loading, setLoading] = React.useState(true);

    const onLoad = React.useCallback(({ patient: { lastDiagnostic } }) => {
        tryFetchDiagnosisRecos(lastDiagnostic).finally(() => setLoading(false));
    }, []);

    const recosInfo = React.useMemo(() => recos.filter((item) => item.recommendation.type === "information"), [recos]);

    return (
        <PageTitle title="Timkl - Mes Patients">
            <PatientNavLayout patientId={patientId} onLoad={onLoad}>
                <div className="informationsList">
                    {loading ? (
                        <DefaultLoader />
                    ) : (
                        recosInfo.map((info) => (
                            <div key={info.id} className="informationsList__item">
                                <a
                                    href={info.recommendation.source}
                                    className="informationsList__item-sourceLink"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Icon name="puce-reco" />
                                    <Icon name="pdf" className="sourceIcon" />
                                </a>
                                <div className="informationsList__item-title">{info.recommendation.title}</div>
                                <div className="informationsList__item-description">
                                    {info.recommendation.description}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </PatientNavLayout>
        </PageTitle>
    );
};

PatientsNavInfosScreen.propTypes = {
    recos: PropTypes.arrayOf(PropTypes.object).isRequired,
    tryFetchDiagnosisRecos: PropTypes.func.isRequired,
};

const mapStateToProps = ({ diagnosis: { recos } }) => ({ recos });

const mapDispatchToProps = {
    tryFetchDiagnosisRecos,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientsNavInfosScreen);
