import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import { userCan } from "../../../reducers/auth";

import { Icon, ImageStatus } from "@rdcs/dap-front-library";

import defaultUserImage from "@rdcs/dap-front-library/static/images/default-user.png";

import PatientReport from "./PatientReport";
import { useSelector } from "react-redux";

// import FollowUpQuestionnaire from "./FollowUpQuestionnaire";

const Header = ({ patient, lastDiagnosis }) => {
    const reports = useSelector((state) => state.reports);

    const sortedReports = [...reports.list].sort((a, b) => new Date(b.date) - new Date(a.date));
    const mostRecentReport = sortedReports[0];

    return (
        <header className="headerPage">
            <div className="headerPage__title mt-2">
                <ImageStatus
                    className="mr-1"
                    filename={patient.avatar && patient.avatar.filename}
                    fallbackImageUrl={defaultUserImage}
                    title={patient.statusReason}
                    statusColor={patient.statusColor}
                    size={44}
                    user={patient}
                />
                <div>
                    <h1 className="title-1 inline">
                        {patient.firstName}&nbsp;
                        <span className="headerPage__statusDot-group">{patient.lastName}</span>
                    </h1>
                </div>
                {/* <FollowUpQuestionnaire /> */}
            </div>

            {userCan(["ROLE_DOCTOR"]) && (
                <div className="headerPage__actions">
                    <Link
                        to={`/mes-patients/${patient.id}/nouvelle-consultation`}
                        className="headerPage__actions-item button button__primaryButton timkl_backgroundColor--blue-important"
                    >
                        <Icon size={20} name="stethoscope" />
                        <span className="ml-1">{`${lastDiagnosis !== null ? "Revoir" : "Définir"} le parcours de soin`}</span>
                    </Link>
                </div>
            )}
            {userCan(["ROLE_PHARMACIST", "ROLE_NURSE"]) && <PatientReport patient={patient} mostRecentReport={mostRecentReport} />}
        </header>
    );
};

Header.propTypes = {
    patient: PropTypes.shape({
        id: PropTypes.number.isRequired,
        avatar: PropTypes.shape({
            filename: PropTypes.string.isRequired,
        }),
        statusReason: PropTypes.string.isRequired,
        statusColor: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        lastDiagnostic: PropTypes.object,
    }).isRequired,
    lastDiagnosis: PropTypes.object.isRequired,
};

export default React.memo(Header);
