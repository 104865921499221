import React from 'react';

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'SET_CONTENT':
            return {
                ...prevState,
                content: action.content,
            }
        case 'SET_ATTACHMENT': {
            return {
                ...prevState,
                attachment: action.attachment,
            }
        }
        case 'SET_PARTNER':
            return {
                ...prevState,
                partner: action.partner,
            }
        case 'SET_PERFORMED_BY_TMP':
            return {
                ...prevState,
                performedByTmp: action.performedByTmp,
            }
        case 'SET_PERFORMED_BY':
            return {
                ...prevState,
                performedBy: prevState.performedByTmp,
                performedByTmp: null,
            }
        default:
            return prevState;
    }
};

const useState = ({ content = '', attachment = null, partner, performedBy = null }) => {
    const defaultState = React.useMemo(() => ({
        content,
        attachment: attachment && attachment['@id'],
        partner,
        performedBy,
        performedByTmp: null,
    }), []);

    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(() => ({

        setContent: (content) => dispatch({ type: 'SET_CONTENT', content }),

        setAttachment: (attachment = null) => dispatch({ type: 'SET_ATTACHMENT', attachment }),

        setPartner: (partner) => dispatch({ type: 'SET_PARTNER', partner }),

        setPerformedByTmp: (performedByTmp) => dispatch({ type: 'SET_PERFORMED_BY_TMP', performedByTmp }),

        setPerformedBy: () => dispatch({ type: 'SET_PERFORMED_BY' }),

    }), []);

    return [state, methods];
};

export default useState;