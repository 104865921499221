import * as types from "../actions/types";

const initialState = {
    list: [],
    listLoading: false
};

function practitioners(state = initialState, action) {
    switch (action.type) {
        // FETCH
        case types.PRACTITIONERS_FETCH_REQUESTED: {
            return Object.assign({}, state, { listLoading: true });
        }
        case types.PRACTITIONERS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                listLoading: false,
                list: action.data["hydra:member"]
            });
        }
        case types.PRACTITIONERS_FETCH_FAILURE: {
            return Object.assign({}, state, { listLoading: false, list: [] });
        }
        case types.PRACTITIONERS_FETCH_CLEAR: {
            return Object.assign({}, state, { list: [] });
        }
        // DEFAULT
        default:
            return state;
    }
}

export default practitioners;
