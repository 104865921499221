import * as types from "../actions/types";

const initialState = {
    list: [],
    listLoading: false,
    listLoaded: 0,
};

function complications(state = initialState, action) {
    switch (action.type) {
        case types.COMPLICATIONS_FETCH_REQUESTED: {
            return Object.assign({}, state, {
                listLoading: true,
                listLoaded: 0,
            });
        }
        case types.COMPLICATIONS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                listLoading: false,
                list: action.data.sort((a, b) => (a.order < b.order ? -1 : 1)),
                listLoaded: Date.now()
            });
        }
        case types.COMPLICATIONS_FETCH_FAILURE: {
            return Object.assign({}, state, { listLoading: false, list: [] });
        }
        default:
            return state;
    }
}

export default complications;
