import React from 'react';

const defaultState = {
    allowSubmit: false,
    modeEdit: false,
};

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'SET_MODE_EDIT':
            return {
                ...prevState,
                modeEdit: action.mode,
                allowSubmit: false
            }
        case 'SET_ALLOW_SUBMIT':
            return {
                ...prevState,
                allowSubmit: action.allowSubmit
            }
        default:
            return prevState;
    }
};

const useState = () => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(() => ({

        setAllowSubmit: allowSubmit => dispatch({ type: 'SET_ALLOW_SUBMIT', allowSubmit }),

        setModeEdit: mode => dispatch({ type: 'SET_MODE_EDIT', mode }),

    }), []);

    return [state, methods];
};

export default useState;