import { getUniqueArrayOfObjects } from "../../../utils/utils";

const getTreatmentListOption = (treatmentsList) => {
    const allLevelOne = treatmentsList.map(t => t.levelOne);
    const allLevelTwo = treatmentsList.map(t => t.levelTwo);
    const allLevelThree = treatmentsList.map(t => t.levelThree);

    return getUniqueArrayOfObjects(
        [...allLevelOne, ...allLevelTwo, ...allLevelThree].filter(t => t !== null),
        "@id"
    ).map(t => ({
        label: t.name,
        value: t["@id"]
    }));
};

export default getTreatmentListOption;