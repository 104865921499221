import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from "@rdcs/dap-front-library";

const SubTitle = ({ children, className = '' }) => (
    <p className={className}><Icon name='arrow-right' size={12} /> {children}</p>
);

SubTitle.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]).isRequired,
    className: PropTypes.string,
};

export default SubTitle;