import React from "react";

import API from "../../utils/API";

const reducer = (prevState, action) => {
    switch (action.type) {
        case "SET_EMAIL":
            return {
                ...prevState,
                email: action.email,
            };
        case "SET_PLAIN_PASSWORD":
            return {
                ...prevState,
                plainPassword: action.plainPassword,
                confirmPasswordError: null,
            };
        case "SET_CONFIRM_PASSWORD":
            return {
                ...prevState,
                confirmPassword: action.confirmPassword,
                confirmPasswordError: null,
            };
        case "SET_LASTNAME":
            return {
                ...prevState,
                lastName: action.lastName,
            };
        case "SET_FIRSTNAME":
            return {
                ...prevState,
                firstName: action.firstName,
            };
        case "SET_TITLE":
            return {
                ...prevState,
                title: action.title,
            };
        case "SET_ESTABLISHMENT":
            return {
                ...prevState,
                establishment: action.establishment,
            };
        case "SET_ADDRESS":
            return {
                ...prevState,
                address: action.address,
            };
        case "SET_ZIPCODE":
            return {
                ...prevState,
                zipcode: action.zipcode,
            };
        case "SET_CITY":
            return {
                ...prevState,
                city: action.city,
            };
        case "SET_PHONE_NUMBER_1":
            return {
                ...prevState,
                phoneNumber1: action.phoneNumber1,
            };
        case "SET_PHONE_NUMBER_2":
            return {
                ...prevState,
                phoneNumber2: action.phoneNumber2,
            };
        case "SET_AVATAR":
            return {
                ...prevState,
                avatar: action.avatar,
            };
        case "SET_JUSTIFICATORY":
            return {
                ...prevState,
                justificatory: action.justificatory,
            };
        case "TOGGLE_CONSENTS":
            return {
                ...prevState,
                consents: {
                    ...prevState.consents,
                    [action.id]: action.value,
                },
            };
        case "CHECK_PASSWORD":
            return {
                ...prevState,
                confirmPasswordError: action.check ? null : "Les mots de passe ne correspondent pas",
            };
        default:
            return prevState;
    }
};

const useState = (practitioner, gigyaUser = null) => {
    const defaultState = React.useMemo(
        () => ({
            rppsNumber: practitioner.rppsNumber,
            titre: practitioner.titre,
            firstName: gigyaUser?.profile?.firstName || practitioner.firstName,
            lastName: gigyaUser?.profile?.lastName || practitioner.lastName,
            profession: practitioner.profession,
            speciality: practitioner.speciality,
            establishment: practitioner.establishment,
            address: gigyaUser?.data?.billAddressStreet || practitioner.address,
            zipcode: gigyaUser?.data?.billAddressPostalCode || practitioner.zipcode,
            city: gigyaUser?.data?.billAddressCity || practitioner.city,
            email: (gigyaUser?.profile?.email || practitioner.email) ?? "",
            phoneNumber1: practitioner.phoneNumber1,
            phoneNumber2: practitioner.phoneNumber2,
            avatar: null,
            justificatory: null,
            plainPassword: "",
            confirmPassword: "",
            confirmPasswordError: null,
            consents: {},
        }),
        []
    );

    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(
        () => ({
            setEmail: (email) => dispatch({ type: "SET_EMAIL", email }),

            setPlainPassword: (plainPassword) => dispatch({ type: "SET_PLAIN_PASSWORD", plainPassword }),

            setConfirmPassword: (confirmPassword) => dispatch({ type: "SET_CONFIRM_PASSWORD", confirmPassword }),

            setLastName: (lastName) => dispatch({ type: "SET_LASTNAME", lastName }),

            setFirstName: (firstName) => dispatch({ type: "SET_FIRSTNAME", firstName }),

            setTitle: (title) => dispatch({ type: "SET_TITLE", title }),

            setEstablishment: (establishment) => dispatch({ type: "SET_ESTABLISHMENT", establishment }),

            setAddress: (address) => dispatch({ type: "SET_ADDRESS", address }),

            setZipcode: (zipcode) => dispatch({ type: "SET_ZIPCODE", zipcode }),

            setCity: (city) => dispatch({ type: "SET_CITY", city }),

            setPhoneNumber1: (phoneNumber1) => dispatch({ type: "SET_PHONE_NUMBER_1", phoneNumber1 }),

            setPhoneNumber2: (phoneNumber2) => dispatch({ type: "SET_PHONE_NUMBER_2", phoneNumber2 }),

            toggleConsents: (id, value) => dispatch({ type: "TOGGLE_CONSENTS", id, value }),

            uploadAvatarAction: (file, token) => API.uploadFileRecaptcha(file, token, (avatar) => dispatch({ type: "SET_AVATAR", avatar })),

            uploadDocumentAction: (file, token) =>
                API.uploadFileRecaptcha(file, token, (justificatory) => dispatch({ type: "SET_JUSTIFICATORY", justificatory })),
        }),
        []
    );
    const NoConstantMethods = React.useMemo(
        () => ({
            checkPassword: () => {
                const check = state.plainPassword === state.confirmPassword;

                dispatch({ type: "CHECK_PASSWORD", check });
                return check;
            },
        }),
        [state]
    );

    return [state, methods, NoConstantMethods];
};

export default useState;
