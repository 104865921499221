import React from 'react';

const defaultState = {
    justificatory: '',
    justificatoryError: '',
    submitLoading: false,
    justificatoryUrl: null
};

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'SET_JUSTIFICATORY_URL':
            return {
                ...prevState,
                justificatoryUrl: action.justificatoryUrl,
            }
        case 'SET_JUSTIFICATORY':
            return {
                ...prevState,
                justificatory: action.justificatory,
            }
        case 'SET_JUSTIFICATORY_ERROR':
            return {
                ...prevState,
                justificatoryError: action.justificatoryError,
                submitLoading: false,
            }
        case 'JUSTIFICATORY_LOAD':
            return {
                ...prevState,
                submitLoading: true,
            }
        default:
            return prevState;
    }
};

const useState = () => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(() => ({

        setJustificatoryUrl: (justificatoryUrl) => dispatch({ type: 'SET_JUSTIFICATORY_URL', justificatoryUrl }),

        setJustificatory: (justificatory) => dispatch({ type: 'SET_JUSTIFICATORY', justificatory }),

        setJustificatoryError: (justificatoryError) => dispatch({ type: 'SET_JUSTIFICATORY_ERROR', justificatoryError }),

        justificatoryLoad: () => dispatch({ type: 'JUSTIFICATORY_LOAD' }),

    }), []);
    
    return [state, methods];
};

export default useState;