import { combineReducers } from "redux";

import authReducer from "./auth";
import complicationsReducer from "./complications";
import diagnosisReducer from "./diagnosis";
import measuresReducer from "./measures";
import notesReducer from "./notes";
import partnersReducer from "./partners";
import pointsOfAttentionReducer from "./pointsOfAttention";
import patientsReducer from "./patients";
import practitionersReducer from "./practitioners";
import profileReducer from "./profile";
import questionsReducer from "./questions";
import recommandationsReducer from "./recommandations";
import reportsReducer from "./reports";
import treatmentsReducer from "./treatments";
import thematicReducer from "./thematic";
import coordinatorsReducer from "./coordinators";

const reducers = combineReducers({
    auth: authReducer,
    complications: complicationsReducer,
    diagnosis: diagnosisReducer,
    measures: measuresReducer,
    notes: notesReducer,
    partners: partnersReducer,
    pointsOfAttention: pointsOfAttentionReducer,
    patients: patientsReducer,
    practitioners: practitionersReducer,
    profile: profileReducer,
    questions: questionsReducer,
    recommandations: recommandationsReducer,
    reports: reportsReducer,
    treatments: treatmentsReducer,
    thematic: thematicReducer,
    coordinators: coordinatorsReducer,
});

export default reducers;
