import React from "react";
import PropTypes from 'prop-types';

import Nav from "./Nav";
import BloodSugarHeader from './BloodSugarHeader';
import { userCan } from "../../../reducers/auth";

const SubHeader = ({ medicalPatient, canAccessStudyQuestion }) => (
    <div className={`headerPage__subHeader 
    top_left_right_radius
    ${userCan(["ROLE_PHARMACIST"]) && "timkl__backgroundColor--violet-light-important"} 
    ${userCan(["ROLE_DOCTOR"]) && "timkl__backgroundColor--blue-light4-important"}`} >
        <Nav medicalPatient={medicalPatient} canAccessStudyQuestion={canAccessStudyQuestion} />
        <BloodSugarHeader patientAccessRight={medicalPatient} />
    </div>
);

SubHeader.propTypes = {
    medicalPatient: PropTypes.object,
    canAccessStudyQuestion: PropTypes.bool.isRequired,
};

export default SubHeader;