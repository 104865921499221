import * as types from "../actions/types";

const initialState = {
    list: [],
    listLoading: false,
    listLoaded: 0,

    checkUps: [],
    checkUpsLoading: false,
    checkUpsLoaded: 0,
    checkUpsPatientId: -1,

    emailIsSending: false,
};

function partners(state = initialState, action) {
    switch (action.type) {
        // FETCH
        case types.PARTNERS_FETCH_REQUESTED: {
            return Object.assign({}, state, {
                listLoading: true,
                listLoaded: 0,
            });
        }
        case types.PARTNERS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                listLoading: false,
                list: action.data,
                listLoaded: Date.now()
            });
        }
        case types.PARTNERS_FETCH_FAILURE: {
            return Object.assign({}, state, {
                listLoading: false,
                list: []
            });
        }

        // EMAIL SEND
        case types.PARTNERS_EMAIL_SEND_REQUESTED: {
            return Object.assign({}, state, {
                emailIsSending: true,
            });
        }
        case types.PARTNERS_EMAIL_SEND_SUCCESS: {
            return Object.assign({}, state, {
                emailIsSending: false,
                checkUps: [...state.checkUps, action.data]
            });
        }
        case types.PARTNERS_EMAIL_SEND_FAILURE: {
            return Object.assign({}, state, {
                emailIsSending: false
            });
        }

        case types.PARTNER_CHECK_UPS_FETCH_REQUESTED: {
            return Object.assign({}, state, {
                checkUpsLoading: true,
                checkUpsLoaded: 0,
                checkUpsPatientId: -1,
            });
        }
        case types.PARTNER_CHECK_UPS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                checkUpsLoading: false,
                checkUps: action.data,
                checkUpsLoaded: Date.now(),
                checkUpsPatientId: action.patientId
            });
        }
        case types.PARTNER_CHECK_UPS_FETCH_FAILURE: {
            return Object.assign({}, state, {
                checkUpsLoading: false,
                checkUps: []
            });
        }
        
        // DEFAULT
        default:
            return state;
    }
}

export default partners;