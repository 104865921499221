import React from "react";
import PropTypes from "prop-types";

import {
    IconButton,
    InputCheckbox,
    InputText,
    InputCheck,
    InputDateTime,
    Form,
    RegExp,
} from "@rdcs/dap-front-library";
import { toast } from "react-toastify";

import API from "../../utils/API";
import { isLocalAPI } from "../../utils/utils";

import ConcentUpload from "./ConcentUpload";

const patientFirstnameFormat = {
    pattern: RegExp.name,
    message: 'Le prénom doit être constitué uniquement de lettre ou de tiret.',
};

const patientLastnameFormat = {
    pattern: RegExp.name,
    message: 'Le nom doit être constitué uniquement de lettre ou de tiret.',
};

const BreakScoreQuestionnaireForm1 = ({
    onSubmit,
    setNonInsulino,
    patient: {
        firstName = '',
        lastName = '',
        birthDate = null,
        consent: savedConsent = null,
        allowDataUsageForStudy = false,
        insulino = false
    },}) => {
    const [consent, setConsent] = React.useState(savedConsent);

    const onUploadConcent = (consent) => API.uploadFile(consent)
        .then(item => setConsent(item['@id']))
        .catch(() => {
            setConsent(null);
            toast.error("L'upload a échoué pour une raison inconnue");
        });

    const onSubmitCallback = React.useCallback((form) => {
        setNonInsulino(form.insulino);
        onSubmit({
            ...form,
            consent: isLocalAPI ? null : consent,
            practitioner: null,
            email: null,
            phoneNumber: null,
        });
    }, [
        onSubmit,
        isLocalAPI ? null : consent,
    ]);

    return (
        <div className="breakScoreQuestionnaire__container">
            <Form onSubmit={onSubmitCallback}>
                <ConcentUpload
                    className="breakScoreQuestionnaire__fieldset col-xs-50 mb-4"
                    onUpload={onUploadConcent}
                />
                <div className='py-4 px-8'>
                    <section>
                        <h2 className="title-3 mb-2">Informations personnelles du patient</h2>
                        <div className='grid grid__padding-25'>
                            <div className='d-flex'>
                                <p className="ml-3 mr-4 mt-1">Le patient est-il insulino-traité ?</p>
                                <InputCheck name="insulino" defaultValue={false} checked={insulino} />
                            </div>
                            <InputText
                                label="Nom"
                                placeholder="Saisissez le nom du patient"
                                required
                                name='lastName'
                                defaultValue={lastName}
                                format={patientLastnameFormat}
                                containerClassName='col-sm-30 col-xs-100'
                            />
                            <InputText
                                label="Prénom"
                                placeholder="Saisissez le prénom du patient"
                                required
                                name='firstName'
                                defaultValue={firstName}
                                format={patientFirstnameFormat}
                                containerClassName='col-sm-30 col-xs-100'
                            />
                        </div>
                        <div className='grid grid__padding-25'>
                            <InputDateTime
                                label="Date de naissance du patient"
                                placeholder="Saisissez la date de naissance du patient"
                                maxDate={new Date()}
                                required
                                name='birthDate'
                                defaultValue={birthDate && new Date(birthDate)}
                                className='col-sm-25 col-xs-100'
                            />
                        </div>
                    </section>

                    <InputCheckbox
                        className="mt-4"
                        label="Sauvegarder les réponses et informations personnelles du patient à des fins d'étude"
                        defaultChecked={allowDataUsageForStudy}
                        name='allowDataUsageForStudy'
                    />

                    <div className="txtRight mt-4">
                        <IconButton
                            className="button__primaryButton"
                            type="submit"
                            disabled={consent === null}
                            icon='arrow-right'
                            right
                        >
                            Enregistrement
                        </IconButton>
                    </div>
                </div>
            </Form>
        </div>
    );
}

BreakScoreQuestionnaireForm1.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    setNonInsulino: PropTypes.func.isRequired,
    patient: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        birthDate: PropTypes.string,
        consent: PropTypes.string,
        allowDataUsageForStudy: PropTypes.bool,
        insulino: PropTypes.bool
    }).isRequired
};

export default BreakScoreQuestionnaireForm1;
