import React from "react";
import PropTypes from 'prop-types';

import parse from "html-react-parser";
import {
    constant,
    Icon,
    Button,
    InputNumber,
    InputPassword,
    InputImage,
    InputText,
    InputEmail,
    InputTel,
    Select,
    InputDocument,
    InputCheckbox,
    PractitionerLayoutAsideAnonymous,
    Grecaptcha
} from "@rdcs/dap-front-library";

import useState from './useState';

const SignInForm = ({
    gigyaUser,
    consents,
    practitioner,
    submit,
    loading,
    violations,
    cancel
}) => {
    const [form, {
        setEmail,
        setPlainPassword,
        setConfirmPassword,
        setLastName,
        setFirstName,
        setTitle,
        setEstablishment,
        setAddress,
        setZipcode,
        setCity,
        setPhoneNumber1,
        setPhoneNumber2,
        uploadAvatarAction,
        uploadDocumentAction,
        toggleConsents
    }, {
        checkPassword
    }] = useState(practitioner, gigyaUser);
    const {
        rppsNumber,
        titre,
        firstName,
        lastName,
        profession,
        speciality,
        establishment,
        address,
        zipcode,
        city,
        email,
        phoneNumber1,
        phoneNumber2,
        confirmPasswordError,
    } = form;
    const gRecaptchaRef = React.useRef();
    const passwordRef = React.useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (checkPassword()) {
            const finalForm = { ... form };

            delete finalForm.confirmPasswordError;

            if (form.justificatory === null) {
                delete finalForm.justificatory;
            }

            submit(finalForm);
        }
        else {
            passwordRef.current?.scrollIntoView();
        }
    };

    return (
        <div className="grid credentials__inscriptionContainer">
            <Grecaptcha ref={gRecaptchaRef} />
            <PractitionerLayoutAsideAnonymous />
            <main className="col-sm-50 credentialsMain__container">
                <form className="credentialsMain credentialsMain--inscriptionStep2" onSubmit={handleSubmit}>
                    <button className="credentialsMain__back" onClick={cancel}>
                        <Icon name="arrow-left" /> &nbsp; Retour
                    </button>

                    <h1 className="credentials__title">S'inscrire</h1>

                    {gigyaUser && (
                        <div className="bgNotice radius shadow mt-4 mb-2 pt-2 pl-3 pb-2 pr-3">
                            Nous vous invitons à finaliser l'inscription avec votre compte Gigya
                        </div>
                    )}

                    <InputEmail
                        label="Votre email"
                        placeholder="Saisissez votre email"
                        legend="Cette adresse nous sert à vous communiquer des informations importantes. Nous ne la communiquerons à aucun tiers."
                        onChange={setEmail}
                        error={violations.email}
                        defaultValue={email}
                        required
                    />
                    {!gigyaUser && (
                        <div ref={passwordRef} className="grid grid__padding-10">
                            <div className="col-xs-50">
                                <InputPassword
                                    label="Votre mot de passe"
                                    onChange={setPlainPassword}
                                    required
                                />
                            </div>
                            <div className="col-xs-50">
                                <InputPassword
                                    label="Confirmation"
                                    onChange={setConfirmPassword}
                                    required
                                />
                            </div>
                            {(violations.plainPassword || confirmPasswordError) && (
                                <div className="col-xs-100">
                                    {violations.plainPassword && (
                                        <p className="form__errorMessage">{violations.plainPassword}</p>
                                    )}
                                    {confirmPasswordError && (
                                        <p className="form__errorMessage">{confirmPasswordError}</p>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    <InputText
                        label="Votre nom"
                        placeholder="Saisissez votre nom"
                        name="lastName"
                        onChange={setLastName}
                        error={violations.lastName}
                        defaultValue={lastName}
                        required
                    />
                    <InputText
                        label="Votre prénom"
                        placeholder="Saisissez votre prénom"
                        name="firstName"
                        onChange={setFirstName}
                        error={violations.firstName}
                        defaultValue={firstName}
                        required
                    />
                    <div className="grid grid__padding-10">
                        {practitioner.creationEndpoint === "doctors" && (
                            <div className="col-xs-40">
                                <Select
                                    label="Votre titre"
                                    options={[
                                        { label: "Aucun", value: "" },
                                        ...constant("titres").map(titre => ({
                                            label: constant("titres", titre),
                                            value: titre
                                        }))
                                    ]}
                                    onChange={setTitle}
                                    error={violations.titre}
                                    defaultValue={titre}
                                />
                            </div>
                        )}
                        <div
                            className={`${
                                practitioner.creationEndpoint === "doctors" ? "col-xs-60" : "col-xs-100"
                            }`}
                        >
                            <InputText
                                label="Votre profession"
                                placeholder="Saisissez votre profession"
                                name="profession"
                                disabled={true}
                                onChange={() => undefined}
                                error={violations.profession}
                                defaultValue={profession}
                                required
                            />
                        </div>
                    </div>
                    {practitioner.creationEndpoint === "doctors" && (
                        <InputText
                            label="Votre spécialité"
                            placeholder="Aucune"
                            name="speciality"
                            disabled={true}
                            onChange={() => undefined}
                            error={violations.speciality}
                            defaultValue={speciality}
                            required
                        />
                    )}
                    <InputText
                        label="Votre établissement"
                        placeholder="Saisissez votre établissement"
                        name="establishment"
                        onChange={setEstablishment}
                        error={violations.establishment}
                        defaultValue={establishment}
                    />
                    <div>
                        <InputNumber
                            label="Votre numéro RPPS"
                            placeholder="Saisissez votre numéro RPPS"
                            name="rpps"
                            disabled={true}
                            onChange={() => undefined}
                            error={violations.rppsNumber}
                            defaultValue={parseInt(rppsNumber)}
                            required
                        />
                        <InputDocument
                            label="Charger un justificatif"
                            documentName="Mon justificatif"
                            legend="Justificatif accepté : carte professionnelle, certificat de scolarité, en-tête d'ordonnance, en-tête de fiche de paie."
                            required={false}
                            uploadAction={uploadDocumentAction}
                            error={violations.justificatory}
                            gRecaptchaRef={gRecaptchaRef}
                        />
                    </div>
                    <InputText
                        label="Votre adresse"
                        placeholder="Saisissez votre adresse"
                        name="address"
                        onChange={setAddress}
                        error={violations.address}
                        defaultValue={address}
                        required
                    />
                    <div className="grid grid__padding-10">
                        <div className="col-xs-50">
                            <InputNumber
                                label="Code postal"
                                placeholder="Saisissez votre code postal"
                                name="zip"
                                onChange={setZipcode}
                                error={violations.zipcode}
                                defaultValue={parseInt(zipcode)}
                                required
                            />
                        </div>
                        <div className="col-xs-50">
                            <InputText
                                label="Votre ville"
                                placeholder="Saisissez votre ville"
                                name="city"
                                onChange={setCity}
                                error={violations.city}
                                defaultValue={city}
                                required
                            />
                        </div>
                    </div>
                    <div className="grid grid__padding-10">
                        <div
                            className={`${
                                practitioner.creationEndpoint === "doctors" ? "col-xs-50" : "col-xs-100"
                            }`}
                        >
                            <InputTel
                                label="Votre téléphone"
                                placeholder="Saisissez votre téléphone"
                                name="phone"
                                onChange={setPhoneNumber1}
                                error={violations.phoneNumber1}
                                defaultValue={phoneNumber1}
                                required
                            />
                        </div>
                        {practitioner.creationEndpoint === "doctors" && (
                            <div className="col-xs-50">
                                <InputTel
                                    label="Votre téléphone 2"
                                    placeholder="Saisissez votre téléphone"
                                    name="phone-2"
                                    onChange={setPhoneNumber2}
                                    error={violations.phoneNumber2}
                                    defaultValue={phoneNumber2}
                                />
                            </div>
                        )}
                    </div>
                    <InputImage
                        label="Votre photo de profil"
                        text="Télécharger"
                        uploadAction={uploadAvatarAction}
                        error={violations.avatar}
                        gRecaptchaRef={gRecaptchaRef}
                    />
                    <div className="credentialsDataUsage">
                        <h2>Mentions légales sur le traitement des données</h2>
                        <div className="credentialsDataUsage__content mb-0 mt-2">
                            <p>
                                Timkl SAS (565 rue Aristide Bergès – 38330 Montbonnot-Saint-Martin) a la qualité de
                                prestataire de service et distributeurs de matériels au sens de l’article L.5232-3
                                du Code de la Santé Publique. Il est responsable de traitement au sens du Règlement
                                Général sur la Protection des Données (RGPD) concernant vos données collectées dans
                                le cadre de la plateforme « Diabetics Adherence Platform » éditée par le groupe
                                Roche.
                            </p>
                            <p>
                                L’objet de ces traitements est de permettre une coordination du parcours de soin des
                                patients diabétiques par une infirmière Timkl conformément aux recommandations de la
                                Haute Autorité de Santé.
                            </p>
                            <p>
                                En tant que Professionnel de santé, vous êtes référencé dans la base publique RPPS
                                et à ce titre, votre identifiant RPPS nous permet de remplir automatiquement les
                                données de votre profil. Ces données ont vocation à vous permettre :<br />
                                1) de vous identifier afin d’éviter tout doublon puis lorsque vous accédez et
                                effectuez des actions sur la plateforme (conformément à nos obligations légales de
                                sécurité) : vos données d’identification sont conservées pour la durée d’activité de
                                votre compte augmentée d’un an et les logs sont conservés cinq ans à compter de la
                                collecte.
                                <br />
                                2) de gérer votre compte, communiquer avec vous (y compris par contact téléphonique
                                ou e-mail) et vous permettre d’accéder au dossier des patients ayant accepté le
                                partage de leurs données (conformément au contrat avec Timkl): les données de votre
                                compte sont conservées pour la durée d’activité de votre compte puis archivées 10
                                ans sauf indication contraire de votre part.
                                <br />
                                3) De générer des statistiques quant aux actions techniques sur la plateforme de
                                façon non individuelle (uniquement dans le cadre de notre intérêt légitime
                                d’amélioration des services et avec votre consentement qui vous sera demandé en cas
                                d’accès non nécessaire à votre appareil): ces données seront traitées pour une durée
                                n’excédant pas quelques dizaines de mois.
                            </p>
                            <p>
                                Pour pouvoir vous fournir nos services, nous collaborons régulièrement avec des
                                tiers internes ou externes au groupe Roche en Europe, par exemple des affiliées de
                                Roche qui appartiennent au groupe Roche/F. Hoffmann-La Roche Ltd. Les prestations
                                d’authentification sont réalisées par Gigya, un service fourni par SAP Inc. en
                                Irlande; les données de santé sont hébergées en Allemagne par AWS (infrastructure)
                                et en Espagne par Accenture (administration) qui sont certifiés à cette fin au
                                regard des exigences du Code de la Santé Publique. Nous pouvons partager les données
                                traitées sur la Plateforme avec les forces de l’ordre, organismes publics ou des
                                tiers autorisés au sens du RGPD en réponse à une demande en ce sens ayant fait
                                l’objet d’une vérification, ainsi qu’avec les autorités de santé françaises
                                autorisées et la caisse d’assurance maladie.
                            </p>
                            <p>
                                Vous disposez des droits d’accès, de rectification et d’effacement de vos données à
                                caractère personnel, des droits de vous opposer ou de limiter le traitement de vos
                                données à caractère personnel, du droit à la portabilité des données à caractère
                                personnel, de retirer votre consentement à tout moment et du droit de définir des
                                directives relatives au sort de vos données à caractère personnel après votre mort,
                                directement sur la Plateforme ou en vous adressant à dpo.timkl@timkl.fr. Vous avez
                                le droit d’adresser une réclamation à l’autorité de contrôle compétente : Commission
                                nationale de l'informatique et des libertés - 3 Place de Fontenoy – 75007 Paris.
                            </p>
                        </div>
                    </div>
                    <br />
                    {consents.map(consent => (
                        <InputCheckbox
                            key={consent.id}
                            label={parse(consent.legalStatements.fr.purpose)}
                            onChange={val => toggleConsents(consent.id, val)}
                            required={consent.required}
                        />
                    ))}
                    {violations.gigya && violations.gigya === 409 ? (
                        <p className="form__errorMessage">
                            Vous avez déjà un compte Roche, merci de vous connecter sur{" "}
                            <a
                                className="form__errorMessage txtBold txtUnderline"
                                href={`${window.location.origin}/connexion`}
                            >
                                {window.location.origin}/connexion
                            </a>
                        </p>
                    ) : null}
                    <div className="credentialsMain__validate">
                        <Button type="submit" className="button__primaryButton" isLoading={loading}>
                            Valider mon inscription
                        </Button>
                    </div>
                </form>
            </main>
        </div>
    );
}

SignInForm.propTypes = {
    gigyaUser: PropTypes.shape({
        profile: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            email: PropTypes.string,
        }).isRequired,
        data: PropTypes.shape({
            billAddressStreet: PropTypes.string,
            billAddressPostalCode: PropTypes.string,
            billAddressCity: PropTypes.string,
        }).isRequired,
    }),
    consents: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        required: PropTypes.bool,
        legalStatements: PropTypes.shape({
            fr: PropTypes.shape({
                purpose: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    })).isRequired,
    practitioner: PropTypes.shape({
        rppsNumber: PropTypes.string.isRequired,
        titre: PropTypes.string,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        profession: PropTypes.string,
        speciality: PropTypes.string,
        establishment: PropTypes.string,
        address: PropTypes.string,
        zipcode: PropTypes.string,
        city: PropTypes.string,
        email: PropTypes.string,
        phoneNumber1: PropTypes.string,
        phoneNumber2: PropTypes.string,
        creationEndpoint: PropTypes.string,
    }).isRequired,
    submit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    violations: PropTypes.object,
    cancel: PropTypes.func.isRequired,
}

export default SignInForm;
