import * as types from "../actions/types";

const initialState = {
    list: [],
    listLoading: false,
    createLoading: false,
    createError: false,
    editLoading: false,
    editError: false,
    deleteLoading: false,
    deleteError: false,
    departments: [],
    departmentsLoading: false,
};

function coordinators(state = initialState, action) {
    switch (action.type) {
        // FETCH Coordinators
        case types.COORDINATORS_FETCH_REQUESTED: {
            return Object.assign({}, state, { listLoading: true });
        }
        case types.COORDINATORS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                listLoading: false,
                list: action.data,
            });
        }
        case types.COORDINATORS_FETCH_FAILURE: {
            return Object.assign({}, state, { listLoading: false, list: [] });
        }
        default:
            return state;
    }
}

export default coordinators;
