import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DefaultLoader } from "@rdcs/dap-front-library";

import Header from "./Header";
import SubHeader from "./SubHeader";

import AppLayout from "../../Layout/AppLayout";

import { tryFetchDiagnosisRecos } from "../../../actions/diagnosis";
import { tryFetchPatientAppointments, tryMedicalPatient } from "../../../actions/patients";
import { tryFetchPointsOfAttention } from "../../../actions/pointsOfAttention";
import Questions from "../../../actions/questions";
import { tryFetchReports } from "../../../actions/reports";
import { tryFetchThematic } from "../../../actions/thematics";

import { userCan } from "../../../reducers/auth";

import { Roles, getRoles } from "../../../utils/TokenData";

const { tryFetchStudyQuestions } = Questions;

const breadcrumbs = [{ label: "Mes patients", link: "/mes-patients" }, { label: "Fiche Patient" }];

const PatientNavLayout = ({
    children,
    medicalPatient: medicalPatientProps,
    onLoad,
    onMedicalPatientUpdate,
    patientId,
    patientLastDiagnosis,
    tryFetchDiagnosisRecos,
    tryFetchPatientAppointments,
    tryFetchPointsOfAttention,
    tryFetchReports,
    tryFetchStudyQuestions,
    tryFetchThematic,
    tryMedicalPatient,
}) => {
    const navigate = useNavigate();

    const [medicalPatient, setMedicalPatient] = React.useState(null);

    const canAccessStudyQuestion = React.useMemo(
        () => userCan(getRoles(Roles.ROLE_DOCTOR, Roles.ROLE_PHARMACIST, Roles.ROLE_NURSE)),
        []
    );

    React.useEffect(() => {
        Promise.all([
            tryMedicalPatient(patientId),
            canAccessStudyQuestion ? tryFetchStudyQuestions() : Promise.resolve([]),
            tryFetchPointsOfAttention(),
            tryFetchThematic(),
            tryFetchPatientAppointments(patientId),
            tryFetchReports(patientId),
        ])
            .then(([medicalPatient, studyQuestions]) => {
                const extraRequest = [tryFetchDiagnosisRecos(medicalPatient.patient.lastDiagnostic)];

                setMedicalPatient(medicalPatient);
                return Promise.all([medicalPatient, studyQuestions, ...extraRequest]);
            })
            .then(([medicalPatient, studyQuestions]) => {
                onLoad?.(medicalPatient, studyQuestions);
            })
            .catch((e) => {
                console.error(e);
                navigate({ pathname: "/mes-patients" });
            });
    }, [medicalPatient, patientId]);

    React.useEffect(() => {
        if (medicalPatient !== null) {
            onMedicalPatientUpdate?.(medicalPatientProps);
        }
    }, [medicalPatientProps]);

    return (
        <AppLayout navActive={0} breadcrumbs={breadcrumbs}>
            {!medicalPatient ? (
                <DefaultLoader />
            ) : (
                <>
                    <Header patient={medicalPatient.patient} lastDiagnosis={patientLastDiagnosis} />
                    <div className="border top_left_right_radius pb-4">
                        <SubHeader medicalPatient={medicalPatient} canAccessStudyQuestion={canAccessStudyQuestion} />
                        <main className="dashboardContent">{children}</main>
                    </div>
                </>
            )}
        </AppLayout>
    );
};

PatientNavLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    medicalPatient: PropTypes.object,
    onLoad: PropTypes.func,
    onMedicalPatientUpdate: PropTypes.func,
    patientId: PropTypes.number.isRequired,
    patientLastDiagnosis: PropTypes.object.isRequired,
    tryFetchDiagnosisRecos: PropTypes.func.isRequired,
    tryFetchPatientAppointments: PropTypes.func.isRequired,
    tryFetchPointsOfAttention: PropTypes.func.isRequired,
    tryFetchReports: PropTypes.func.isRequired,
    tryFetchStudyQuestions: PropTypes.func.isRequired,
    tryFetchThematic: PropTypes.func.isRequired,
    tryMedicalPatient: PropTypes.func.isRequired,
};

const mapStateToProps = ({ patients: { medicalPatient }, diagnosis: { patientLastDiagnosis } }) => ({
    medicalPatient,
    patientLastDiagnosis,
});

const mapDispatchToProps = {
    tryMedicalPatient,
    tryFetchStudyQuestions,
    tryFetchPointsOfAttention,
    tryFetchThematic,
    tryFetchPatientAppointments,
    tryFetchDiagnosisRecos,
    tryFetchReports,
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(PatientNavLayout));
