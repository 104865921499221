import React from "react";
import PropTypes from "prop-types";

import { IconButton, InputCheckbox, InputText, InputCheck, Form } from "@rdcs/dap-front-library";

import { formatDate, isLocalAPI } from "../../../utils/utils";

const Step1 = ({
    onSubmit,
    setInsulino,
    patient: { consent = "existing-patient", allowDataUsageForStudy = false, insulino = false },
    singlePatient: { firstName, lastName, dateOfBirth },
}) => {
    const onSubmitCallback = React.useCallback(
        (form) => {
            setInsulino(form.insulino);
            onSubmit({
                ...form,
                consent,
                practitioner: null,
                email: null,
                phoneNumber: null,
            });
        },
        [onSubmit, isLocalAPI ? null : consent]
    );

    let formattedBirthDate = null;
    if (dateOfBirth) formattedBirthDate = formatDate(dateOfBirth);

    return (
        <div className="breakScoreQuestionnaire__container">
            <Form onSubmit={onSubmitCallback}>
                <div className="py-4 px-8">
                    <section>
                        <h2 className="title-3 mb-2">Informations personnelles du patient</h2>
                        <div className="grid grid__padding-25">
                            <div className="d-flex">
                                <p className="ml-3 mr-4 mt-1">Le patient est-il insulino-traité ?</p>
                                <InputCheck name="insulino" defaultValue={false} checked={insulino} />
                            </div>
                            <InputText
                                label="Nom"
                                placeholder="Saisissez le nom du patient"
                                name="lastName"
                                defaultValue={lastName}
                                containerClassName="col-sm-30 col-xs-100"
                                disabled
                            />
                            <InputText
                                label="Prénom"
                                placeholder="Saisissez le prénom du patient"
                                name="firstName"
                                defaultValue={firstName}
                                containerClassName="col-sm-30 col-xs-100"
                                disabled
                            />
                        </div>
                        <div className="grid grid__padding-25">
                            <InputText
                                label="Date de naissance"
                                placeholder="Saisissez la date de naissance du patient"
                                name="birthDate"
                                defaultValue={formattedBirthDate}
                                containerClassName="col-sm-30 col-xs-100"
                                disabled
                            />
                        </div>
                    </section>

                    <InputCheckbox
                        className="mt-4"
                        label="Sauvegarder les réponses et informations personnelles du patient à des fins d'étude"
                        defaultChecked={allowDataUsageForStudy}
                        name="allowDataUsageForStudy"
                    />

                    <div className="txtRight mt-4">
                        <IconButton className="button__primaryButton" type="submit" disabled={consent === null} icon="arrow-right" right>
                            Suivant
                        </IconButton>
                    </div>
                </div>
            </Form>
        </div>
    );
};

Step1.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    setInsulino: PropTypes.func.isRequired,
    patient: PropTypes.shape({
        consent: PropTypes.string,
        allowDataUsageForStudy: PropTypes.bool,
        insulino: PropTypes.bool,
    }).isRequired,
    singlePatient: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        dateOfBirth: PropTypes.string,
    }),
};

export default Step1;
