import React from "react";

import BaseScreen from './BaseScreen';

const LegalNoticeScreen = () => (
    <BaseScreen
        title='LegalNotice'
        content='Legal'
    />
);

LegalNoticeScreen.propTypes = {};

export default LegalNoticeScreen;
