import PCancelable from "p-cancelable";
import * as queryString from "query-string";

import { tryGigyaRefreshToken } from "../actions/auth";
import store from "../store";

import { addActiveRequest } from "./requestManager";
import { isLocalAPI } from "./utils";

/**
 * Endpoints mapping for direct call on DAP backend
 * Automatically used when REACT_APP_USE_LOCAL_API = true
 */

/**
 * ** Usage **
 *
 * const request = API.request({
 *      method: 'GET'|'POST'|'PUT'|'DELETE'|null,
 *      path: 'the-api-endpoint/{id}',
 *      uriParams: {id: 1}
 *      queryParams: {active: true}
 *      token: 'accessToken'|null,
 *      data: FormData|Object|null
 * });
 * request.cancel(); // This will stop the AJAX call
 *
 * API.uploadFile(file).then(created => console.log(created)); // Use it when you need to upload a file in a form, you will get the file path in return
 *
 */
class API {
    static setNavigateFunction(navigate) {
        API.navigate = navigate;
    }
    static async request(params = {}, callback) {
        if (!("path" in params)) {
            console.error('API.request() requires "path" in params object');
        }

        // For authenticated request, check if token expired
        if (params.token) {
            const authStore = store.getState().auth;
            if (authStore.exp && Math.floor(new Date().getTime()) > authStore.exp) {
                if (authStore.refreshTokenLoading === false) {
                    await store.dispatch(tryGigyaRefreshToken(authStore.refresh_token));
                    params.token = store.getState().auth.access_token;
                } else {
                    await new Promise((resolve) => {
                        const unsubscribe = store.subscribe(() => {
                            if (store.getState().auth.refreshTokenLoading === false) {
                                params.token = store.getState().auth.access_token;
                                resolve();
                                unsubscribe();
                            }
                        });
                    });
                }
            }
        }

        const buildFullPath = (path, uriParams = {}, queryParams = {}) => {
            let fullPath = path;
            for (const key in uriParams) {
                fullPath = fullPath.replace("{" + key + "}", uriParams[key]);
            }
            if (Object.entries(queryParams).length > 0) {
                fullPath = fullPath + "?" + queryString.stringify(queryParams);
            }
            const missingUriParams = fullPath.match(/{.*?}/g);
            if (missingUriParams) {
                console.error("Missing uriParams : " + missingUriParams.join(", "));
            }
            return fullPath;
        };

        const cancelablePromise = new PCancelable((resolve, reject, onCancel) => {
            const request = new XMLHttpRequest();
            const path = buildFullPath(params.path, params.uriParams, params.queryParams);
            const apiUrl = isLocalAPI && !path.startsWith("/ciam") ? "http://localhost:81" : window.env.API_URL;
            const method = params.method || "GET";
            const data = params.data || null;
            const token = isLocalAPI
                ? !path.startsWith("/ciam")
                    ? localStorage.getItem("id_token")
                    : localStorage.getItem("access_token")
                : params.token || null;
            let body = null;

            // Create request instance
            request.open(method, apiUrl + path);

            // Bypass header
            request.setRequestHeader("client_id", window.env.API_CLIENT_ID);
            request.setRequestHeader("client_secret", window.env.API_CLIENT_SECRET);
            request.setRequestHeader(
                "ApiKey",
                params.patientCreationByHCP ? window.env.AUTH_API_KEY_PATIENT : window.env.AUTH_API_KEY
            );

            // Append auth header if token sent
            if (token) {
                request.setRequestHeader("Authorization", "Bearer " + token);
            }

            // Append header if gigya user token sent
            if (params.gigyaExistingUserToken) {
                request.setRequestHeader("GIGYA-EXISTING-USER-TOKEN", params.gigyaExistingUserToken);
            }

            // Set body and Content-Type header if necessary
            if (data instanceof FormData) {
                body = data;
            } else if (data instanceof Object) {
                request.setRequestHeader("Content-Type", "application/json");
                body = JSON.stringify(data);
            } else if (data !== null) {
                console.error("Invalid data, expecting FormData or Object");
            }

            // Handle request response
            request.onload = function () {
                const responseJson = API.parseJson(request.response);

                // If error status code
                if (request.status >= 400) {
                    // If the account is disabled, redirect to log out screen
                    // Then to login screen with message in history state
                    if (
                        request.status === 401 &&
                        responseJson.message &&
                        responseJson.message === "error.user_not_enabled"
                    ) {
                        API.navigate("/deconnexion", {
                            state: {
                                userDisabled: "Votre compte n'est pas activé",
                            },
                        });
                    }
                    reject({
                        code: request.status,
                        message: API.getErrorMessageFromStatus(request.status),
                        violations: API.formatViolations(responseJson),
                        body: responseJson,
                    });
                }
                // Success return responseJson
                else {
                    resolve(responseJson);
                }
            };

            // Handle request error
            request.onerror = function () {
                reject({
                    code: request.status,
                    message: API.getErrorMessageFromStatus(request.status),
                    body: null,
                });
            };

            // Handle request error
            request.ontimeout = function () {
                reject({
                    code: request.status,
                    message: API.getErrorMessageFromStatus(request.status),
                    body: null,
                });
            };

            // Send request
            request.send(body);

            // Allow cancel request
            onCancel.shouldReject = false;
            onCancel(() => path !== "/gigya/refresh-token" && path !== "" && request.abort());
        });

        addActiveRequest(cancelablePromise);
        callback && callback(cancelablePromise);

        return cancelablePromise;
    }

    static uploadFile(file) {
        if (isLocalAPI) {
            return Promise.resolve({ "@id": "/files/1" });
        }

        const formData = new FormData();
        formData.append("file", file);

        return API.request({
            path: isLocalAPI ? "/files/upload" : "/dap-hcp-patient/api/v1/files/upload",
            method: "POST",
            data: formData,
            token: store.getState().auth.access_token,
        });
    }

    static uploadFileRecaptcha(file, token, setState) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("token", token);

        return API.request({
            path: isLocalAPI ? "/files/upload-public" : "/dap-hcp-patient/api/v1/files/upload-public",
            method: "POST",
            data: formData,
        })
            .then((response) => {
                setState(response["@id"]);
                return response;
            })
            .catch(() => {
                setState(null);
                throw new Error("L'upload a échoué pour une raison inconnue");
            });
    }

    static formatViolations(response) {
        let violations = {};
        if (response instanceof Object && "violations" in response) {
            response.violations.forEach((violation) => {
                violations[violation.propertyPath] = violation.message;
            });
        }
        return violations;
    }

    static getErrorMessageFromStatus(code) {
        switch (code) {
            case 401:
                return "Accès refusé";
            case 400:
                return "Erreur dans les données envoyées";
            default:
                return "Un problème est survenu";
        }
    }

    static parseJson(str) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return null;
        }
    }

    static searchInRpps = (accessToken, params) => {
        const queryParams = {
            discr: params.type,
            page: params.page,
            itemsPerPage: params.itemsPerPage,
        };

        if (params.city) {
            queryParams["city"] = params.city;
        }

        if (params.fullName) {
            queryParams["fullName"] = params.fullName;
        }

        return API.request({
            token: isLocalAPI ? accessToken : null,
            path: isLocalAPI ? "/rpps_practitioners" : "/dap-hcp-patient/api/v1/customers/practitioners/rpps",
            queryParams: queryParams,
        })
            .then((response) => response)
            .catch((error) => error.message);
    };
}

export default API;
