import React from "react";

import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
    DefaultLoader,
    PractitionerDiagnosticHeader,
    PageTitle,
    useDidUpdate,
    getViolations,
} from "@rdcs/dap-front-library";

import useState, { steps } from "./useState";

import { tryFetchComplications } from "../../../actions/complications";
import {
    tryCreateDiagnosis,
    tryEditDiagnosis,
    tryFetchCheckUpQuestions,
    tryFetchLastDiagnosis,
    tryFetchDiagnosisRecos,
    trySetDiagnosisRecommendationAvailability,
} from "../../../actions/diagnosis";
import { tryMedicalPatient, trySinglePatient } from "../../../actions/patients";
import Questions from "../../../actions/questions";
import { tryFetchTreatments } from "../../../actions/treatments";

import DiagnosisStep1 from "../../../components/Diagnosis/DiagnosisStep1";
import DiagnosisStep2 from "../../../components/Diagnosis/DiagnosisStep2";
import DiagnosisStep3 from "../../../components/Diagnosis/DiagnosisStep3";
import DiagnosisStep4 from "../../../components/Diagnosis/DiagnosisStep4";
import AppLayout from "../../../components/Layout/AppLayout";

import { formatDate } from "../../../utils/utils";

const { tryFetchInformationsQuestions } = Questions;

const PatientsDiagnosticScreen = ({
    complications,
    diagnosis,
    patients,
    questions,
    treatments,
    tryCreateDiagnosis,
    tryEditDiagnosis,
    tryFetchCheckUpQuestions,
    tryFetchComplications,
    tryFetchDiagnosisRecos,
    tryFetchInformationsQuestions,
    tryFetchLastDiagnosis,
    tryFetchTreatments,
    tryMedicalPatient,
    trySetDiagnosisRecommendationAvailability,
    trySinglePatient,
    user,
}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const patientId = React.useMemo(() => parseInt(id), [id]);
    const breadcrumbs = React.useMemo(
        () => [
            { label: "Mes patients", link: "/mes-patients" },
            { label: "Fiche patient", link: `/mes-patients/${id}/dashboard` },
            { label: "Revoir le parcours de soin" },
        ],
        [id]
    );

    const [
        {
            medicalPatient,
            isNextStep,
            currentStep,
            dataLoading,
            form,
            stepForm,
            checkUpQuestions,
            checkUpQuestionsForceEnabled,
        },
        {
            setMedicalPatient,
            init,
            setFormValue,
            prevStep,
            setNextStep,
            errorStep,
            setStep1AndNextStep,
            nextStep,
            setGoalAndNextStep,
        },
    ] = useState();

    React.useEffect(() => {
        (patients.medicalPatient?.patient.id === patientId
            ? Promise.resolve(patients.medicalPatient)
            : tryMedicalPatient(patientId)
        )
            .then(setMedicalPatient)
            .catch((error) => {
                navigate({ pathname: "/mes-patients" });
                throw error;
            })
            .then(() =>
                Promise.all([
                    tryFetchCheckUpQuestions(),
                    trySinglePatient(patientId),
                    tryFetchLastDiagnosis(patientId),
                    tryFetchComplications(),
                    tryFetchTreatments(),
                    tryFetchInformationsQuestions(),
                ])
            )
            .then(([checkUpQuestionsInit, patient, patientLastDiagnosis]) =>
                init(checkUpQuestionsInit, patient, patientLastDiagnosis)
            )
            .catch(console.error);
    }, []);

    useDidUpdate(() => {
        if (isNextStep) {
            switch (currentStep) {
                case 1: {
                    const answers = {};
                    const checkUpAnswers = [];
                    const checkUpQuestionsFormPrefix = "CheckUpQuestionsForm#";

                    for (const i in questions.informationsQuestions) {
                        const question = questions.informationsQuestions[i];
                        let answer = null;
                        const answerLabel = `diagnosticAnswers${i}`;
                        const otherLabel = `${answerLabel}-other`;

                        if (stepForm[answerLabel] && stepForm[answerLabel].length > 0) {
                            answer = Array.isArray(stepForm[answerLabel])
                                ? [...stepForm[answerLabel]]
                                : [stepForm[answerLabel]];
                        }
                        if (stepForm[otherLabel]) {
                            answer.push(stepForm[otherLabel]);
                        }
                        if (answer) {
                            answers[i.toString()] = {
                                question: question.label,
                                values: answer,
                                fieldType: question.fieldType,
                                fieldLength: question.fieldLength,
                                mandatory: question.mandatory,
                            };
                        }
                    }
                    for (const stepFormField in stepForm) {
                        if (stepFormField.startsWith(checkUpQuestionsFormPrefix)) {
                            checkUpAnswers.push({
                                question: stepFormField.substring(checkUpQuestionsFormPrefix.length),
                                value: stepForm[stepFormField],
                            });
                        }
                    }
                    for (const checkUpQuestion of checkUpQuestions) {
                        if (!checkUpAnswers.find(({ question }) => question === checkUpQuestion["@id"])) {
                            checkUpAnswers.push({
                                question: checkUpQuestion["@id"],
                                value: null,
                            });
                        }
                    }

                    setStep1AndNextStep(
                        stepForm.pathology,
                        stepForm.diabetesDiscoveryYear,
                        stepForm.treatmentGroup,
                        answers,
                        checkUpAnswers
                    );
                    break;
                }

                case 2:
                    tryCreateDiagnosis({
                        patient: {
                            ...form.patient,
                            checkUpAnswers: form.hasLastDiagnosis
                                ? []
                                : form.patient.checkUpAnswers // Do not resubmit checkUpAnswers
                                      .map(
                                          (
                                              checkUpAnswer // Set answer false if null
                                          ) =>
                                              checkUpAnswer.value === null
                                                  ? { ...checkUpAnswer, value: false }
                                                  : checkUpAnswer
                                      ),
                        },
                        pathology: form.pathology,
                        treatmentGroup: form.treatmentGroup,
                        complicationInstances: form.complicationInstances,
                        consultationAnswers: form.consultationAnswers,
                        diagnosticAnswers: form.diagnosticAnswers && Object.values(form.diagnosticAnswers),
                    })
                        .then((diagnosis) => {
                            setGoalAndNextStep(diagnosis.goal);
                        })
                        .catch(errorStep);
                    break;

                case 3:
                    tryEditDiagnosis(diagnosis.patientUncompletedDiagnosis.id, {
                        goal: parseFloat(stepForm.goal),
                        goalJustification: stepForm.goalJustification,
                        bsMax: form.bsMax,
                        bsMin: form.bsMin,
                    })
                        .then((diagnosis) => tryFetchDiagnosisRecos(diagnosis, null))
                        .then(setNextStep)
                        .catch(errorStep);
                    break;

                case 4: {
                    const diagnosisRecommendationAvailabilityRequests = [];

                    for (const { id, active } of diagnosis.recos) {
                        const recoId = id.toString();

                        if (recoId in stepForm && stepForm[recoId] !== active) {
                            diagnosisRecommendationAvailabilityRequests.push(
                                trySetDiagnosisRecommendationAvailability(id, stepForm[recoId])
                            );
                        }
                    }

                    Promise.all(diagnosisRecommendationAvailabilityRequests)
                        .then(() => tryEditDiagnosis(diagnosis.patientUncompletedDiagnosis.id, { completed: true }))
                        .then(() => navigate(`/mes-patients/${patientId}`))
                        .catch(errorStep);
                    break;
                }
            }
        }
    }, [isNextStep]);

    const diagnosisCreateError = React.useMemo(
        () => getViolations(diagnosis.diagnosisCreateError),
        [diagnosis.diagnosisCreateError]
    );
    const diagnosisEditError = React.useMemo(
        () => getViolations(diagnosis.diagnosisEditError),
        [diagnosis.diagnosisEditError]
    );
    let diagnosisLatestDate = "";
    let diagnosisDate = "";
    let diagnosisAuthor = "";
    if (form?.hasLastDiagnosis) {
        diagnosisLatestDate = diagnosis.patientLastDiagnosis.updatedAt
            ? diagnosis.patientLastDiagnosis.updatedAt
            : diagnosis.patientLastDiagnosis.createdAt;
        diagnosisDate = formatDate(diagnosisLatestDate);
        diagnosisAuthor = `${diagnosis.patientLastDiagnosis.createdBy.firstName} ${diagnosis.patientLastDiagnosis.createdBy.lastName}`;
    }

    const DiagnosisStep = React.useMemo(() => {
        if (dataLoading === false) {
            switch (currentStep) {
                case 1:
                    return (
                        <DiagnosisStep1
                            informationsQuestions={questions.informationsQuestions}
                            treatmentGroups={treatments.list}
                            checkUpQuestions={checkUpQuestions.filter((q) => {
                                switch (true) {
                                    case q.label === "Consultation d'un Pédiatre" &&
                                        moment().diff(moment(patients.singlePatient.dateOfBirth), "years") > 15:
                                        return false;
                                    case q.label === "Consultation d'un Endocrino-diabétologue" &&
                                        form.pathology !== "DT1":
                                        return false;
                                }
                                return true;
                            })}
                            defaultValues={form}
                            checkUpQuestionsForceEnabled={checkUpQuestionsForceEnabled}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            diagnosisDate={diagnosisDate}
                            diagnosisAuthor={diagnosisAuthor}
                        />
                    );
                case 2:
                    return (
                        <DiagnosisStep2
                            complications={complications.list}
                            defaultValues={form}
                            setFormValue={setFormValue}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            isLoading={diagnosis.diagnosisCreateLoading || diagnosis.recosLoading}
                            errors={diagnosisCreateError}
                        />
                    );
                case 3:
                    return (
                        <DiagnosisStep3
                            goal={form.goal}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            isLoading={diagnosis.diagnosisEditLoading || diagnosis.recosLoading}
                            errors={diagnosisEditError}
                        />
                    );
                case 4:
                    return (
                        <DiagnosisStep4
                            recos={diagnosis.recos}
                            patient={patients.singlePatient}
                            hcp={user}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            isLoading={diagnosis.diagnosisEditLoading}
                            changeDiagnosisVal={trySetDiagnosisRecommendationAvailability}
                        />
                    );
                default:
                    return undefined;
            }
        }
        return <DefaultLoader />;
    }, [
        dataLoading,
        currentStep,
        checkUpQuestions,
        form?.pathology,
        diagnosis.diagnosisCreateLoading,
        diagnosis.diagnosisEditLoading,
        diagnosis.recosLoading,
        diagnosisCreateError,
        diagnosisEditError,
    ]);
    return (
        <PageTitle title="Timkl - Nouvelle consultation">
            <AppLayout navActive={0} user={user} pageClass="page-dashboard" breadcrumbs={breadcrumbs}>
                {medicalPatient && (
                    <PractitionerDiagnosticHeader
                        patient={medicalPatient.patient}
                        steps={steps}
                        index={currentStep - 1}
                    />
                )}

                <main className="diagnostic__main">
                    <div className="diagnostic__mainContainer">
                        {form?.hasLastDiagnosis && (
                            <p className="txtItalic mt-2">
                                Parcours de soin défini le {diagnosisDate} par {diagnosisAuthor}
                            </p>
                        )}
                        {DiagnosisStep}
                    </div>
                </main>
            </AppLayout>
        </PageTitle>
    );
};

PatientsDiagnosticScreen.propTypes = {
    complications: PropTypes.object.isRequired,
    diagnosis: PropTypes.object.isRequired,
    patients: PropTypes.object.isRequired,
    questions: PropTypes.object.isRequired,
    treatments: PropTypes.object.isRequired,
    tryCreateDiagnosis: PropTypes.func.isRequired,
    tryEditDiagnosis: PropTypes.func.isRequired,
    tryFetchCheckUpQuestions: PropTypes.func.isRequired,
    tryFetchComplications: PropTypes.func.isRequired,
    tryFetchDiagnosisRecos: PropTypes.func.isRequired,
    tryFetchInformationsQuestions: PropTypes.func.isRequired,
    tryFetchLastDiagnosis: PropTypes.func.isRequired,
    tryFetchTreatments: PropTypes.func.isRequired,
    tryMedicalPatient: PropTypes.func.isRequired,
    trySetDiagnosisRecommendationAvailability: PropTypes.func.isRequired,
    trySinglePatient: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth: { user }, patients, complications, treatments, diagnosis, questions }) => ({
    user,
    patients,
    complications,
    treatments,
    diagnosis,
    questions,
});

const mapDispatchToProps = {
    tryCreateDiagnosis,
    tryEditDiagnosis,
    tryFetchCheckUpQuestions,
    tryFetchComplications,
    tryFetchDiagnosisRecos,
    tryFetchInformationsQuestions,
    tryFetchLastDiagnosis,
    tryFetchTreatments,
    tryMedicalPatient,
    trySetDiagnosisRecommendationAvailability,
    trySinglePatient,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientsDiagnosticScreen);
