import React from "react";
import PropTypes from 'prop-types';

import moment from 'moment';
import { Button, InputText, Select, InputDateTime } from "@rdcs/dap-front-library";

import useState from './useState';

const PatientTaskForm = ({
    patientId,
    patientPractitioners,
    taskLabels,
    isLoading,
    onSubmit,
}) => {
    const patient = React.useMemo(() => `/patients/${patientId}`, [patientId]);

    const [{
        label,
        showLabelInput,
        responsible,
        dueAt
    }, {
        selectLabel,
        setLabel,
        setResponsible,
        setDueAt
    }] = useState();

    const taskLabelOptions = React.useMemo(() => ([
        { label: "-- Choisir --", value: "" },
        ...taskLabels
            .sort((a, b) => (a.order < b.order ? -1 : 1))
            .map(tL => ({ label: tL.label, value: tL.label })),
        { label: "Personnalisé", value: "custom" }
    ]), [taskLabels]);

    const patientPractitionersOptions = React.useMemo(() => ([
        { label: "-- Choisir --", value: "" },
        ...patientPractitioners.map(aR => ({
            label: aR.user.firstName + " " + aR.user.lastName,
            value: aR.user["@id"]
        }))
    ]),[patientPractitioners]);

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                onSubmit({
                    patient,
                    label,
                    responsible,
                    dueAt: moment(dueAt).format('YYYY-MM-DD[T]HH:mm:ssZ')
                });
            }}
        >
            <Select
                label="Intitulé de l'action"
                options={taskLabelOptions}
                onChange={selectLabel}
                required
            />
            {showLabelInput && (
                <InputText
                    onChange={setLabel}
                    placeholder="Saisissez le titre de l'action"
                    required
                />
            )}
            <Select
                options={patientPractitionersOptions}
                label="Assignée à"
                onChange={setResponsible}
            />
            <InputDateTime
                label="Avant le"
                placeholder="Choisissez une date"
                onChange={setDueAt}
                minDate={new Date()}
            />
            <Button className="button__primaryButton mt-3" isLoading={isLoading}>
                Ajouter l'action
            </Button>
        </form>
    );
}

PatientTaskForm.propTypes = {
    patientId: PropTypes.number.isRequired,
    patientPractitioners: PropTypes.arrayOf(PropTypes.object).isRequired,
    taskLabels: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default PatientTaskForm;