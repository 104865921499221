import React from 'react';
import PropTypes from 'prop-types';

import Moment from "moment";
import { Icon, Button, Modal, ImagePreview } from "@rdcs/dap-front-library";

import { getInitials } from "../../utils/utils";

import defaultUserImage from "@rdcs/dap-front-library/static/images/default-user.png";

const PatientCard = ({
    patient: { 
        avatar,
        firstName,
        lastName,
        email,
        phoneNumber,
        dateOfBirth,
        ssn
     },
     onRemovePatient
}) => {
    const modalRef = React.useRef(null);

    return (
        <div className="proSanteList__item">
            <div className="proSanteList__itemVisuel">
                {avatar ? (
                    <ImagePreview
                        className="proSanteList__itemVisuel-img"
                        filename={avatar && avatar.filename}
                        fallbackImageUrl={defaultUserImage}
                    />
                ) : (
                    <span className="proSanteList__itemVisuel-initiales">
                        {getInitials(firstName, lastName)}
                    </span>
                )}
            </div>
            <div>
                <p className="title-4">
                    {firstName} {lastName}
                </p>
                <p className="proSanteList__itemSubtitle">
                    {email}
                    {phoneNumber
                        ? " :: " + phoneNumber
                        : ""}
                </p>
                <p className="proSanteList__itemSubtitle">
                    {"Né(e) le : " +
                        Moment(dateOfBirth).format("DD/MM/YYYY")}
                </p>
                <p className="proSanteList__itemSubtitle">
                    {"Numéro de sécurité sociale : " + ssn}
                </p>
            </div>
            <div className="proSanteList__item__actions">
                <Modal
                    buttonClass="moderationListItem__action"
                    buttonContent={
                        <span className="proSanteList__itemActions-iconContainer">
                            <Icon name="close" className="proSanteList__itemActions-icon" />
                            Retirer de mes patients
                        </span>
                    }
                    ref={modalRef}
                >
                    <div className="modal__title">Confirmer la suppression</div>
                    <div className="modal__btnGroup">
                        <Button
                            className="button__secondaryButton"
                            onClick={() => modalRef.current.toggleModal()}
                        >
                            Annuler
                        </Button>
                        <Button
                            className="button__primaryButton"
                            onClick={onRemovePatient}
                        >
                            Supprimer
                        </Button>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

PatientCard.propTypes = {
    patient: PropTypes.shape({
        avatar: PropTypes.shape({
            filename: PropTypes.string.isRequired,
        }),
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string,
        dateOfBirth: PropTypes.string,
        ssn: PropTypes.string,
    }).isRequired,
    onRemovePatient: PropTypes.func.isRequired,
};

export default React.memo(PatientCard);