import * as types from "./types";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from '../store';

export const tryFetchThematic = () => dispatch => {
    const {
        auth: {
            access_token,
        },
        thematic: {
            datas,
            loaded
        }
    } = store.getState();

    if (loaded) {
        return Promise.resolve(datas);
    }

    dispatch({ type: types.THEMATICS_FETCH_REQUESTED });
    return (
        API.request({
            path: isLocalAPI ? "/thematics" : "/dap-hcp-patient/api/v1/customers/patients/thematics",
            queryParams: {
                pagination: false
            },
            token: access_token
        })
        .then(response => {
            dispatch({ type: types.THEMATICS_FETCH_SUCCESS, data: response["hydra:member"] });
            return response["hydra:member"];
        })
        .catch(error => {
            dispatch({ type: types.THEMATICS_FETCH_FAILURE, data: error });
            throw error;
        })
    );
};

export const tryCreateThematic = data => dispatch => {
    const {
        auth: {
            access_token,
        },
        thematic: {
            datas,
        }
    } = store.getState();

    dispatch({ type: types.THEMATICS_CREATE_REQUESTED });
    return (
        API.request({
            path: isLocalAPI ? "/thematics" : "/dap-hcp-patient/api/v1/customers/patients/thematics",
            method: "POST",
            token: access_token,
            data,
        })
        .then(response => {
            dispatch({ type: types.THEMATICS_CREATE_SUCCESS, data: response });
            return response;
        })
        .catch(error => {
            dispatch({ type: types.THEMATICS_CREATE_FAILURE, data: error });
            throw error;
        })
    );
};

export const tryEditThematic = (id, data) => dispatch => {
    const {
        auth: {
            access_token,
        },
        thematic: {
            datas,
        }
    } = store.getState();

    dispatch({ type: types.THEMATICS_EDIT_REQUESTED });
    return (
        API.request({
            path: isLocalAPI ? "/thematics/{id}" : "/dap-hcp-patient/api/v1/customers/patients/thematics/{id}",
            uriParams: {
                id
            },
            method: "PUT",
            token: access_token,
            data,
        })
        .then(response => {
            dispatch({ type: types.THEMATICS_EDIT_SUCCESS, data: response });
            return response;
        })
        .catch(error => {
            dispatch({ type: types.THEMATICS_EDIT_FAILURE, data: error });
            throw error;
        })
    );
};

export const tryDeleteThematic = id => dispatch => {
    const {
        auth: {
            access_token,
        },
    } = store.getState();

    dispatch({ type: types.THEMATICS_DELETE_REQUESTED });
    return (
        API.request({
            path: isLocalAPI ? "/thematics/{id}" : "/dap-hcp-patient/api/v1/customers/patients/thematics/{id}",
            uriParams: {
                id
            },
            method: "DELETE",
            token: access_token,
       })
        .then(response => {
            dispatch({ type: types.THEMATICS_DELETE_SUCCESS, data: id });
            return response;
        })
        .catch(error => {
            dispatch({ type: types.THEMATICS_DELETE_FAILURE, data: error });
            throw error;
        })
    );
};