import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { tryLogout } from "../../actions/auth";

const LogoutScreen = ({ tryLogout }) => {
    const location = useLocation();

    React.useEffect(() => {
        tryLogout();
    }, []);

    return (
        <Navigate
            to={"/connexion"}
            state={{
                from: location,
                userDisabled: location.state && location.state.userDisabled,
            }}
        />
    );
};

LogoutScreen.propTypes = {
    tryLogout: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    tryLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutScreen);
