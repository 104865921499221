const sortArrayByDueAtAndPosition = array => (
    array.sort((a, b) => {
        const dateDiff = new Date(a.dueAt) - new Date(b.dueAt);
        if (dateDiff !== 0) return dateDiff;

        if (a.recommendation.position === b.recommendation.position) return 0;
        else if (a.recommendation.position === null) return 1;
        else if (b.recommendation.position === null) return -1;
        else return a.recommendation.position < b.recommendation.position ? -1 : 1;
    })
);

export default sortArrayByDueAtAndPosition;