import React from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { Document, Page, View, Text, Image } from "@react-pdf/renderer";

import Logo from "../../../assets/img/logo.png";
import Exam from "../../../assets/img/exam.png";
import Steto from "../../../assets/img/steto.png";
import styles from "./PDFConsults.styled";

const PDFDocument = ({ diagnostics, patient: { pointOfContact }, hcp: { firstName, lastName }, nbOfMonths }) => {
    const sortedAndGroupedRecommendations = React.useMemo(() => {
        const sorted = diagnostics.sort((a, b) => new Date(a.dueAt) - new Date(b.dueAt));

        const groupedByMonth = sorted.reduce((acc, curr) => {
            const recommendationDate = moment(new Date(curr.dueAt));
            const monthDifference = Math.floor(recommendationDate.diff(new Date(), "months", true));

            if (monthDifference >= 0 && monthDifference <= nbOfMonths) {
                const month = recommendationDate.format("MMMM YYYY");

                if (!acc[month]) {
                    acc[month] = [];
                }

                acc[month].push(curr);
            }

            return acc;
        }, {});

        // Convertir l'objet en un tableau de paires clé-valeur, trier le tableau et le convertir à nouveau en un objet
        const sortedGroupedByMonth = Object.entries(groupedByMonth).reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {});

        return sortedGroupedByMonth;
    }, [diagnostics]);

    return (
        <Document author="DAP">
            <Page style={styles.body} size="A4" orientation="portrait">
                <View style={styles.page}>
                    <View style={styles.container}>
                        <View style={styles.header}>
                            <Image src={Logo} style={styles.logo}></Image>
                            <View style={[styles.section, styles.contact]}>
                                <Text style={[styles.font12, styles.bold]}>
                                    <Text style={styles.light}>Médecin traitant : </Text> {`${firstName} ${lastName}`}
                                </Text>
                                {pointOfContact && (
                                    <Text style={[styles.bold, styles.font12]}>
                                        <Text style={styles.light}> Référent de proximité : </Text> {`${pointOfContact.firstName} ${pointOfContact.lastName}`}
                                    </Text>
                                )}
                            </View>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.date}>Bloc-note - {moment(new Date()).format("DD/MM/YYYY")}</Text>
                        </View>

                        <View style={styles.section}>
                            <View style={styles.subHeader}>
                                <Text style={styles.sectionTitle}>Mes prochaines visites</Text>
                                <View style={styles.legendContainer}>
                                    <View style={styles.legendItem}>
                                        <Image src={Steto} style={styles.logoLegend} />
                                        <Text style={styles.font12}>Consultations</Text>
                                    </View>
                                    <View style={styles.legendItem}>
                                        <Image src={Exam} style={styles.logoLegend} />
                                        <Text style={styles.font12}>Examens</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View>
                            {Object.entries(sortedAndGroupedRecommendations).map(([month, recommendations]) => (
                                <View style={styles.monthContainer} key={month}>
                                    <Text style={styles.monthTextContainer}>Avant {month} </Text>
                                    <View>
                                        {recommendations.map((recommendation) => {
                                            return (
                                                <View wrap={false} style={styles.visitContainer} key={recommendation.id}>
                                                    <View>
                                                        {recommendation.recommendation.type === "consultation" ? (
                                                            <Image style={styles.logoLine} src={Steto} />
                                                        ) : (
                                                            <Image style={styles.logoLine} src={Exam} />
                                                        )}
                                                    </View>
                                                    {/* Affichez les informations souhaitées de chaque recommandation ici */}
                                                    <Text style={styles.lineText}>{recommendation.recommendation.title}</Text>
                                                </View>
                                            );
                                        })}
                                    </View>
                                </View>
                            ))}
                        </View>

                        <View>
                            <Text style={styles.consult}>Consulter les examens en ligne sur {window.location.origin}</Text>
                        </View>

                        <View>
                            <View style={[styles.section, styles.row, styles.lastSection]}>
                                <View style={[styles.cell, styles.rdv]}>
                                    <Text style={styles.font12}>Votre prochain Rendez-vous :</Text>
                                    <View style={styles.row}>
                                        <View style={styles.cell}>
                                            <Text style={styles.nextConsultText}>
                                                le : {"          "}/{"          "}/
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.font12}>Avec : </Text>
                                        <View style={styles.flex}>
                                            <Text style={styles.checkbox} />
                                            <Text style={styles.font12}>Médecin traitant</Text>
                                        </View>
                                        {pointOfContact && pointOfContact.roles.some((role) => role === "ROLE_PHARMACIST") && (
                                            <View style={styles.flex}>
                                                <Text style={styles.checkbox} />
                                                <Text style={styles.font12}>Pharmacien</Text>
                                            </View>
                                        )}
                                        {pointOfContact && pointOfContact.roles.some((role) => role === "ROLE_NURSE") && (
                                            <View style={styles.flex}>
                                                <Text style={styles.checkbox} />
                                                <Text style={styles.font12}>Infirmier</Text>
                                            </View>
                                        )}
                                    </View>
                                </View>
                                <View style={styles.cell}>
                                    <View style={styles.notes}>
                                        <Text style={styles.font12}>Notes :</Text>
                                        <View style={styles.line} />
                                        <View style={styles.line} />
                                        <View style={styles.line} />
                                        <View style={styles.line} />
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

PDFDocument.propTypes = {
    diagnostics: PropTypes.arrayOf(
        PropTypes.shape({
            "@id": PropTypes.string,
            id: PropTypes.number,
            active: PropTypes.bool,
            dueAt: PropTypes.string,
            recommendation: PropTypes.shape({
                "@id": PropTypes.string,
                id: PropTypes.number,
                title: PropTypes.string,
                type: PropTypes.oneOf(["consultation", "information", "examination"]),
            }),
        })
    ),
    hcp: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }),
    patient: PropTypes.shape({
        pointOfContact: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            roles: PropTypes.arrayOf(PropTypes.string),
        }),
    }),
    nbOfMonths: PropTypes.number.isRequired,
};

export default PDFDocument;
