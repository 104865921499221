import React from "react";
import PropTypes from "prop-types";

import { Select } from "@rdcs/dap-front-library";

const DelegateSelector = ({ coordinators, currentCoordinatorId, onChange }) => {
    const [delegate, setDelegate] = React.useState(currentCoordinatorId);

    React.useEffect(() => {
        onChange(delegate);
    }, [delegate, onChange]);

    return (
        <>
            <Select
                key={coordinators.length}
                label="Votre facilitateur délégué"
                options={[
                    { label: "Aucun", value: "" },
                    ...coordinators.map((c) => ({
                        label: c.firstName + " " + c.lastName,
                        value: c.id,
                    })),
                ]}
                defaultValue={currentCoordinatorId}
                onChange={setDelegate}
                legend="Un facilitateur délégué pourra définir un parcours de soin complet pour vos patients, y compris des recommandations."
            />
        </>
    );
};

DelegateSelector.propTypes = {
    currentCoordinatorId: PropTypes.number.isRequired,
    coordinators: PropTypes.arrayOf(
        PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            departments: PropTypes.arrayOf(
                PropTypes.shape({
                    code: PropTypes.string.isRequired,
                }).isRequired
            ).isRequired,
        }).isRequired
    ).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default DelegateSelector;
