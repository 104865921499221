import React from "react";

import BaseScreen from './BaseScreen';

const ContactScreen = () => (
    <BaseScreen
        title='Contact'
        content='Contact'
    />
);

ContactScreen.propTypes = {};

export default ContactScreen;
