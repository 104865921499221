import React from 'react';

const defaultState = {
    medicalPatient: null,
    isNextStep: false,
    currentStep: 1,
    dataLoading: true,
    form: null,
    stepForm: null,
    checkUpQuestions: [],
    checkUpQuestionsForceEnabled: []
};

const checkUpQuestionsIcons = {
    "Consultation d'un Ophtalmologue": 'complications-occulaires',
    "Consultation d'un Chirurgien-dentiste": 'teeth',
    "Consultation d'un Pédiatre": 'pro',
    "Consultation d'un Endocrino-diabétologue": 'pro'
}

export const steps = [
    "Informations",
    "Complications",
    "Objectifs",
    "Validation"
];

const getCheckUpAnswerVal = (questionId, checkUpAnswers) => {
    const answer = checkUpAnswers && checkUpAnswers.find(a => a.question === questionId);

    return answer ? answer.value : null;
};

const setFormValue = (oldForm, field, value) => {
    const levels = field.split(".");
    const firstLevel = levels[0];
    const secondLevel = levels[1];

    return {
        ...oldForm,
        [firstLevel]: secondLevel ? { ...oldForm[firstLevel], [secondLevel]: value } : value,
    };
};

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'SET_MEDICAL_PATIENT':
            return {
                ...prevState,
                medicalPatient: action.medicalPatient,
                isNextStep: false,
            }
        case 'INIT':
            const checkUpQuestionsForceEnabled = prevState.checkUpQuestionsForceEnabled.slice();
            const checkUpAnswers = action.checkUpQuestions.map(q => ({
                question: q["@id"],
                value: getCheckUpAnswerVal(q["@id"], action.patient.checkUpAnswers)
            }))

            for (const checkUpAnswer of checkUpAnswers) {
                if (checkUpAnswer.value === null) {
                    checkUpQuestionsForceEnabled.push(checkUpAnswer.question);
                }
            }

            return {
                ...prevState,
                dataLoading: false,
                isNextStep: false,
                checkUpQuestions: action.checkUpQuestions.map(q => ({
                    ...q,
                    icon: checkUpQuestionsIcons[q.label]
                })),
                checkUpQuestionsForceEnabled,
                form: {
                    hasLastDiagnosis: action.lastDiagnosis !== null,
                    patient: {
                        id: action.patient["@id"],
                        diabetesDiscoveryYear: action.patient.diabetesDiscoveryYear,
                        checkUpAnswers
                    },
                    pathology: action.lastDiagnosis?.pathology,
                    treatmentGroup: action.lastDiagnosis?.treatmentGroup && action.lastDiagnosis.treatmentGroup["@id"],
                    complicationInstances: action.lastDiagnosis?.complicationInstances && action.lastDiagnosis?.complicationInstances.map(c => ({
                        complication: c.complication,
                        value: c.value
                    })) || [],
                    goal: action.lastDiagnosis?.goal,
                    bsMin: action.lastDiagnosis?.bsMin,
                    bsMax: action.lastDiagnosis?.bsMax,
                    completed: false,
                    diagnosticAnswers: action.lastDiagnosis?.diagnosticAnswers || {},
                }
            }
        case 'SET_FORM_VALUE':
            return {
                ...prevState,
                form: setFormValue(prevState.form, action.field, action.value),
                isNextStep: false,
            }
        case 'SET_GOAL_AND_NEXT_STEP':
            return {
                ...prevState,
                form: setFormValue(prevState.form, 'goal', action.goal),
                currentStep: prevState.currentStep + 1,
                isNextStep: false,
            }
        case 'PREV_STEP':
            return {
                ...prevState,
                currentStep: prevState.currentStep - 1,
                isNextStep: false,
            }
        case 'SET_NEXT_STEP':
            return {
                ...prevState,
                currentStep: prevState.currentStep + 1,
                isNextStep: false,
                stepForm: null,
            }
        case 'ERROR_STEP':
            return {
                ...prevState,
                isNextStep: false,
                stepForm: null,
            }
        case 'SET_STEP_1_AND_NEXT_STEP':
            return {
                ...prevState,
                currentStep: prevState.currentStep + 1,
                isNextStep: false,
                stepForm: null,
                form: {
                    ...prevState.form,
                    pathology: action.pathology,
                    treatmentGroup: action.treatmentGroup,
                    patient: {
                        ...prevState.form.patient,
                        diabetesDiscoveryYear: parseInt(action.diabetesDiscoveryYear),
                        checkUpAnswers: action.checkUpAnswers
                    },
                    diagnosticAnswers: action.diagnosticAnswers
                },
            }
        case 'NEXT_STEP':
            return {
                ...prevState,
                isNextStep: true,
                stepForm: action.stepForm,
            }
        default:
            return prevState;
    }
};

const useState = () => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(() => ({

        setMedicalPatient: (medicalPatient) => dispatch({ type: 'SET_MEDICAL_PATIENT', medicalPatient }),

        init: (checkUpQuestions, patient, lastDiagnosis) => {
            dispatch({
                type: 'INIT',
                checkUpQuestions,
                patient,
                lastDiagnosis
            });
        },

        setFormValue: (field, value) => dispatch({ type: 'SET_FORM_VALUE', field, value }),

        prevStep: () => dispatch({ type: 'PREV_STEP' }),

        setNextStep: () => dispatch({ type: 'SET_NEXT_STEP' }),

        setStep1AndNextStep: (
            pathology,
            diabetesDiscoveryYear,
            treatmentGroup,
            diagnosticAnswers,
            checkUpAnswers
        ) => dispatch({
            type: 'SET_STEP_1_AND_NEXT_STEP',
            pathology,
            diabetesDiscoveryYear,
            treatmentGroup,
            diagnosticAnswers,
            checkUpAnswers
        }),

        nextStep: (stepForm) => dispatch({ type: 'NEXT_STEP', stepForm }),

        errorStep: () => dispatch({ type: 'ERROR_STEP' }),

        setGoalAndNextStep: (goal) => dispatch({ type: 'SET_GOAL_AND_NEXT_STEP', goal }),

    }), []);

    return [state, methods];
};

export default useState;