import React from 'react';

const defaultState = {
    loading: true,
    nav: [
        {
            path: "/mes-patients",
            icon: "datas",
            title: "Mes patients"
        },
        {
            path: "/recommandations-de-la-has",
            icon: "stats",
            title: "Recommandations",
            subtitle: "Haute Autorité de santé"
        }
    ]
};

const pushOnNav = (prevNav, navItem) => {
    const nav = prevNav.slice();
    
    nav.push(navItem);
    return nav;
}

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'LOAD':
            return {
                ...prevState,
                loading: false,
            }
        case 'PUSH_BREAK_SCORE_QUESTIONS_ON_NAV':
            return {
                ...prevState,
                loading: false,
                nav: pushOnNav(prevState.nav, {
                    path: "/questionnaire-rupture",
                    icon: "question",
                    title: "Questionnaire d'identification de nouveaux patients"
                }),
            }
        default:
            return prevState;
    }
};

const useState = () => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(() => ({

        load: () => dispatch({ type: 'LOAD' }),

        addBreakScoreQuestionOnNav: (breakScoreQuestions) => {
            if (breakScoreQuestions.some(({ active }) => active)) {
                dispatch({ type: 'PUSH_BREAK_SCORE_QUESTIONS_ON_NAV' });
            } else {
                dispatch({ type: 'LOAD' });
            }
        },

    }), []);

    return [state, methods];
};

export default useState;