import React from 'react';

import Moment from "moment";

import { formatHBA1C } from "@rdcs/dap-front-library";

const numberOfItemsToPush = 3; // The number of items to show at beginning
const stateOfShowAddMeasure = false; // The state of opening add measure
const mainCategoriesName = ["HbA1c"]; // Choose a list of main categories to show

const defaultState = {
    loading: true,
    medicalPatient: null,
    measures: [],
    // One state for main categories of measures
    mainMeasures: {
        measureTypes: [], // An array which list all types of measures
        showMoreItem: [], // An array which contains all showMore (int) to slice the array when render
        showAddItem: [], // An array which list if the form to add an item should be shown or not (bool)
        addMeasureData: [] // An array with necessary datas to add a measure
    },
    // One state for others categories of measures
    othersMeasures: {
        measureTypes: [],
        showMoreItem: 3,
        showAddItem: false,
        showFilterItem: false,
        addMeasureData: {
            unit: ''
        },
        filter: {
            category: "", // Default category to all
            startDate: Moment(new Date())
                .subtract(1, "Y")
                .startOf("month")
                .format("YYYY-MM-DD[T]HH:mm:ssZ"), // Default startDate filter to start of month + (year - 1)
            endDate: Moment(new Date())
                .add(1, "Y")
                .endOf("month")
                .format("YYYY-MM-DD[T]HH:mm:ssZ") // Default endDate filter to end of month + (year + 1)
        }
    },
    chartData: {
        dates: [],
        values: []
    }
};

const defineChatData = (patientMeasure) => {
    let chartData = patientMeasure.filter(measure => measure.type.name === "HbA1c");

    chartData = chartData.reverse();
    if (chartData.length > 5) {
        chartData = chartData.slice(chartData.length - 5, chartData.length);
    }
    chartData = sortPatientMeasures(chartData).reverse();
    return {
        dates: chartData.map(data => data.date),
        values: chartData.map(data => parseFloat(data.value))
    };
}

const sortPatientMeasures = (patientMeasures) => patientMeasures.sort((a, b) => (
    Moment(b.date).diff(a.date, 'day') || Moment(b.createdAt).diff(a.createdAt, 'milliseconds')
));

const reducer = (prevState, action) => {
    let patientMeasures;

    switch (action.type) {
        case 'INIT':
            return {
                ...prevState,
                loading: false,
                measures: action.patientMeasure,
                mainMeasures: {
                    ...prevState.mainMeasures,
                    measureTypes: action.mainPatientMeasures.sort((a, b) => (a.title < b.title ? -1 : 1)),
                    showMoreItem: action.mainMeasuresShowMoreItemArray,
                    showAddItem: action.mainMeasuresShowAddItemArray,
                    addMeasureData: action.mainMeasuresAddMeasureDataArray
                },
                othersMeasures: {
                    ...prevState.othersMeasures,
                    addMeasureData: {
                        ...prevState.othersMeasures.addMeasureData,
                        ...action.addMeasureData
                    },
                    measureTypes: action.othersPatientMeasures.sort((a, b) => (a.title < b.title ? -1 : 1)),
                },
                chartData: {
                    ...prevState.chartData,
                    ...defineChatData(action.patientMeasure)
                }
            }
        case 'LOAD_MEDICAL_PATIENT':
            return {
                ...prevState,
                medicalPatient: action.medicalPatient,
            }
        case 'END_LOAD':
            return {
                ...prevState,
                loading: false,
            }
        case 'ADD_PATIENT_MEASURE':
            patientMeasures = sortPatientMeasures([action.patientMeasure, ...prevState.measures]);

            return {
                ...prevState,
                measures: patientMeasures,
                chartData: {
                    ...prevState.chartData,
                    ...defineChatData(patientMeasures)
                }
            }
        case 'ADD_MEASURE_TYPE_UNIT':
            return {
                ...prevState,
                othersMeasures: {
                    ...prevState.othersMeasures,
                    addMeasureData: {
                        ...prevState.othersMeasures.addMeasureData,
                        type: action.measureType['@id'],
                        unit: action.measureType.unit
                    }
                }
            }
        case 'ADD_MEASURE_TYPE_DATE':
            return {
                ...prevState,
                othersMeasures: {
                    ...prevState.othersMeasures,
                    addMeasureData: {
                        ...prevState.othersMeasures.addMeasureData,
                        date: Moment(action.date).format('YYYY-MM-DD[T]HH:mm:ssZ')
                    }
                }
            }
        case 'ADD_MEASURE_TYPE_VALUE':
            return {
                ...prevState,
                othersMeasures: {
                    ...prevState.othersMeasures,
                    addMeasureData: {
                        ...prevState.othersMeasures.addMeasureData,
                        value: action.value
                    }
                }
            }
        case 'MAIN_MEASURES_SHOW_ADD_ITEM':
            return {
                ...prevState,
                mainMeasures: {
                    ...prevState.mainMeasures,
                    showAddItem: {
                        ...prevState.mainMeasures.showAddItem,
                        [action.index]: !prevState.mainMeasures.showAddItem[action.index]
                    },
                    addMeasureData: {
                        ...prevState.mainMeasures.addMeasureData,
                        [action.index]: {
                            ...prevState.mainMeasures.addMeasureData[action.index],
                            type: action.item
                        }
                    }
                }
            }
        case 'ADD_MAIN_MEASURE_TYPE_DATE':
            return {
                ...prevState,
                mainMeasures: {
                    ...prevState.mainMeasures,
                    addMeasureData: {
                        ...prevState.mainMeasures.addMeasureData,
                        [action.index]: {
                            ...prevState.mainMeasures.addMeasureData[action.index],
                            date: Moment(action.date).format('YYYY-MM-DD[T]HH:mm:ssZ')
                        }
                    }
                }
            }
        case 'ADD_MAIN_MEASURE_TYPE_VALUE':
            return {
                ...prevState,
                mainMeasures: {
                    ...prevState.mainMeasures,
                    addMeasureData: {
                        ...prevState.mainMeasures.addMeasureData,
                        [action.index]: {
                            ...prevState.mainMeasures.addMeasureData[action.index],
                            value: action.value
                        }
                    }
                }
            }
        case 'MAIN_MEASURES_SHOW_MORE_ITEM':
            return {
                ...prevState,
                mainMeasures: {
                    ...prevState.mainMeasures,
                    showMoreItem: {
                        ...prevState.mainMeasures.showMoreItem,
                        [action.index]: prevState.mainMeasures.showMoreItem[action.index] + 3
                    }
                }
            }
        case 'SHOW_FILTER_ITEM':
            return {
                ...prevState,
                othersMeasures: {
                    ...prevState.othersMeasures,
                    showFilterItem: !prevState.othersMeasures.showFilterItem
                }
            }
        case 'SHOW_ADD_ITEM':
            return {
                ...prevState,
                othersMeasures: {
                    ...prevState.othersMeasures,
                    showAddItem: !prevState.othersMeasures.showAddItem
                }
            }
        case 'SET_FILTER_MEASURE':
            return {
                ...prevState,
                othersMeasures: {
                    ...prevState.othersMeasures,
                    filter: {
                        ...prevState.othersMeasures.filter,
                        category: action.measure
                    }
                }
            }
        case 'SET_FILTER_START_DATE':
            return {
                ...prevState,
                othersMeasures: {
                    ...prevState.othersMeasures,
                    filter: {
                        ...prevState.othersMeasures.filter,
                        startDate: Moment(action.startDate).startOf("month").format("YYYY-MM-DD[T]HH:mm:ssZ")
                    }
                }
            }
        case 'SET_FILTER_END_DATE':
            return {
                ...prevState,
                othersMeasures: {
                    ...prevState.othersMeasures,
                    filter: {
                        ...prevState.othersMeasures.filter,
                        endDate: Moment(action.endDate).endOf("month").format("YYYY-MM-DD[T]HH:mm:ssZ")
                    }
                }
            }
        case 'OTHER_MEASURES_SHOW_MORE_ITEM':
            return {
                ...prevState,
                othersMeasures: {
                    ...prevState.othersMeasures,
                    showMoreItem: prevState.othersMeasures.showMoreItem + 3
                }
            }
        default:
            return prevState;
    }
};

const useState = (patientId) => {
    const addMeasureData = React.useMemo(() => ({
        value: '',
        source: 'DAP',
        date: '',
        patient:  `/patients/${patientId}`
    }), []);

    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(() => ({

        init: (measuresList, listMeasureTypes) => { 
            const patientMeasure = sortPatientMeasures(measuresList.filter(measure => measure.patient.id === patientId));
    
            // Create an array with a value for each measure type
            const mainMeasuresShowMoreItemArray = [];
            const mainMeasuresShowAddItemArray = [];
            const mainMeasuresAddMeasureDataArray = [];
            
            const mainPatientMeasures = listMeasureTypes.filter(measure =>
                mainCategoriesName.some(categoryName => measure.name === categoryName)
            );
    
            mainPatientMeasures.forEach(() => {
                mainMeasuresShowMoreItemArray.push(numberOfItemsToPush); // mainMeasuresShowMoreItemArray = [3, 3, 3, 3, 3]*
                mainMeasuresShowAddItemArray.push(stateOfShowAddMeasure); // mainMeasuresShowAddItemArray = [false, false, false, false, false]
                mainMeasuresAddMeasureDataArray.push(addMeasureData); // mainMeasuresAddMeasureDataArray = [{patient: "".....}, {patient: "".....}, {patient: "".....}, {patient: "".....}, {patient: "".....}]
            });

            /* Define main categories END */
        
            /* Define others categories BEGINNING */
            const othersPatientMeasures = listMeasureTypes.filter(
                measure => !mainCategoriesName.some(categoryName => measure.name === categoryName)
            );
            /* Define others categories END */
        
            /* Define all defaults vars END */

            dispatch({
                type: 'INIT',
                patientMeasure,
                mainPatientMeasures,
                mainMeasuresShowMoreItemArray,
                mainMeasuresShowAddItemArray,
                mainMeasuresAddMeasureDataArray,
                othersPatientMeasures,
                addMeasureData
            });
        },

        endLoad: () => dispatch({ type: 'END_LOAD' }),

        loadMedicalPatient: (medicalPatient) => dispatch({ type: 'LOAD_MEDICAL_PATIENT', medicalPatient }),

        addPatientMeasure: (patientMeasure) => dispatch({ type: 'ADD_PATIENT_MEASURE', patientMeasure }),

        addMeasureTypeUnit: (measureType) => dispatch({ type: 'ADD_MEASURE_TYPE_UNIT', measureType }),

        addMeasureTypeDate: (date) => dispatch({ type: 'ADD_MEASURE_TYPE_DATE', date }),

        addMeasureTypeValue: (value) => dispatch({ type: 'ADD_MEASURE_TYPE_VALUE', value }),

        mainMeasuresShowAddItem: (item, index) => dispatch({ type: 'MAIN_MEASURES_SHOW_ADD_ITEM', item, index }),

        addMainMeasureTypeDate: (date, index) => dispatch({ type: 'ADD_MAIN_MEASURE_TYPE_DATE', date, index }),

        addMainMeasureTypeValue: (value, index) => dispatch({ type: 'ADD_MAIN_MEASURE_TYPE_VALUE', value, index }),

        mainMeasuresShowMoreItem: (index) => dispatch({ type: 'MAIN_MEASURES_SHOW_MORE_ITEM', index }),

        showFilterItem: () => dispatch({ type: 'SHOW_FILTER_ITEM' }),

        showAddItem: () => dispatch({ type: 'SHOW_ADD_ITEM' }),

        setFilterMeasure: (measure) => dispatch({ type: 'SET_FILTER_MEASURE', measure }),

        setFilterStartDate: (startDate) => dispatch({ type: 'SET_FILTER_START_DATE', startDate }),

        setFilterEndDate: (endDate) => dispatch({ type: 'SET_FILTER_END_DATE', endDate }),

        otherMeasuresShowMoreItem: () => dispatch({ type: 'OTHER_MEASURES_SHOW_MORE_ITEM' }),

    }), []);
    
    return [state, methods];
};

export default useState;