import moment from "moment/moment";

export const LOGIN_URL =
    window.env.AUTH_URL +
    "/oidc/authorize?apiKey=" +
    window.env.AUTH_API_KEY +
    "&client_id=" +
    window.env.AUTH_CLIENT_ID +
    "&redirect_uri=" +
    window.env.AUTH_REDIRECT_URI +
    "&lang=fr_FR";

export const getInitials = (firstName, lastName) => firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();

export const ageInInterval = (age, interval) => {
    const intervalValue = interval.split("_");
    let intervalParts = "";

    switch (intervalValue[0]) {
        case "inferior":
            intervalParts = "0-" + intervalValue[2];
            break;
        case "between":
            intervalParts = intervalValue[1] + "-" + intervalValue[3];
            break;
        case "superior":
            intervalParts = intervalValue[2] + "-130";
            break;
        default:
            break;
    }
    intervalParts = intervalParts.split("-");
    if (intervalParts.length !== 2) {
        console.error("ageInInterval interval param must be like: 18-25");
    }

    const minAge = parseInt(intervalParts[0]);
    const maxAge = parseInt(intervalParts[1]);
    const patientAge = moment().diff(age, "years");

    return patientAge >= minAge && patientAge <= maxAge;
};

export const getUniqueArrayOfObjects = (array, key) =>
    array.length > 0 && !array.includes(undefined)
        ? array
              .map((e) => e[key])
              // store the keys of the unique objects
              .map((e, i, final) => final.indexOf(e) === i && i)
              // eliminate the dead keys & store unique objects
              .filter((e) => array[e])
              .map((e) => array[e])
        : [];

export const getTreatmentName = (treatmentGroup) =>
    treatmentGroup.levelOne.name +
    (treatmentGroup.levelTwo ? " / " + treatmentGroup.levelTwo.name : "") +
    (treatmentGroup.levelThree ? " / " + treatmentGroup.levelThree.name : "");

export const isLocalAPI = process.env.REACT_APP_USE_LOCAL_API === "true";

export const formatDate = (dateAndTime) => {
    const date = new Date(dateAndTime);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("fr", { month: "long" }).format(date);
    const year = date.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
};
