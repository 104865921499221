import React from "react";
import PropTypes from 'prop-types';

import { AddButtonModal } from "@rdcs/dap-front-library";

import PatientNoteForm from "../../../../../../components/Patients/PatientNoteForm";

const RightSection = ({
    patientId,
    createLoading,
    createError,
    tryCreateNote
}) => (
    <div className='d-flex'>
        <AddButtonModal title='Ajouter une note' >
            {modalRef => (
                <PatientNoteForm
                    patientId={patientId}
                    onSubmit={form => 
                        tryCreateNote(form).then(() => {
                            modalRef.current.toggleModal();
                        })
                    }
                    isLoading={createLoading}
                    errors={createError}
                />
            )}
        </AddButtonModal>
    </div>
);

RightSection.propTypes = {
    patientId: PropTypes.number.isRequired,
    createLoading: PropTypes.bool.isRequired,
    createError: PropTypes.object.isRequired,
    tryCreateNote: PropTypes.func.isRequired,
};

export default React.memo(RightSection);