import React from "react";

import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { ButtonUpload, DropDown, Icon, TextArea } from "@rdcs/dap-front-library";

import AddVisit from "./addVisit";
import CheckboxesReport from "./checkboxesReport";
import RecoCard from "./recoCars";

import API from "../../../utils/API";
import { isLocalAPI } from "../../../utils/utils";

const g_listenObjectiveQuestions = [
    {
        question: "Avez-vous des éléments d’aggravation de la situation du patient à signaler ?",
        name: "aggravatingElements",
    },
    {
        question: "Avez-vous repéré des éléments bloquant la réalisation du parcours de soin du patient ? ",
        name: "blockingElements",
    },
    {
        question: "Avez-vous fixé un ou plusieurs objectifs avec le patient d'ici le prochain entretien ?",
        name: "objective",
    },
];

const ContentDropdown = ({
    actualReport,
    canModify,
    diagnosis,
    isLoading,
    patients,
    pointsOfAttention,
    prevObjective = null,
    setAllowBtn,
    setData,
    thematic,
}) => {
    const initialListenObjectiveState = React.useMemo(
        () =>
            g_listenObjectiveQuestions.reduce(
                (result, { name }) => ({
                    ...result,
                    [name]: actualReport?.[name] || null,
                }),
                {}
            ),
        [actualReport]
    );

    const [comment, setComment] = React.useState(actualReport ? actualReport.comment : null);

    const textComment = React.useRef(null);
    const [attachments, setAttachments] = React.useState(actualReport ? actualReport.attachments : null);

    const [completedPointsOfAttention, setCompletedPointsOfAttention] = React.useState(false);
    const [completedThematic, setCompletedThematic] = React.useState(false);

    const [listenObjectiveState, setListenObjectiveState] = React.useState(initialListenObjectiveState);

    const [checkboxesThematic, setCheckboxesThematic] = React.useState(actualReport?.thematics ?? []);

    const [checkboxesPointOfAttention, setCheckboxesPointOfAttention] = React.useState(
        actualReport?.patientPointOfAttentions.map((item) => item.pointOfAttention) ?? []
    );

    const [commentThematic, setCommentThematic] = React.useState(actualReport?.thematicsComment ?? null);
    const [commentPointOfAttention, setCommentPointOfAttention] = React.useState(
        actualReport?.pointOfAttentionsComment ?? null
    );

    const [noThematicComment, setNoConseilComment] = React.useState(actualReport?.noThematicsComment ?? null);
    const [noPointOfAttentionComment, setNoPointOfAttentionComment] = React.useState(
        actualReport?.noPointOfAttentionsComment ?? null
    );

    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);
    const patientIri = React.useMemo(() => `/patients/${patientId}`, [patientId]);

    React.useEffect(() => {
        setCompletedThematic(checkboxesThematic.length !== 0 || noThematicComment !== null || commentThematic !== null);
        setCompletedPointsOfAttention(
            checkboxesPointOfAttention.length !== 0 ||
                noPointOfAttentionComment !== null ||
                commentPointOfAttention !== null
        );
    }, [
        noThematicComment,
        commentThematic,
        checkboxesThematic,
        checkboxesPointOfAttention,
        noPointOfAttentionComment,
        commentPointOfAttention,
    ]);

    React.useEffect(() => {
        setAllowBtn(completedThematic && completedPointsOfAttention);
    }, [completedThematic, completedPointsOfAttention]);

    function transformArrayPointOf(array) {
        return array.map((item) => ({ pointOfAttention: item }));
    }

    React.useEffect(() => {
        setData({
            patient: patientIri,
            comment: comment,
            thematics: checkboxesThematic,
            patientPointOfAttentions: transformArrayPointOf(checkboxesPointOfAttention),
            attachments: isLocalAPI ? null : attachments,
            thematicsComment: commentThematic,
            noThematicsComment: noThematicComment,
            pointOfAttentionsComment: commentPointOfAttention,
            noPointOfAttentionsComment: noPointOfAttentionComment,
            ...listenObjectiveState,
        });
    }, [
        comment,
        checkboxesThematic,
        checkboxesPointOfAttention,
        commentThematic,
        commentPointOfAttention,
        attachments,
        noPointOfAttentionComment,
        noThematicComment,
        listenObjectiveState,
    ]);

    const consultAppointments = React.useMemo(
        () =>
            patients.patientAppointments.filter(({ recommendations }) =>
                recommendations.find(({ type }) => type === "consultation")
            ),
        [patients.patientAppointments]
    );

    const recoConsults = React.useMemo(() => {
        const now = moment().unix();
        return diagnosis.recos
            .filter(({ recommendation: { type } }) => type === "consultation")
            .map((reco) => {
                for (const consultAppointment of consultAppointments) {
                    if (
                        consultAppointment.recommendations.find(
                            (recommendation) => recommendation.id === reco.recommendation.id
                        )
                    ) {
                        return {
                            ...reco,
                            statusColor: "green",
                            appointment: consultAppointment,
                        };
                    }
                }
                return { ...reco, statusColor: now < moment(reco.dueAt).unix() ? "orange" : "red" };
            })
            .filter(({ appointment = null }) => !appointment?.doneAt)
            .sort((a, b) => {
                const codeA = a.statusColor.charCodeAt(0);
                const codeB = b.statusColor.charCodeAt(0);

                if (codeA !== codeB) {
                    return codeB - codeA;
                }
                return new Date(a.appointment?.date || a.dueAt) - new Date(b.appointment?.date || b.dueAt);
            });
    }, [diagnosis.recos, consultAppointments]);

    const uploadReportsDocumentAction = React.useCallback(
        (file) =>
            API.uploadFile(file)
                .then((item) => {
                    const fileURL = URL.createObjectURL(file);
                    setAttachments((prevAttachments) => [
                        ...(prevAttachments || []),
                        { file: item, fileName: file.name, url: fileURL },
                    ]);
                })
                .catch(() => {
                    throw new Error("L'upload a échoué pour une raison inconnue");
                }),
        []
    );

    const removeAttachment = (indexToRemove) => {
        setAttachments((prevAttachments) => prevAttachments.filter((_, index) => index !== indexToRemove));
    };

    const uploadPreviewNewWin = (url) => {
        const win = window.open(
            "",
            "Title",
            "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top=" +
                (screen.height - 400) +
                ",left=" +
                (screen.width - 840)
        );
        win.document.body.innerHTML = '<iframe width="100%" height="100%" src="' + url.url + '"></iframe>';
    };

    const updateListenState = (name) => (e) =>
        setListenObjectiveState((_listenObjectiveState) => ({
            ..._listenObjectiveState,
            [name]: e,
        }));

    return (
        <div className="mx-4 mt-4 d-flex flex-column justify-space-between" style={{ height: "100%" }}>
            <div>
                {prevObjective && (
                    <div className="mb-2">
                        <p className="report_titlePrevObj">Rappel de l’objectif fixé au précédent entretien : </p>
                        <p className="report_prevObj"> {prevObjective} </p>
                    </div>
                )}

                <DropDown open={actualReport} title="Points de vigilances" isCompleted={completedPointsOfAttention}>
                    <CheckboxesReport
                        setCheckboxes={setCheckboxesPointOfAttention}
                        checkboxes={checkboxesPointOfAttention}
                        question={pointsOfAttention}
                        pointsOfAttention
                        setComment={setCommentPointOfAttention}
                        setNoPointComment={setNoPointOfAttentionComment}
                        comment={commentPointOfAttention}
                        noPointComment={noPointOfAttentionComment}
                        disabled={!canModify}
                    />
                </DropDown>
                <DropDown open={actualReport} title="informations & conseils de santé" isCompleted={completedThematic}>
                    <CheckboxesReport
                        setCheckboxes={setCheckboxesThematic}
                        checkboxes={checkboxesThematic}
                        question={thematic}
                        setComment={setCommentThematic}
                        comment={commentThematic}
                        setNoPointComment={setNoConseilComment}
                        noPointComment={noThematicComment}
                        disabled={!canModify}
                    />
                </DropDown>
                <DropDown title="Parcours de soin">
                    <RecoCard reco={recoConsults} isLoading={isLoading} />
                    <div className="d-flex align-items-center mt-2 ml-2">
                        <AddVisit disabled={!canModify} />
                    </div>
                </DropDown>
                <DropDown open={actualReport} title="Ecoute & objectifs">
                    <div className="d-flex flex-column pxy-2">
                        {g_listenObjectiveQuestions.map(({ question, name }) => {
                            const _updateState = updateListenState(name);
                            const _state = listenObjectiveState[name];
                            const isSelected = _state !== null;

                            return (
                                <>
                                    <div className="d-flex align-items-center mb-2">
                                        {question}
                                        <div>
                                            <span
                                                className={`InputObjective  ${
                                                    isSelected ? "activeInputObjective" : null
                                                } ml-2 mr-2 cursorPointer`}
                                                onClick={() => canModify && _updateState("")}
                                            >
                                                Oui
                                            </span>
                                            <span
                                                className={`InputObjective  ${
                                                    !isSelected ? "activeInputObjective" : null
                                                } cursorPointer `}
                                                onClick={() => canModify && _updateState(null)}
                                            >
                                                Non
                                            </span>
                                        </div>
                                    </div>
                                    {isSelected &&
                                        (canModify ? (
                                            <div style={{ width: "100%" }}>
                                                <TextArea
                                                    className="textAreaBlueBorder"
                                                    onChange={_updateState}
                                                    defaultValue={_state}
                                                    disabled={!canModify}
                                                />
                                            </div>
                                        ) : (
                                            <div className="d-flex flex-column">
                                                <div className="mt-1 mb-4 ml-7">{_state}</div>
                                            </div>
                                        ))}
                                </>
                            );
                        })}
                    </div>
                </DropDown>
                <DropDown title="Documents associés">
                    <div className="mt-1">
                        {attachments?.map((item, index) => {
                            return (
                                <div className="cardReportDocument mb-2" key={index}>
                                    <p className="timkl__color--blue-dark">{item.fileName}</p>
                                    <div>
                                        <Icon
                                            size={20}
                                            className="icon-eye mr-2"
                                            color="#0069b4"
                                            onClick={() => uploadPreviewNewWin(item)}
                                        />
                                        <Icon
                                            size={20}
                                            className="icon-trash"
                                            color="#8ab5e1"
                                            onClick={() => canModify && removeAttachment(index)}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <ButtonUpload
                            className="timkl__color--blue-important uploadButtonNoBackNoBorder"
                            uploadAction={uploadReportsDocumentAction}
                            icon="add-circle"
                            secondary
                            isShowPreview={false}
                            disabled={!canModify}
                        >
                            Ajouter un nouveau document
                        </ButtonUpload>
                    </div>
                </DropDown>
                <DropDown open={actualReport} title="Note générale" isCompleted={comment !== null}>
                    <p className="ml-4 mt-2">Saisissez un commentaire global sur l’entretien si vous le souhaitez</p>
                    {!actualReport ? (
                        <div className="d-flex flex-column">
                            <TextArea
                                className="px-4  textAreaBlueBorder"
                                onChange={setComment}
                                ref={textComment}
                                defaultValue={comment}
                                isRequired={false}
                                disabled={!canModify}
                            />
                        </div>
                    ) : (
                        <div className="d-flex flex-column">
                            <div className="mt-2 ml-7">{comment}</div>
                        </div>
                    )}
                </DropDown>
            </div>
        </div>
    );
};

ContentDropdown.propTypes = {
    actualReport: PropTypes.object,
    canModify: PropTypes.bool.isRequired,
    diagnosis: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    patients: PropTypes.object.isRequired,
    pointsOfAttention: PropTypes.array.isRequired,
    prevObjective: PropTypes.any,
    setAllowBtn: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    thematic: PropTypes.object.isRequired,
};

const mapStateToProps = ({
    auth: { user, access_token },
    pointsOfAttention: { list },
    thematic: { datas },
    diagnosis,
    patients,
}) => ({
    user,
    access_token,
    pointsOfAttention: list,
    diagnosis,
    patients,
    thematic: datas,
});

export default connect(mapStateToProps)(ContentDropdown);
