import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    createLoading: false,
    createError: false,

    editLoading: false,
    editError: false,

    deleteLoading: false,
    deleteError: false,

    swapOrderLoading: false
};

for (const {
    camelName,
    answers
} of types.QUESTIONNAIRE_TYPES) {
    initialState[`${camelName}Questions`] = [];
    initialState[`${camelName}QuestionsLoading`] = false;
    initialState[`${camelName}QuestionsLoaded`] = 0;

    if (answers) {
        initialState[`${camelName}Answers`] = [];
        initialState[`${camelName}AnswersLoading`] = false;
        initialState[`${camelName}AnswersLoaded`] = 0;
        initialState[`${camelName}AnswersPatientId`] = -1;
    }
}

function questions(state = initialState, action) {
    switch (action.type) {
        /*  Potential Patients */
        case types.ADD_POTENTIAL_PATIENT_REQUESTED:
            return {
                ...state,
                createLoading: true,
                createError: false
            };

        case types.ADD_POTENTIAL_PATIENT_SUCCESS:
            return {
                ...state,
                createLoading: false,
                deleteLoading: false,
                editLoading: false
            };

        case types.ADD_POTENTIAL_PATIENT_FAILURE:
            return {
                ...state,
                createLoading: false,
                createError: action.data
            };

        case types.EDIT_POTENTIAL_PATIENT_REQUESTED:
            return {
                ...state,
                editLoading: true,
                editError: false
            };

        case types.EDIT_POTENTIAL_PATIENT_SUCCESS:
            return {
                ...state,
                editLoading: false
            };

        case types.EDIT_POTENTIAL_PATIENT_FAILURE:
            return {
                ...state,
                editLoading: false,
                editError: action.data
            };


        case types.DELETE_POTENTIAL_PATIENT_REQUESTED:
            return {
                ...state,
                deleteLoading: true,
                deleteError: false
            };

        case types.DELETE_POTENTIAL_PATIENT_SUCCESS:
            return {
                ...state,
                deleteLoading: false
            };

        case types.DELETE_POTENTIAL_PATIENT_FAILURE:
            return {
                ...state,
                deleteLoading: false,
                deleteError: action.data
            };

        default:
            break;
    }

    for (const {
        name,
        upType,
        camelName,
        format = data => data
    } of types.QUESTIONNAIRE_TYPES) {
        if (action.type.startsWith(upType)) {
            switch (action.type.substring(upType.length + 1)) {
                case 'QUESTIONS_FETCH_REQUESTED':
                    return {
                        ...state,
                        [`${camelName}QuestionsLoading`]: true,
                        [`${camelName}QuestionsLoaded`]: 0
                    };

                case 'QUESTIONS_FETCH_SUCCESS':
                    return {
                        ...state,
                        [`${camelName}QuestionsLoading`]: false,
                        [`${camelName}Questions`]: format(action.data),
                        [`${camelName}QuestionsLoaded`]: Date.now()
                    };

                case 'QUESTIONS_FETCH_FAILURE':
                    return {
                        ...state,
                        [`${camelName}QuestionsLoading`]: false,
                        [`${camelName}Questions`]: []
                    };

                case `QUESTION_ADD_REQUESTED`:
                    return {
                        ...state,
                        createLoading: true,
                        createError: false
                    };

                case `QUESTION_ADD_SUCCESS`:
                    toast.success(`La question ${name} a été créée`);
                    return {
                        ...state,
                        createLoading: false
                    };

                case `QUESTION_ADD_FAILURE`:
                    toast.error(`La question ${name} n'a pas pu être créée`);
                    return {
                        ...state,
                        createLoading: false,
                        createError: action.data
                    };

                case 'QUESTION_EDIT_REQUESTED':
                    return {
                        ...state,
                        editLoading: true,
                        editError: false
                    };

                case 'QUESTION_EDIT_SUCCESS':
                    toast.success(`La question ${name} a été modifiée`);
                    return {
                        ...state,
                        editLoading: false
                    };

                case 'QUESTION_EDIT_FAILURE':
                    toast.error(`La question ${name} n'a pas pu être modifiée`);
                    return {
                        ...state,
                        editLoading: false,
                        editError: action.data
                    };

                case 'ANSWERS_FETCH_REQUESTED':
                    return {
                        ...state,
                        [`${camelName}AnswersLoading`]: true,
                        [`${camelName}AnswersLoaded`]: 0,
                        [`${camelName}AnswersPatientId`]: -1,
                    };

                case 'ANSWERS_FETCH_SUCCESS':
                    return {
                        ...state,
                        [`${camelName}AnswersLoading`]: false,
                        [`${camelName}Answers`]: action.data,
                        [`${camelName}AnswersLoaded`]: Date.now(),
                        [`${camelName}AnswersPatientId`]: action.patientId,
                    };

                case 'ANSWERS_FETCH_FAILURE':
                    return {
                        ...state,
                        [`${camelName}AnswersLoading`]: false,
                        [`${camelName}Answers`]: []
                    };
                case 'ANSWER_ADD_REQUESTED':
                    return Object.assign({}, state, {
                        createLoading: true,
                        createError: false
                    });

                case 'ANSWER_ADD_SUCCESS':
                    return {
                        ...state,
                        createLoading: false
                    };

                case 'ANSWER_ADD_FAILURE':
                    return {
                        ...state,
                        createLoading: false,
                        createError: action.data
                    };

                case 'ANSWER_EDIT_REQUESTED':
                    return {
                        ...state,
                        editLoading: true,
                        editError: false
                    };

                case 'ANSWER_EDIT_SUCCESS':
                    return {
                        ...state,
                        editLoading: false
                    };

                case 'ANSWER_EDIT_FAILURE':
                    return {
                        ...state,
                        editLoading: false,
                        editError: action.data
                    };

                default:
                    break;
            }
        }
    }

    return state;
}

export default questions;
