import * as types from "./types";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import { userRole } from "../reducers/auth";

import { tryGigyaRefreshToken } from "./auth";

import store from '../store';

export const tryFetchProfileConsents = () => dispatch => {
    dispatch({ type: types.PROFILE_FETCH_CONSENTS_REQUESTED });
    return API.request({
        method: "POST",
        path: "/ciam/api/v1/access/schema",
        data: {
            apiKey: window.env.AUTH_API_KEY
        }
    })
    .then(response => {
        let consentsArray = [];
        for (var key in response) consentsArray.push({ id: key, ...response[key] });
        dispatch({ type: types.PROFILE_FETCH_CONSENTS_SUCCESS, data: consentsArray });
    })
    .catch(error => dispatch({ type: types.PROFILE_FETCH_CONSENTS_FAILURE, data: error }));
};

export const tryFetchProfile = () => dispatch => {
    const {
        auth: {
            access_token,
            user: {
                id
            }
        },
        profile: {
            profile,
            profileId,
            profileLoaded,
        }
    } = store.getState();

    if (profileLoaded && profileId === id) {
        return Promise.resolve(profile);
    }

    const userType = userRole();
    
    dispatch({ type: types.PROFILE_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/{userType}/{id}" : "/dap-hcp-patient/api/v1/customers/practitioners/{userType}/{id}",
        uriParams: { userType, id },
        token: access_token
    })
    .then(response => dispatch({ type: types.PROFILE_FETCH_SUCCESS, data: response, id }))
    .catch(error => dispatch({ type: types.PROFILE_FETCH_FAILURE, data: error }));
};

export const tryCreateProfile = (data, userType, gigyaExistingUserToken = null) => dispatch => {
    dispatch({ type: types.PROFILE_CREATE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/{userType}" : "/dap-hcp-patient/api/v1/customers/practitioners/{userType}/create",
        uriParams: {
            userType: userType
        },
        method: "POST",
        data: data,
        gigyaExistingUserToken: gigyaExistingUserToken
    })
    .then(response => dispatch({ type: types.PROFILE_CREATE_SUCCESS, data: response }))
    .catch(error => {
        dispatch({ type: types.PROFILE_CREATE_FAILURE, data: error });
        throw new Error("Le profil n'a pas pu être créé");
    });
};

export const tryFinalizeProfile = (uid, data) => dispatch => {
    dispatch({ type: types.PROFILE_FINALIZE_REQUESTED });
    return API.request({
        path: isLocalAPI
            ? "/finalize_practitioner/{uid}"
            : "/dap-hcp-patient/api/v1/customers/practitioners/finalize/{uid}",
        uriParams: {
            uid
        },
        method: "PUT",
        data: data
    })
    .then(response => dispatch({ type: types.PROFILE_FINALIZE_SUCCESS, data: response }))
    .catch(error => {
        dispatch({ type: types.PROFILE_FINALIZE_FAILURE, data: error });
        throw error;
    });
};

export const tryEditProfile = profile => dispatch => {
    const userType = userRole();
    const {
        auth: {
            access_token,
            refresh_token,
            user: {
                id
            }
        },
    } = store.getState();

    dispatch({ type: types.PROFILE_EDIT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/{userType}/{id}" : "/dap-hcp-patient/api/v1/customers/practitioners/{userType}/{id}",
        uriParams: { userType, id },
        method: "PUT",
        token: access_token,
        data: profile
    })
    .then(response => {
        dispatch({ type: types.PROFILE_EDIT_SUCCESS, data: response });
        return tryGigyaRefreshToken(refresh_token)(dispatch).then(() => response);
    })
    .catch(error => {
        dispatch({type: types.PROFILE_EDIT_FAILURE, data: error})
        throw error
    });
};
