import React, { Fragment, useCallback, useMemo } from "react";
import {
  DropDownItem,
  TextArea,
  toTree
} from "@rdcs/dap-front-library";

const CheckboxesItem = ({ checkboxes, item, defaultChecked, onChange, disabled }) => {
  const isItemInCheckboxes = useMemo(() => checkboxes.some(checkbox => checkbox["@id"] === item["@id"]), []);
  const _onChange = useCallback(e => onChange(e, item), []);

  return (
    <DropDownItem
        title={item.name}
        defaultChecked={isItemInCheckboxes || defaultChecked} 
        onChange={disabled || _onChange}
        disabled={disabled}
      />
  );
};

const CheckboxesList = ({ checkboxes, items, handleChangeConseilSante, handleChangeOther, comment, disabled, deep = 0 }) =>  
  items.map(item => (
    <Fragment key={item.key}>
      <div className={`${deep ? `ml-${deep * 4}` : undefined}`}>
        <CheckboxesItem
          checkboxes={checkboxes}
          item={item}
          defaultChecked={item.isOther === true && comment !== null}
          onChange={item.isOther === true ? handleChangeOther : handleChangeConseilSante}
          disabled={disabled}
        />
      </div>
      {item.children?.length ?
      <CheckboxesList
        checkboxes={checkboxes}
        items={item.children}
        handleChangeConseilSante={handleChangeConseilSante}
        handleChangeOther={handleChangeOther}
        comment={comment}
        disabled={disabled}
        deep={deep + 1}
      /> : null}
    </Fragment>
  ));

const CheckboxesReport = ({ checkboxes, setCheckboxes, question, pointsOfAttention, setComment, setNoPointComment, noPointComment, comment, disabled }) => {
  const [noPoint, setNoPoint] = React.useState(noPointComment !== null);
  const [otherPoint, setOtherPoint] = React.useState(comment !== null);

  const _questions = useMemo(() => {
    const _questions = toTree((question ?? []).map(q => ({...q, parent: q.parent?.id ?? null})) );
    const otherItemExists = _questions.some(item => item.name === "Autres (à préciser)");
  
    if (!otherItemExists) {
      _questions.push({ name: "Autres (à préciser)", value: false, isOther: true});
    }

    return _questions;
  }, [question]);

  const handleChangeConseilSante = useCallback((e, item) => {
      setCheckboxes((checkboxes) => {
        if (e) {
          if (!checkboxes.some(checkItem => checkItem['@id'] === item['@id'])) {
            return [...checkboxes, item];
          }
        } else {
          return checkboxes.filter(checkItem => checkItem['@id'] !== item['@id']);
        }
        return checkboxes;
      });
  }, []);

  const handleChangeOther = useCallback(() => {
    setNoPointComment(null);
    setOtherPoint(otherPoint => !otherPoint);
    setComment(''); 
  }, []);

  const handleChangeNoPoint = useCallback(() => {
    setNoPoint(noPoint => !noPoint);
    setNoPointComment('');
    setCheckboxes([]);
    setOtherPoint(false);
    setComment(null);
  }, []);

  return (
    <div className="mt-2 ml-2" >
      <h2 className="mb-2 timkl__color--black"> {pointsOfAttention ? "Point(s) de vigilance(s) abordés lors de l’entretien de suivi" : "Thématique(s) abordé(s) lors de l’entretien de suivi"}</h2>
      {!noPoint &&
        <>
          <CheckboxesList
            checkboxes={checkboxes}
            items={_questions}
            handleChangeConseilSante={handleChangeConseilSante}
            handleChangeOther={handleChangeOther}
            comment={comment}
            disabled={disabled}
          />
          {otherPoint && <TextArea onChange={setComment} placeholder={comment} className="textAreaBlueBorder"  />}

          <div className="mt-2 mb-2" style={{ width: "83px", borderBottom: "solid 1px #94BBE3" }}></div>
        </>
      }
      <DropDownItem title="Aucune des thématiques ci-dessus n’a été abordée" defaultChecked={noPoint} onChange={handleChangeNoPoint} disabled={disabled} ></DropDownItem>
      {noPoint ? <TextArea onChange={setNoPointComment} placeholder={noPointComment}  className="textAreaBlueBorder" disabled={disabled} /> : null}

    </div>
  )

}


export default CheckboxesReport