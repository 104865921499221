import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { userCan } from "../../reducers/auth";

import {
    constant,
    Button,
    InputEmail,
    InputNumber,
    InputImage,
    InputText,
    InputTel,
    Select,
    getViolations,
} from "@rdcs/dap-front-library";

import API from "../../utils/API";

import UserCan from "../Utils/UserCan";
import DelegateSelector from "./DelegateSelector";

const ProfileForm = ({
    profile,
    loading,
    violations,
    submit,
    coordinators,
}) => {
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [titre, setTitre] = React.useState("");
    const [establishment, setEstablishment] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [zipcode, setZipcode] = React.useState("");
    const [city, setCity] = React.useState("");
    const [phoneNumber1, setPhoneNumber1] = React.useState("");
    const [phoneNumber2, setPhoneNumber2] = React.useState("");
    const [avatar, setAvatar] = React.useState(null);
    const [delegate, setDelegate] = React.useState(null);

    const uploadAvatarAction = (file) =>
        API.uploadFile(file)
            .then((item) => setAvatar(item["@id"]))
            .catch(() => {
                setAvatar(null);
                throw new Error("L'upload a échoué pour une raison inconnue");
            });

    const handleSubmit = (e) => {
        e.preventDefault();

        const form = {
            firstName,
            lastName,
            titre,
            establishment,
            address,
            zipcode,
            city,
            phoneNumber1,
            phoneNumber2,
            avatar,
            delegate: delegate === "" ? null : `/coordinators/${delegate}`,
        };

        for (const field in form) {
            if (
                field !== "delegate" &&
                (form[field] === "" || form[field] === null)
            ) {
                delete form[field];
            }
        }

        submit(form);
    };

    return (
        <form onSubmit={handleSubmit}>
            <InputNumber
                label="Votre numéro RPPS"
                placeholder="Saisissez votre numéro RPPS"
                name="rpps"
                disabled={true}
                onChange={() => undefined}
                defaultValue={parseInt(profile.rppsNumber)}
                required
            />
            <InputEmail
                label="Votre email"
                disabled={true}
                legend="Cette adresse nous sert à vous communiquer des informations importantes. Nous ne la communiquerons à aucun tiers."
                onChange={() => undefined}
                defaultValue={profile.email}
                required
            />
            <div className="grid grid__padding-10">
                <div className="col-xs-50">
                    <InputText
                        label="Votre nom"
                        placeholder="Saisissez votre nom"
                        name="lastName"
                        onChange={setLastName}
                        error={violations.lastName}
                        defaultValue={profile.lastName}
                        required
                    />
                </div>
                <div className="col-xs-50">
                    <InputText
                        label="Votre prénom"
                        placeholder="Saisissez votre prénom"
                        name="firstName"
                        onChange={setFirstName}
                        error={violations.firstName}
                        defaultValue={profile.firstName}
                        required
                    />
                </div>
            </div>
            <div className="grid grid__padding-10">
                <UserCan roles={["ROLE_DOCTOR"]}>
                    <div className="col-xs-40">
                        <Select
                            label="Votre titre"
                            options={[
                                { label: "Aucun", value: "" },
                                ...constant("titres").map((titre) => ({
                                    label: constant("titres", titre),
                                    value: titre,
                                })),
                            ]}
                            onChange={setTitre}
                            error={violations.titre}
                            defaultValue={profile.titre}
                        />
                    </div>
                </UserCan>
                <div
                    className={`${
                        userCan("ROLE_DOCTOR") ? "col-xs-60" : "col-xs-100"
                    }`}
                >
                    <InputText
                        label="Votre profession"
                        placeholder="Saisissez votre profession"
                        name="profession"
                        disabled={true}
                        onChange={() => undefined}
                        error={violations.profession}
                        defaultValue={profile.profession}
                        required
                    />
                </div>
            </div>
            <UserCan roles={["ROLE_DOCTOR"]}>
                <InputText
                    label="Votre spécialité"
                    placeholder="Aucune"
                    name="speciality"
                    disabled={true}
                    onChange={() => undefined}
                    error={violations.speciality}
                    defaultValue={profile.speciality}
                    required
                />
                <InputText
                    label="Votre établissement"
                    placeholder="Saisissez votre établissement"
                    name="establishment"
                    onChange={setEstablishment}
                    error={violations.establishment}
                    defaultValue={profile.establishment}
                />
            </UserCan>
            <InputText
                label="Votre adresse"
                placeholder="Saisissez votre adresse"
                name="address"
                onChange={setAddress}
                error={violations.address}
                defaultValue={profile.address}
                required
            />
            <div className="grid grid__padding-10">
                <div className="col-xs-30">
                    <InputNumber
                        label="Code postal"
                        placeholder="Saisissez votre code postal"
                        name="zip"
                        onChange={setZipcode}
                        error={violations.zipcode}
                        defaultValue={parseInt(profile.zipcode)}
                        required
                    />
                </div>
                <div className="col-xs-70">
                    <InputText
                        label="Votre ville"
                        placeholder="Saisissez votre ville"
                        name="city"
                        onChange={setCity}
                        error={violations.city}
                        defaultValue={profile.city}
                        required
                    />
                </div>
            </div>
            <div className="grid grid__padding-10">
                <div
                    className={`${
                        userCan(["ROLE_DOCTOR", "ROLE_PHARMACIST"])
                            ? "col-xs-50"
                            : "col-xs-100"
                    }`}
                >
                    <InputTel
                        label="Votre téléphone"
                        placeholder="Saisissez votre téléphone"
                        onChange={setPhoneNumber1}
                        error={violations.phoneNumber1}
                        defaultValue={profile.phoneNumber1}
                        required
                    />
                </div>
                <UserCan roles={["ROLE_DOCTOR", "ROLE_PHARMACIST"]}>
                    <div className="col-xs-50">
                        <InputTel
                            label="Votre téléphone 2"
                            placeholder="Saisissez votre téléphone"
                            onChange={setPhoneNumber2}
                            error={violations.phoneNumber2}
                            defaultValue={profile.phoneNumber2}
                        />
                    </div>
                </UserCan>
            </div>
            <InputImage
                label="Votre photo de profil"
                text="Télécharger"
                uploadAction={uploadAvatarAction}
                error={violations.avatar}
                defaultFilename={profile.avatar?.filename}
            />
            <UserCan roles={["ROLE_DOCTOR"]}>
                <div style={{ maxWidth: "400px" }}>
                    <DelegateSelector
                        coordinators={coordinators}
                        currentCoordinatorId={
                            profile.delegate ? profile.delegate.id : ""
                        }
                        onChange={setDelegate}
                    />
                </div>
            </UserCan>
            <div className="credentialsMain__validate">
                <Button
                    type="submit"
                    className="button__primaryButton"
                    isLoading={loading}
                >
                    Enregistrer
                </Button>
            </div>
        </form>
    );
};

ProfileForm.propTypes = {
    profile: PropTypes.shape({
        rppsNumber: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        titre: PropTypes.string,
        profession: PropTypes.string.isRequired,
        speciality: PropTypes.string,
        establishment: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        zipcode: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        phoneNumber1: PropTypes.string,
        phoneNumber2: PropTypes.string,
        avatar: PropTypes.shape({
            filename: PropTypes.string.isRequired,
        }),
        delegate: PropTypes.object,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    violations: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    coordinators: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({
    profile: { profileEditLoading: loading, profileEditError },
}) => ({
    loading,
    violations: getViolations(profileEditError),
});

export default connect(mapStateToProps)(ProfileForm);
