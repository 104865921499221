import React from "react";

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import API from "./API";

export const NavigationProvider = ({ children }) => {
    const navigate = useNavigate();
    API.setNavigateFunction(navigate);
    return <>{children}</>;
};

NavigationProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
