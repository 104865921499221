import React from "react";
import PropTypes from 'prop-types';

import { connect } from "react-redux";

import { PageTitle } from "@rdcs/dap-front-library";

import AppLayout from "../../components/Layout/AppLayout";

const BaseScreen = ({ user, title, content }) => (
    <PageTitle title={`Timkl - ${title}`}>
        {user ? (
            <AppLayout navActive={null}>
                <h2>
                    {content}
                </h2>
            </AppLayout>
        ) : (
            <div>{`${content} anonymous`}</div>
        )}
    </PageTitle>
);

BaseScreen.propTypes = {
    user: PropTypes.object,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(BaseScreen);
