import * as types from "./types";
import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

export const tryFetchCoordinators = (auth) => (dispatch) => {
    dispatch({ type: types.COORDINATORS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/coordinators" : "/dap-hcp-patient/api/v1/customers/admins/coordinators",
        queryParams: { pagination: false },
        token: auth.access_token,
    })
        .then((response) => dispatch({ type: types.COORDINATORS_FETCH_SUCCESS, data: response["hydra:member"] }))
        .catch((error) => dispatch({ type: types.COORDINATORS_FETCH_FAILURE, data: error }));
};
