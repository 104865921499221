import * as types from "../actions/types";

const initialState = {
    list: [],
    listLoaded: 0,
    listLoading: false,
    datas: [],
    loaded: 0,

    fetchLoading: false,
    createLoading: false,
    editLoading: false,
    deleteLoading: false,

    createError: false,
    editError: false
};

function thematic(state = initialState, action) {
    switch (action.type) {
        // FETCH
        case types.THEMATICS_FETCH_REQUESTED: {
            return {
                ...state,
                datas: [],
                loaded: 0,
                fetchLoading: true,
            };
        }
        case types.THEMATICS_FETCH_SUCCESS: {
            return {
                ...state,
                datas: action.data,
                loaded: Date.now(),
                fetchLoading: false,
            };
        }
        case types.THEMATICS_FETCH_FAILURE: {
            return {
                ...state,
                fetchLoading: false
            };
        }

        // CREATE
        case types.THEMATICS_CREATE_REQUESTED: {
            return {
                ...state,
                createLoading: true,
                createError: false
            };
        }
        case types.THEMATICS_CREATE_SUCCESS: {
            return {
                ...state,
                datas: [ ...state.datas, action.data ],
                createLoading: false,
                createError: false
            };
        }
        case types.THEMATICS_CREATE_FAILURE: {
            return {
                ...state,
                createLoading: false,
                createError: action.data
            };
        }

        // EDIT
        case types.THEMATICS_EDIT_REQUESTED: {
            return {
                ...state,
                editLoading: true,
                editError: false,
            };
        }
        case types.THEMATICS_EDIT_SUCCESS: {
            return {
                ...state,
                datas: state.datas.map((data) => data.id === action.data.id ? action.data : data),
                editLoading: false,
                editError: false
            };
        }
        case types.THEMATICS_EDIT_FAILURE: {
            return {
                ...state,
                editLoading: false,
                editError: action.data

            };
        }

        // DELETE
        case types.THEMATICS_DELETE_REQUESTED: {
            return {
                ...state,
                deleteLoading: true
            };
        }
        case types.THEMATICS_DELETE_SUCCESS: {
            return {
                ...state,
                datas: state.datas.filter(({ id }) => id !== action.data),
                deleteLoading: false
            };
        }
        case types.THEMATICS_DELETE_FAILURE: {
            return {
                ...state,
                deleteLoading: false
            };
        }
        
        // DEFAULT
        default:
            return state;
    }
}

export default thematic;