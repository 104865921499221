import * as types from "./types";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from "../store";

export const tryFetchDiagnosisRecos =
    (diagnosis, active = 1) =>
    (dispatch) => {
        const {
            auth: { access_token },
            diagnosis: { recos, recosDiagnosisId, recosLoaded },
        } = store.getState();

        if (diagnosis === null) {
            dispatch({ type: types.DIAGNOSIS_RECOS_FETCH_FAILURE });
            return Promise.resolve([]);
        }

        if (recosLoaded && recosDiagnosisId === diagnosis["@id"]) {
            return Promise.resolve(recos);
        }
        dispatch({ type: types.DIAGNOSIS_RECOS_FETCH_REQUESTED });
        return API.request({
            path: isLocalAPI ? "/diagnostic_recommendations" : "/dap-hcp-patient/api/v1/clinical-data/diagnostics-recommendations",
            queryParams: {
                diagnostic: diagnosis["@id"],
                ...(active === null ? {} : { active }),
            },
            token: access_token,
        })
            .then((response) =>
                dispatch({
                    type: types.DIAGNOSIS_RECOS_FETCH_SUCCESS,
                    data: response["hydra:member"],
                    diagnosisId: diagnosis["@id"],
                })
            )
            .catch(() => dispatch({ type: types.DIAGNOSIS_RECOS_FETCH_FAILURE }));
    };

export const tryCreateDiagnosis = (data) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.DIAGNOSIS_CREATE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/diagnostics" : "/dap-hcp-patient/api/v1/clinical-data/diagnostics",
        method: "POST",
        token: access_token,
        data: data,
    })
        .then((response) => {
            dispatch({ type: types.DIAGNOSIS_CREATE_SUCCESS, data: response });
            return response;
        })
        .catch((error) => {
            dispatch({ type: types.DIAGNOSIS_CREATE_FAILURE, data: error });
            throw error;
        });
};

export const tryEditDiagnosis = (diagnosisId, form) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.DIAGNOSIS_EDIT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/diagnostics/{id}" : "/dap-hcp-patient/api/v1/clinical-data/diagnostics/{id}",
        uriParams: {
            id: diagnosisId,
        },
        method: "PUT",
        token: access_token,
        data: form,
    })
        .then((response) => {
            dispatch({ type: types.DIAGNOSIS_EDIT_SUCCESS, data: response });
            return response;
        })
        .catch((error) => {
            dispatch({ type: types.DIAGNOSIS_EDIT_FAILURE, data: error });
            throw error;
        });
};

export const tryFetchLastDiagnosis = (patientId) => (dispatch) => {
    const {
        auth: { access_token },
        diagnosis: { patientLastDiagnosis, patientLastDiagnosisPatientId, patientLastDiagnosisLoaded },
    } = store.getState();

    if (patientLastDiagnosisLoaded && patientLastDiagnosisPatientId === patientId) {
        return Promise.resolve(patientLastDiagnosis);
    }
    dispatch({ type: types.PATIENT_LAST_DIAGNOSIS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/diagnostics" : "/dap-hcp-patient/api/v1/clinical-data/diagnostics",
        queryParams: {
            itemsPerPage: 1,
            "order[createdAt]": "desc",
            patient: patientId,
        },
        method: "GET",
        token: access_token,
    })
        .then((response) => {
            const data = response["hydra:member"];
            const patientLastDiagnostic = data?.[0] || null;

            dispatch({
                type: types.PATIENT_LAST_DIAGNOSIS_SUCCESS,
                data: patientLastDiagnostic,
                patientId,
            });
            return patientLastDiagnostic;
        })
        .catch((error) =>
            dispatch({
                type: types.PATIENT_LAST_DIAGNOSIS_FAILURE,
                data: error,
            })
        );
};

// export const tryFetchUncompletedDiagnosis = patientId => dispatch => {
//     const {
//         auth: {
//             access_token,
//         },
//         diagnosis: {
//             patientLastDiagnosis,
//             patientLastDiagnosisPatientId,
//             patientLastDiagnosisLoaded
//         }
//     } = store.getState();

//     if (patientLastDiagnosisLoaded && patientLastDiagnosisPatientId === patientId) {
//         return Promise.resolve(patientLastDiagnosis);
//     }

//     dispatch({ type: types.PATIENT_UNCOMPLETED_DIAGNOSIS_REQUESTED });
//     return API.request({
//         path: isLocalAPI ? "/diagnostics" : "/dap-hcp-patient/api/v1/clinical-data/diagnostics",
//         queryParams: {
//             itemsPerPage: 1,
//             completed: 0,
//             "order[createdAt]": "desc",
//             patient: patientId
//         },
//         method: "GET",
//         token: access_token
//     })
//     .then(response =>
//         dispatch({
//             type: types.PATIENT_UNCOMPLETED_DIAGNOSIS_SUCCESS,
//             data: response["hydra:member"],
//             patientId
//         })
//     )
//     .catch(error =>
//         dispatch({
//             type: types.PATIENT_UNCOMPLETED_DIAGNOSIS_FAILURE,
//             data: error
//         })
//     );
// };

export const tryFetchCheckUpQuestions = () => (dispatch) => {
    const {
        auth: { access_token },
        diagnosis: { checkUpQuestions, checkUpQuestionsLoaded },
    } = store.getState();

    if (checkUpQuestionsLoaded) {
        return Promise.resolve(checkUpQuestions);
    }

    dispatch({ type: types.DIAGNOSIS_CHECKUP_QUESTIONS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/check_up_questions" : "/dap-hcp-patient/api/v1/customers/patients/closed-questions",
        method: "GET",
        token: access_token,
    })
        .then((response) => {
            dispatch({
                type: types.DIAGNOSIS_CHECKUP_QUESTIONS_SUCCESS,
                data: response["hydra:member"],
            });
            return response["hydra:member"];
        })
        .catch((error) =>
            dispatch({
                type: types.DIAGNOSIS_CHECKUP_QUESTIONS_FAILURE,
                data: error,
            })
        );
};

export const tryFetchConsultationQuestions = () => (dispatch) => {
    const {
        auth: { access_token },
        diagnosis: { consultationQuestions, consultationQuestionsLoaded },
    } = store.getState();

    if (consultationQuestionsLoaded) {
        return Promise.resolve(consultationQuestions);
    }

    dispatch({ type: types.DIAGNOSIS_CONSULTATION_QUESTIONS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/consultation_questions" : "/dap-hcp-patient/api/v1/customers/patients/consultation-questions",
        method: "GET",
        token: access_token,
    })
        .then((response) => {
            dispatch({
                type: types.DIAGNOSIS_CONSULTATION_QUESTIONS_SUCCESS,
                data: response["hydra:member"],
            });
            return response["hydra:member"];
        })
        .catch((error) =>
            dispatch({
                type: types.DIAGNOSIS_CONSULTATION_QUESTIONS_FAILURE,
                data: error,
            })
        );
};

export const tryFetchConsultationAnswers = (diagnosticId) => (dispatch) => {
    const {
        auth: { access_token },
        diagnosis: { consultationAnswers, consultationAnswersLoaded, consultationAnswersDiagnosticId },
    } = store.getState();

    if (consultationAnswersLoaded && consultationAnswersDiagnosticId === diagnosticId) {
        return Promise.resolve(consultationAnswers);
    }

    dispatch({ type: types.DIAGNOSIS_CONSULTATION_ANSWERS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/consultation_answers" : "/dap-hcp-patient/api/v1/customers/patients/consultation-answers",
        queryParams: {
            pagination: false,
            "diagnostic.id": diagnosticId,
        },
        method: "GET",
        token: access_token,
    })
        .then((response) =>
            dispatch({
                type: types.DIAGNOSIS_CONSULTATION_ANSWERS_SUCCESS,
                data: response["hydra:member"],
                diagnosticId,
            })
        )
        .catch((error) =>
            dispatch({
                type: types.DIAGNOSIS_CONSULTATION_ANSWERS_FAILURE,
                data: error,
            })
        );
};

export const trySetDiagnosisRecommendationAvailability = (id, val) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.DIAGNOSIS_RECO_AVAILABILITY_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/diagnostic_recommendations/{id}" : "/dap-hcp-patient/api/v1/clinical-data/diagnostics-recommendations/{id}",
        uriParams: {
            id,
        },
        method: "PUT",
        token: access_token,
        data: {
            active: val,
        },
    })
        .then((response) =>
            dispatch({
                type: types.DIAGNOSIS_RECO_AVAILABILITY_SUCCESS,
                data: response,
            })
        )
        .catch((error) =>
            dispatch({
                type: types.DIAGNOSIS_RECO_AVAILABILITY_FAILURE,
                data: error,
            })
        );
};
