import React from "react";
import PropTypes from "prop-types";

import { InputDateTime, InputNumber, InputRadio, InputText, InputTel, InputEmail, InputCheckbox, Button, Form } from "@rdcs/dap-front-library";
import moment from "moment";

const PatientsAddForm = ({ patient, gigyaProfile, violations, loading, defaultSsn, submit }) => {
    const defaultSex = React.useMemo(() => {
        if (gigyaProfile?.gender) {
            switch (gigyaProfile.gender) {
                case "m":
                    return "male";
                case "f":
                    return "female";
                default:
                    return null;
            }
        } else {
            const firstNumberSsn = defaultSsn.toString()[0];

            switch (firstNumberSsn) {
                case "1":
                    return "male";
                case "2":
                    return "female";
                default:
                    return null;
            }
        }
    }, []);
    const defaultDateOfBirth = React.useMemo(
        () =>
            ((gigyaProfile?.birthDay &&
                gigyaProfile.birthMonth &&
                gigyaProfile.birthYear &&
                moment()
                    .date(gigyaProfile.birthDay)
                    .month(gigyaProfile.birthMonth - 1)
                    .year(gigyaProfile.birthYear)
                    .toDate()) ||
                (patient?.birthDate && moment(patient?.birthDate).toDate())) ??
            null,
        []
    );
    const disabledSsn = React.useMemo(() => Boolean(defaultSsn), [defaultSsn]);
    const ssnDefaultValue = React.useMemo(() => (disabledSsn ? parseInt(defaultSsn) : undefined), []);

    const [sex, setSex] = React.useState(defaultSex);
    const [diabetesType, setDiabetesType] = React.useState(null);
    const [emailNotifications, setEmailNotifications] = React.useState(false);
    const [smsNotifications, setSmsNotifications] = React.useState(false);

    const handleSubmit = (form) => {
        submit({
            ...form,
            alternativePhoneNumber: form.alternativePhoneNumber || null,
            emailNotifications,
            smsNotifications,
        });
    };

    const sexOptions = React.useMemo(
        () => [
            { label: "Homme", value: "male" },
            { label: "Femme", value: "female" },
        ],
        []
    );
    const diabetesTypeOptions = React.useMemo(
        () => [
            { label: "DT1", value: "DIABETES_TYPE1" },
            { label: "DT2", value: "DIABETES_TYPE2" },
            { label: "Gestationnel", value: "DIABETES_GESTATIONAL" },
        ],
        []
    );

    return (
        <Form onSubmit={handleSubmit} className="card">
            <div className="grid grid__padding-10">
                <div className="col-xs-50">
                    <InputRadio
                        label="Sexe du patient"
                        options={sexOptions}
                        defaultValue={defaultSex}
                        error={violations.sex}
                        onChange={setSex}
                        required
                        name="sex"
                    />
                </div>
                <div className="col-xs-50">
                    <InputRadio
                        label="Type de diabète"
                        options={diabetesTypeOptions}
                        defaultValue={diabetesType}
                        error={violations.diabetesType}
                        onChange={setDiabetesType}
                        required
                        name="diabetesType"
                    />
                </div>
                <div className="col-xs-50">
                    <InputText
                        label="Nom du patient"
                        placeholder="Saisissez le nom du patient"
                        name="lastName"
                        defaultValue={gigyaProfile?.lastName || patient?.lastName || ""}
                        error={violations.lastName}
                        required
                    />
                </div>
                <div className="col-xs-50">
                    <InputText
                        label="Prénom du patient"
                        placeholder="Saisissez le prénom du patient"
                        name="firstName"
                        defaultValue={gigyaProfile?.firstName || patient?.firstName || ""}
                        error={violations.firstName}
                        required
                    />
                </div>
            </div>
            <div className="grid grid__padding-10">
                <div className="col-xs-50">
                    <InputNumber
                        label="Numéro de sécurité sociale du patient"
                        placeholder="Saisissez le numéro de 13 chiffres"
                        name="ssn"
                        length={13}
                        defaultValue={ssnDefaultValue}
                        disabled={disabledSsn}
                        error={violations.ssn}
                        required
                    />
                </div>
                <div className="col-xs-50">
                    <InputDateTime
                        label="Date de naissance du patient"
                        defaultValue={defaultDateOfBirth}
                        name="dateOfBirth"
                        error={violations.dateOfBirth}
                        maxDate={new Date()}
                        placeholder="jj/mm/aaaa"
                        required
                    />
                </div>
            </div>
            <InputEmail
                label="Email du patient"
                legend="Veuillez vous assurer de l'exactitude de l'adresse mail du patient"
                placeholder="Saisissez l'email du patient"
                defaultValue={gigyaProfile?.email || patient?.email || ""}
                disabled={Boolean(gigyaProfile?.email)}
                name="email"
                error={violations.email}
                required
            />
            <InputTel label="Numéro de téléphone du patient" name="phoneNumber" error={violations.phoneNumber} required defaultValue={patient?.phoneNumber} />
            <InputTel label="Numéro de téléphone alternatif (famille/proche)" name="alternativePhoneNumber" error={violations.alternativePhoneNumber} />
            <InputCheckbox
                label="Recevoir les notifications SMS (modifiable plus tard)"
                onChange={setSmsNotifications}
                error={violations.smsNotifications}
                name="smsNotifications"
            />
            <InputCheckbox
                label="Recevoir les notifications par email (modifiable plus tard)"
                onChange={setEmailNotifications}
                error={violations.emailNotifications}
                name="emailNotifications"
            />
            <Button className="button__primaryButton" isLoading={loading}>
                Enregistrer et lancer la consultation
            </Button>
        </Form>
    );
};

PatientsAddForm.propTypes = {
    patient: PropTypes.object.isRequired,
    gigyaProfile: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        gender: PropTypes.string,
        birthDay: PropTypes.number,
        birthMonth: PropTypes.number,
        birthYear: PropTypes.number,
    }),
    violations: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    defaultSsn: PropTypes.string.isRequired,
    submit: PropTypes.func.isRequired,
};

export default PatientsAddForm;
