/* AUTH */
export const GIGYA_RETRIEVE_TOKEN_FROM_CODE_REQUESTED = "GIGYA_RETRIEVE_TOKEN_FROM_CODE_REQUESTED";
export const GIGYA_RETRIEVE_TOKEN_FROM_CODE_SUCCESS = "GIGYA_RETRIEVE_TOKEN_FROM_CODE_SUCCESS";
export const GIGYA_RETRIEVE_TOKEN_FROM_CODE_FAILURE = "GIGYA_RETRIEVE_TOKEN_FROM_CODE_FAILURE";

export const USERINFO_REQUESTED = "USERINFO_REQUESTED";
export const USERINFO_SUCCESS = "USERINFO_SUCCESS";
export const USERINFO_FAILURE = "USERINFO_FAILURE";

export const USERINFO_UPDATE = "USERINFO_UPDATE";

export const GIGYA_REFRESH_TOKEN_REQUESTED = "GIGYA_REFRESH_TOKEN_REQUESTED";
export const GIGYA_REFRESH_TOKEN_SUCCESS = "GIGYA_REFRESH_TOKEN_SUCCESS";
export const GIGYA_REFRESH_TOKEN_FAILURE = "GIGYA_REFRESH_TOKEN_FAILURE";

export const GIGYA_GET_ACCOUNT_INFO_REQUESTED = "GIGYA_GET_ACCOUNT_INFO_REQUESTED";
export const GIGYA_GET_ACCOUNT_INFO_SUCCESS = "GIGYA_GET_ACCOUNT_INFO_SUCCESS";
export const GIGYA_GET_ACCOUNT_INFO_FAILURE = "GIGYA_GET_ACCOUNT_INFO_FAILURE";

export const GIGYA_SEARCH_BY_SSN_REQUESTED = "GIGYA_SEARCH_BY_SSN_REQUESTED";
export const GIGYA_SEARCH_BY_SSN_SUCCESS = "GIGYA_SEARCH_BY_SSN_SUCCESS";
export const GIGYA_SEARCH_BY_SSN_FAILURE = "GIGYA_SEARCH_BY_SSN_FAILURE";

export const LOGOUT = "LOGOUT";

/* PATIENTS */
export const SEARCH_PATIENT_REQUESTED = "SEARCH_PATIENT_REQUESTED";
export const SEARCH_PATIENT_SUCCESS = "SEARCH_PATIENT_SUCCESS";
export const SEARCH_PATIENT_FAILURE = "SEARCH_PATIENT_FAILURE";

export const MEDICAL_PATIENT_REQUESTED = "MEDICAL_PATIENT_REQUESTED";
export const MEDICAL_PATIENT_SUCCESS = "MEDICAL_PATIENT_SUCCESS";
export const MEDICAL_PATIENT_FAILURE = "MEDICAL_PATIENT_FAILURE";

export const MEDICAL_PATIENTS_REQUESTED = "MEDICAL_PATIENTS_REQUESTED";
export const MEDICAL_PATIENTS_SUCCESS = "MEDICAL_PATIENTS_SUCCESS";
export const MEDICAL_PATIENTS_FAILURE = "MEDICAL_PATIENTS_FAILURE";

export const PATIENTS_CREATE_REQUESTED = "PATIENTS_CREATE_REQUESTED";
export const PATIENTS_CREATE_SUCCESS = "PATIENTS_CREATE_SUCCESS";
export const PATIENTS_CREATE_FAILURE = "PATIENTS_CREATE_FAILURE";

export const PATIENTS_EDIT_REQUESTED = "PATIENTS_EDIT_REQUESTED";
export const PATIENTS_EDIT_SUCCESS = "PATIENTS_EDIT_SUCCESS";
export const PATIENTS_EDIT_FAILURE = "PATIENTS_EDIT_FAILURE";

export const MEDICAL_PATIENT_CREATE_REQUESTED = "MEDICAL_PATIENT_CREATE_REQUESTED";
export const MEDICAL_PATIENT_CREATE_SUCCESS = "MEDICAL_PATIENT_CREATE_SUCCESS";
export const MEDICAL_PATIENT_CREATE_FAILURE = "MEDICAL_PATIENT_CREATE_FAILURE";

export const MEDICAL_PATIENT_DELETE_REQUESTED = "MEDICAL_PATIENT_DELETE_REQUESTED";
export const MEDICAL_PATIENT_DELETE_SUCCESS = "MEDICAL_PATIENT_DELETE_SUCCESS";
export const MEDICAL_PATIENT_DELETE_FAILURE = "MEDICAL_PATIENT_DELETE_FAILURE";

export const SINGLE_PATIENT_REQUESTED = "SINGLE_PATIENT_REQUESTED";
export const SINGLE_PATIENT_SUCCESS = "SINGLE_PATIENT_SUCCESS";
export const SINGLE_PATIENT_FAILURE = "SINGLE_PATIENT_FAILURE";

export const CREATE_PATIENT_APPOINTMENT_REQUESTED = "CREATE_PATIENT_APPOINTMENT_REQUESTED";
export const CREATE_PATIENT_APPOINTMENT_SUCCESS = "CREATE_PATIENT_APPOINTMENT_SUCCESS";
export const CREATE_PATIENT_APPOINTMENT_FAILURE = "CREATE_PATIENT_APPOINTMENT_FAILURE";

export const DELETE_PATIENT_APPOINTMENT_REQUESTED = "DELETE_PATIENT_APPOINTMENT_REQUESTED";
export const DELETE_PATIENT_APPOINTMENT_SUCCESS = "DELETE_PATIENT_APPOINTMENT_SUCCESS";
export const DELETE_PATIENT_APPOINTMENT_FAILURE = "DELETE_PATIENT_APPOINTMENT_FAILURE";

export const EDIT_PATIENT_APPOINTMENT_REQUESTED = "EDIT_PATIENT_APPOINTMENT_REQUESTED";
export const EDIT_PATIENT_APPOINTMENT_SUCCESS = "EDIT_PATIENT_APPOINTMENT_SUCCESS";
export const EDIT_PATIENT_APPOINTMENT_FAILURE = "EDIT_PATIENT_APPOINTMENT_FAILURE";

export const FETCH_PATIENT_APPOINTMENTS_REQUESTED = "FETCH_PATIENT_APPOINTMENTS_REQUESTED";
export const FETCH_PATIENT_APPOINTMENTS_SUCCESS = "FETCH_PATIENT_APPOINTMENTS_SUCCESS";
export const FETCH_PATIENT_APPOINTMENTS_FAILURE = "FETCH_PATIENT_APPOINTMENTS_FAILURE";

export const FETCH_PATIENT_TASKS_REQUESTED = "FETCH_PATIENT_TASKS_REQUESTED";
export const FETCH_PATIENT_TASKS_SUCCESS = "FETCH_PATIENT_TASKS_SUCCESS";
export const FETCH_PATIENT_TASKS_FAILURE = "FETCH_PATIENT_TASKS_FAILURE";

export const CREATE_PATIENT_TASK_REQUESTED = "CREATE_PATIENT_TASK_REQUESTED";
export const CREATE_PATIENT_TASK_SUCCESS = "CREATE_PATIENT_TASK_SUCCESS";
export const CREATE_PATIENT_TASK_FAILURE = "CREATE_PATIENT_TASK_FAILURE";

export const EDIT_PATIENT_TASK_REQUESTED = "EDIT_PATIENT_TASK_REQUESTED";
export const EDIT_PATIENT_TASK_SUCCESS = "EDIT_PATIENT_TASK_SUCCESS";
export const EDIT_PATIENT_TASK_FAILURE = "EDIT_PATIENT_TASK_FAILURE";

export const ADD_PRACTITIONER_REQUESTED = "ADD_PRACTITIONER_REQUESTED";
export const ADD_PRACTITIONER_SUCCESS = "ADD_PRACTITIONER_SUCCESS";
export const ADD_PRACTITIONER_FAILURE = "ADD_PRACTITIONER_FAILURE";

export const FETCH_TASK_LABELS_REQUESTED = "FETCH_TASK_LABELS_REQUESTED";
export const FETCH_TASK_LABELS_SUCCESS = "FETCH_TASK_LABELS_SUCCESS";
export const FETCH_TASK_LABELS_FAILURE = "FETCH_TASK_LABELS_FAILURE";

/* RECOMMANDATIONS */
export const RECOMMANDATIONS_FETCH_REQUESTED = "RECOMMANDATIONS_FETCH_REQUESTED";
export const RECOMMANDATIONS_FETCH_SUCCESS = "RECOMMANDATIONS_FETCH_SUCCESS";
export const RECOMMANDATIONS_FETCH_FAILURE = "RECOMMANDATIONS_FETCH_FAILURE";

/* TREATMENTS */
export const TREATMENTS_FETCH_REQUESTED = "TREATMENTS_FETCH_REQUESTED";
export const TREATMENTS_FETCH_SUCCESS = "TREATMENTS_FETCH_SUCCESS";
export const TREATMENTS_FETCH_FAILURE = "TREATMENTS_FETCH_FAILURE";

/* COMPLICATIONS */
export const COMPLICATIONS_FETCH_REQUESTED = "COMPLICATIONS_FETCH_REQUESTED";
export const COMPLICATIONS_FETCH_SUCCESS = "COMPLICATIONS_FETCH_SUCCESS";
export const COMPLICATIONS_FETCH_FAILURE = "COMPLICATIONS_FETCH_FAILURE";

/* DIAGNOSIS */
export const DIAGNOSIS_CREATE_REQUESTED = "DIAGNOSIS_CREATE_REQUESTED";
export const DIAGNOSIS_CREATE_SUCCESS = "DIAGNOSIS_CREATE_SUCCESS";
export const DIAGNOSIS_CREATE_FAILURE = "DIAGNOSIS_CREATE_FAILURE";

export const DIAGNOSIS_EDIT_REQUESTED = "DIAGNOSIS_EDIT_REQUESTED";
export const DIAGNOSIS_EDIT_SUCCESS = "DIAGNOSIS_EDIT_SUCCESS";
export const DIAGNOSIS_EDIT_FAILURE = "DIAGNOSIS_EDIT_FAILURE";

export const PATIENT_LAST_DIAGNOSIS_REQUESTED = "PATIENT_LAST_DIAGNOSIS_REQUESTED";
export const PATIENT_LAST_DIAGNOSIS_SUCCESS = "PATIENT_LAST_DIAGNOSIS_SUCCESS";
export const PATIENT_LAST_DIAGNOSIS_FAILURE = "PATIENT_LAST_DIAGNOSIS_FAILURE";

export const PATIENT_UNCOMPLETED_DIAGNOSIS_REQUESTED = "PATIENT_UNCOMPLETED_DIAGNOSIS_REQUESTED";
export const PATIENT_UNCOMPLETED_DIAGNOSIS_SUCCESS = "PATIENT_UNCOMPLETED_DIAGNOSIS_SUCCESS";
export const PATIENT_UNCOMPLETED_DIAGNOSIS_FAILURE = "PATIENT_UNCOMPLETED_DIAGNOSIS_FAILURE";

export const DIAGNOSIS_CHECKUP_QUESTIONS_REQUESTED = "DIAGNOSIS_CHECKUP_QUESTIONS_REQUESTED";
export const DIAGNOSIS_CHECKUP_QUESTIONS_SUCCESS = "DIAGNOSIS_CHECKUP_QUESTIONS_SUCCESS";
export const DIAGNOSIS_CHECKUP_QUESTIONS_FAILURE = "DIAGNOSIS_CHECKUP_QUESTIONS_FAILURE";

export const DIAGNOSIS_CONSULTATION_QUESTIONS_REQUESTED = "DIAGNOSIS_CONSULTATION_QUESTIONS_REQUESTED";
export const DIAGNOSIS_CONSULTATION_QUESTIONS_SUCCESS = "DIAGNOSIS_CONSULTATION_QUESTIONS_SUCCESS";
export const DIAGNOSIS_CONSULTATION_QUESTIONS_FAILURE = "DIAGNOSIS_CONSULTATION_QUESTIONS_FAILURE";

export const DIAGNOSIS_CONSULTATION_ANSWERS_REQUESTED = "DIAGNOSIS_CONSULTATION_ANSWERS_REQUESTED";
export const DIAGNOSIS_CONSULTATION_ANSWERS_SUCCESS = "DIAGNOSIS_CONSULTATION_ANSWERS_SUCCESS";
export const DIAGNOSIS_CONSULTATION_ANSWERS_FAILURE = "DIAGNOSIS_CONSULTATION_ANSWERS_FAILURE";

export const DIAGNOSIS_RECO_AVAILABILITY_REQUESTED = "DIAGNOSIS_RECO_AVAILABILITY_REQUESTED";
export const DIAGNOSIS_RECO_AVAILABILITY_SUCCESS = "DIAGNOSIS_RECO_AVAILABILITY_SUCCESS";
export const DIAGNOSIS_RECO_AVAILABILITY_FAILURE = "DIAGNOSIS_RECO_AVAILABILITY_FAILURE";

/* DIAGNOSIS RECOMMANDATIONS */
export const DIAGNOSIS_RECOS_FETCH_REQUESTED = "DIAGNOSIS_RECOS_FETCH_REQUESTED";
export const DIAGNOSIS_RECOS_FETCH_SUCCESS = "DIAGNOSIS_RECOS_FETCH_SUCCESS";
export const DIAGNOSIS_RECOS_FETCH_FAILURE = "DIAGNOSIS_RECOS_FETCH_FAILURE";

/* PROFILE */
export const PROFILE_FETCH_CONSENTS_REQUESTED = "PROFILE_FETCH_CONSENTS_REQUESTED";
export const PROFILE_FETCH_CONSENTS_SUCCESS = "PROFILE_FETCH_CONSENTS_SUCCESS";
export const PROFILE_FETCH_CONSENTS_FAILURE = "PROFILE_FETCH_CONSENTS_FAILURE";

export const PROFILE_FETCH_REQUESTED = "PROFILE_FETCH_REQUESTED";
export const PROFILE_FETCH_SUCCESS = "PROFILE_FETCH_SUCCESS";
export const PROFILE_FETCH_FAILURE = "PROFILE_FETCH_FAILURE";

export const PROFILE_CREATE_REQUESTED = "PROFILE_CREATE_REQUESTED";
export const PROFILE_CREATE_SUCCESS = "PROFILE_CREATE_SUCCESS";
export const PROFILE_CREATE_FAILURE = "PROFILE_CREATE_FAILURE";

export const PROFILE_FINALIZE_REQUESTED = "PROFILE_FINALIZE_REQUESTED";
export const PROFILE_FINALIZE_SUCCESS = "PROFILE_FINALIZE_SUCCESS";
export const PROFILE_FINALIZE_FAILURE = "PROFILE_FINALIZE_FAILURE";

export const PROFILE_EDIT_REQUESTED = "PROFILE_EDIT_REQUESTED";
export const PROFILE_EDIT_SUCCESS = "PROFILE_EDIT_SUCCESS";
export const PROFILE_EDIT_FAILURE = "PROFILE_EDIT_FAILURE";

/* NOTES */
export const NOTES_FETCH_REQUESTED = "NOTES_FETCH_REQUESTED";
export const NOTES_FETCH_SUCCESS = "NOTES_FETCH_SUCCESS";
export const NOTES_FETCH_FAILURE = "NOTES_FETCH_FAILURE";

export const NOTES_CREATE_REQUESTED = "NOTES_CREATE_REQUESTED";
export const NOTES_CREATE_SUCCESS = "NOTES_CREATE_SUCCESS";
export const NOTES_CREATE_FAILURE = "NOTES_CREATE_FAILURE";

export const EDIT_NOTE_REQUESTED = "EDIT_NOTE_REQUESTED";
export const EDIT_NOTE_SUCCESS = "EDIT_NOTE_SUCCESS";
export const EDIT_NOTE_FAILURE = "EDIT_NOTE_FAILURE";

export const NOTE_REMOVE_REQUESTED = "NOTE_REMOVE_REQUESTED";
export const NOTE_REMOVE_SUCCESS = "NOTE_REMOVE_SUCCESS";
export const NOTE_REMOVE_FAILURE = "NOTE_REMOVE_FAILURE";

/* MEASURES */
export const MEASURES_FETCH_REQUESTED = "MEASURES_FETCH_REQUESTED";
export const MEASURES_FETCH_SUCCESS = "MEASURES_FETCH_SUCCESS";
export const MEASURES_FETCH_FAILURE = "MEASURES_FETCH_FAILURE";

export const MEASURES_CREATE_REQUESTED = "MEASURES_CREATE_REQUESTED";
export const MEASURES_CREATE_SUCCESS = "MEASURES_CREATE_SUCCESS";
export const MEASURES_CREATE_FAILURE = "MEASURES_CREATE_FAILURE";

/* MEASURE TYPES */
export const MEASURE_TYPES_FETCH_REQUESTED = "MEASURE_TYPES_FETCH_REQUESTED";
export const MEASURE_TYPES_FETCH_SUCCESS = "MEASURE_TYPES_FETCH_SUCCESS";
export const MEASURE_TYPES_FETCH_FAILURE = "MEASURE_TYPES_FETCH_FAILURE";

/* HEALTH PROFESSIONALS */
export const PRACTITIONERS_FETCH_REQUESTED = "PRACTITIONERS_FETCH_REQUESTED";
export const PRACTITIONERS_FETCH_SUCCESS = "PRACTITIONERS_FETCH_SUCCESS";
export const PRACTITIONERS_FETCH_FAILURE = "PRACTITIONERS_FETCH_FAILURE";
export const PRACTITIONERS_FETCH_CLEAR = "PRACTITIONERS_FETCH_CLEAR";

/* QUESTIONS */
export const QUESTIONNAIRE_TYPES = [
    {
        type: "study",
        name: "d'étude",
        answers: ["fetch", "add", "edit"],
    },
    {
        type: "diagnostic",
        name: "",
        alias: "informations",
        format: (data) => {
            const updatedData = [];

            data.forEach((q) => {
                q.answerList = [];
                for (let i = 1; i <= 10; i++) {
                    if (q["answer" + i]) {
                        q.answerList.push(q["answer" + i]);
                    }
                }
                if (q.hasOtherChoice) {
                    q.answerList.push("Autre");
                }
                updatedData.push(q);
            });
            return updatedData;
        },
    },
    {
        type: "break-score",
        name: "de rupture",
        answers: ["fetch"],
    },
    {
        type: "break-score-no-insulin",
        name: "de rupture",
        answers: ["fetch"],
    },
    {
        type: "epices",
        name: "",
        answers: ["fetch", "add"],
    },
    {
        type: "beck",
        name: "",
        answers: ["fetch", "add"],
    },
];

export const QUESTIONNAIRES = {};

for (const QUESTIONNAIRE_TYPE of QUESTIONNAIRE_TYPES) {
    QUESTIONNAIRE_TYPE.upType = QUESTIONNAIRE_TYPE.type.toUpperCase().replace(/-/g, "_");
    QUESTIONNAIRE_TYPE.pascalName = (QUESTIONNAIRE_TYPE.alias || QUESTIONNAIRE_TYPE.type).replace(/((?:^|-)[a-z]{1})/g, (w) =>
        w.substring(w.length - 1).toUpperCase()
    );
    QUESTIONNAIRE_TYPE.camelName = QUESTIONNAIRE_TYPE.pascalName[0].toLowerCase() + QUESTIONNAIRE_TYPE.pascalName.substring(1);

    QUESTIONNAIRES[`${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_FETCH_REQUESTED`] = `${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_FETCH_REQUESTED`;
    QUESTIONNAIRES[`${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_FETCH_SUCCESS`] = `${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_FETCH_SUCCESS`;
    QUESTIONNAIRES[`${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_FETCH_FAILURE`] = `${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_FETCH_FAILURE`;

    QUESTIONNAIRES[`${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_ADD_REQUESTED`] = `${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_ADD_REQUESTED`;
    QUESTIONNAIRES[`${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_ADD_SUCCESS`] = `${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_ADD_SUCCESS`;
    QUESTIONNAIRES[`${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_ADD_FAILURE`] = `${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_ADD_FAILURE`;

    QUESTIONNAIRES[`${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_EDIT_REQUESTED`] = `${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_EDIT_REQUESTED`;
    QUESTIONNAIRES[`${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_EDIT_SUCCESS`] = `${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_EDIT_SUCCESS`;
    QUESTIONNAIRES[`${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_EDIT_FAILURE`] = `${QUESTIONNAIRE_TYPE.upType}_QUESTIONS_EDIT_FAILURE`;

    if (QUESTIONNAIRE_TYPE.answers) {
        for (let i = 0; i < QUESTIONNAIRE_TYPE.answers.length; ++i) {
            QUESTIONNAIRE_TYPE.answers[i] = QUESTIONNAIRE_TYPE.answers[i].toUpperCase();

            QUESTIONNAIRES[
                `${QUESTIONNAIRE_TYPE.upType}_ANSWERS_${QUESTIONNAIRE_TYPE.answers[i]}_REQUESTED`
            ] = `${QUESTIONNAIRE_TYPE.upType}_ANSWERS_${QUESTIONNAIRE_TYPE.answers[i]}_REQUESTED`;
            QUESTIONNAIRES[
                `${QUESTIONNAIRE_TYPE.upType}_ANSWERS_${QUESTIONNAIRE_TYPE.answers[i]}_SUCCESS`
            ] = `${QUESTIONNAIRE_TYPE.upType}_ANSWERS_${QUESTIONNAIRE_TYPE.answers[i]}_SUCCESS`;
            QUESTIONNAIRES[
                `${QUESTIONNAIRE_TYPE.upType}_ANSWERS_${QUESTIONNAIRE_TYPE.answers[i]}_FAILURE`
            ] = `${QUESTIONNAIRE_TYPE.upType}_ANSWERS_${QUESTIONNAIRE_TYPE.answers[i]}_FAILURE`;
        }
    }
}

export const ADD_POTENTIAL_PATIENT_REQUESTED = "ADD_POTENTIAL_PATIENT_REQUESTED";
export const ADD_POTENTIAL_PATIENT_SUCCESS = "ADD_POTENTIAL_PATIENT_SUCCESS";
export const ADD_POTENTIAL_PATIENT_FAILURE = "ADD_POTENTIAL_PATIENT_FAILURE";

export const POTENTIAL_PATIENT_FETCH_REQUESTED = "POTENTIAL_PATIENT_FETCH_REQUESTED";
export const POTENTIAL_PATIENT_FETCH_SUCCESS = "POTENTIAL_PATIENT_FETCH_SUCCESS";
export const POTENTIAL_PATIENT_FETCH_FAILURE = "POTENTIAL_PATIENT_FETCH_FAILURE";

export const EDIT_POTENTIAL_PATIENT_REQUESTED = "EDIT_POTENTIAL_PATIENT_REQUESTED";
export const EDIT_POTENTIAL_PATIENT_SUCCESS = "EDIT_POTENTIAL_PATIENT_SUCCESS";
export const EDIT_POTENTIAL_PATIENT_FAILURE = "EDIT_POTENTIAL_PATIENT_FAILURE";

export const DELETE_POTENTIAL_PATIENT_REQUESTED = "DELETE_POTENTIAL_PATIENTS_REQUESTED";
export const DELETE_POTENTIAL_PATIENT_SUCCESS = "DELETE_POTENTIAL_PATIENTS_SUCCESS";
export const DELETE_POTENTIAL_PATIENT_FAILURE = "DELETE_POTENTIAL_PATIENTS_FAILURE";

/* PARTNERS */
export const PARTNERS_FETCH_REQUESTED = "PARTNERS_FETCH_REQUESTED";
export const PARTNERS_FETCH_SUCCESS = "PARTNERS_FETCH_SUCCESS";
export const PARTNERS_FETCH_FAILURE = "PARTNERS_FETCH_FAILURE";

export const PARTNERS_EMAIL_SEND_REQUESTED = "PARTNERS_EMAIL_SEND_REQUESTED";
export const PARTNERS_EMAIL_SEND_SUCCESS = "PARTNERS_EMAIL_SEND_SUCCESS";
export const PARTNERS_EMAIL_SEND_FAILURE = "PARTNERS_EMAIL_SEND_FAILURE";

export const PARTNER_CHECK_UPS_FETCH_REQUESTED = "PARTNER_CHECK_UPS_FETCH_REQUESTED";
export const PARTNER_CHECK_UPS_FETCH_SUCCESS = "PARTNER_CHECK_UPS_FETCH_SUCCESS";
export const PARTNER_CHECK_UPS_FETCH_FAILURE = "PARTNER_CHECK_UPS_FETCH_FAILURE";

/* REPORTS */
export const REPORTS_FETCH_REQUESTED = "REPORTS_FETCH_REQUESTED";
export const REPORTS_FETCH_SUCCESS = "REPORTS_FETCH_SUCCESS";
export const REPORTS_FETCH_FAILURE = "REPORTS_FETCH_FAILURE";

export const REPORTS_CREATE_REQUESTED = "REPORTS_CREATE_REQUESTED";
export const REPORTS_CREATE_SUCCESS = "REPORTS_CREATE_SUCCESS";
export const REPORTS_CREATE_FAILURE = "REPORTS_CREATE_FAILURE";

export const EDIT_REPORT_REQUESTED = "EDIT_REPORT_REQUESTED";
export const EDIT_REPORT_SUCCESS = "EDIT_REPORT_SUCCESS";
export const EDIT_REPORT_FAILURE = "EDIT_REPORT_FAILURE";

export const REPORT_REMOVE_REQUESTED = "REPORT_REMOVE_REQUESTED";
export const REPORT_REMOVE_SUCCESS = "REPORT_REMOVE_SUCCESS";
export const REPORT_REMOVE_FAILURE = "REPORT_REMOVE_FAILURE";

/* POINTS OF ATTENTIONS */
export const POINTS_OF_ATTENTION_FETCH_REQUESTED = "POINTS_OF_ATTENTION_FETCH_REQUESTED";
export const POINTS_OF_ATTENTION_FETCH_SUCCESS = "POINTS_OF_ATTENTION_FETCH_SUCCESS";
export const POINTS_OF_ATTENTION_FETCH_FAILURE = "POINTS_OF_ATTENTION_FETCH_FAILURE";

/* PATIENT POINTS OF ATTENTIONS */
export const PATIENT_POINTS_OF_ATTENTION_FETCH_REQUESTED = "PATIENT_POINTS_OF_ATTENTION_FETCH_REQUESTED";
export const PATIENT_POINTS_OF_ATTENTION_FETCH_SUCCESS = "PATIENT_POINTS_OF_ATTENTION_FETCH_SUCCESS";
export const PATIENT_POINTS_OF_ATTENTION_FETCH_FAILURE = "PATIENT_POINTS_OF_ATTENTION_FETCH_FAILURE";

export const PATIENT_POINTS_OF_ATTENTION_SEE_SUCCESS = "PATIENT_POINTS_OF_ATTENTION_SEE_SUCCESS";

export const THEMATICS_FETCH_REQUESTED = "THEMATICS_FETCH_REQUESTED";
export const THEMATICS_FETCH_SUCCESS = "THEMATICS_FETCH_SUCCESS";
export const THEMATICS_FETCH_FAILURE = "THEMATICS_FETCH_FAILURE";

export const THEMATICS_CREATE_REQUESTED = "THEMATICS_CREATE_REQUESTED";
export const THEMATICS_CREATE_SUCCESS = "THEMATICS_CREATE_SUCCESS";
export const THEMATICS_CREATE_FAILURE = "THEMATICS_CREATE_FAILURE";

export const THEMATICS_EDIT_REQUESTED = "THEMATICS_EDIT_REQUESTED";
export const THEMATICS_EDIT_SUCCESS = "THEMATICS_EDIT_SUCCESS";
export const THEMATICS_EDIT_FAILURE = "THEMATICS_EDIT_FAILURE";

export const THEMATICS_DELETE_REQUESTED = "THEMATICS_DELETE_REQUESTED";
export const THEMATICS_DELETE_SUCCESS = "THEMATICS_DELETE_SUCCESS";
export const THEMATICS_DELETE_FAILURE = "THEMATICS_DELETE_FAILURE";

export const ADD_QUESTIONNAIRE_PATIENT_REQUESTED = "ADD_QUESTIONNAIRE_PATIENT_REQUESTED";
export const ADD_QUESTIONNAIRE_PATIENT_SUCCES = "ADD_QUESTIONNAIRE_PATIENT_SUCCES";
export const ADD_QUESTIONNAIRE_PATIENT_FAILLURE = "ADD_QUESTIONNAIRE_PATIENT_FAILLURE";

export const EDIT_QUESTIONNAIRE_PATIENT_REQUESTED = "EDIT_QUESTIONNAIRE_PATIENT_REQUESTED";
export const EDIT_QUESTIONNAIRE_PATIENT_SUCCES = "EDIT_QUESTIONNAIRE_PATIENT_SUCCES";
export const EDIT_QUESTIONNAIRE_PATIENT_FAILLURE = "EDIT_QUESTIONNAIRE_PATIENT_FAILLURE";

export const DELETE_QUESTIONNAIRE_PATIENT_REQUESTED = "DELETE_QUESTIONNAIRE_PATIENT_REQUESTED";

/* COORDINATORS */
export const COORDINATORS_FETCH_REQUESTED = "COORDINATORS_FETCH_REQUESTED";
export const COORDINATORS_FETCH_SUCCESS = "COORDINATORS_FETCH_SUCCESS";
export const COORDINATORS_FETCH_FAILURE = "COORDINATORS_FETCH_FAILURE";

/* EMAIL */

export const EMAIL_VALIDATION_DIAGNOSTIC_REQUESTED = "EMAIL_VALIDATION_DIAGNOSTIC_REQUESTED";
export const EMAIL_VALIDATION_DIAGNOSTIC_SUCCESS = "EMAIL_VALIDATION_DIAGNOSTIC_SUCCESS";
export const EMAIL_VALIDATION_DIAGNOSTIC_FAILURE = "EMAIL_VALIDATION_DIAGNOSTIC_FAILURE";