import ContentDropdown from './contentDropdown'
import { connect } from "react-redux";
import { Button, InputDateTime, Icon } from '@rdcs/dap-front-library';
import { tryCreateReport, tryEditReport, tryFetchReports } from '../../../actions/reports';
import React from 'react';
import moment from 'moment';

const PatientReportForm = ({
    onOpenPreviousInterview,
    tryCreateReport,
    tryEditReport,
    medicalPatient,
    report,
    canModify = true,
    edition,
    onSubmit,
    prevObjective,
    setLoading,
    reloadReport,
    previousReport
}) => {
    const [loadingBtn, setLoadingBtn] = React.useState(false)
    const [data, setData] = React.useState()
    const [allowBtn, setAllowBtn] = React.useState(false)
    const [date, setDate] = React.useState(report ? report.date : new Date());

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingBtn(true)
        const dataWithDate = { ...data, date };

        if (edition) {
            tryEditReport(report.id, dataWithDate).then(() => {
                setLoadingBtn(false)
                onSubmit();
                setLoading(true)
                reloadReport()
            })
        }
        else {
            tryCreateReport(dataWithDate).then(() => {
                setLoadingBtn(false)
                onSubmit();
            })
        }

    };

    return (
        <div className='d-flex flex-column justify-space-between' style={{ minHeight: "100vh" }}>
            <div>
                <h2 className='mx-4 my-2 timkl__color--violet-dark txtBold txtSizeMedium'>{report ? `Compte rendu d’entretien ${moment(report.date).format('DD/MM/YYYY')}` : "Ajouter un compte rendu d’entretien"}</h2>
                <div className='timkl__backgroundColor--violet-light pxy-4 d-flex justify-space-between'>
                    <div>
                        <p> Patient : {medicalPatient.patient?.firstName} {medicalPatient.patient?.lastName}</p>
                        <p className='d-flex align-items-center'> Date :
                            <InputDateTime
                                className="inputDateReport"
                                defaultValue={new Date(report ? report.date : new Date())}
                                name='date'
                                placeholder={report ? report.date : "jj/mm/aaaa"}
                                required
                                onChange={(newDate) => setDate(newDate)}
                            />
                        </p>
                    </div>
                    {!edition && previousReport && (
                        <div>
                            <Button onClick={onOpenPreviousInterview} className=" button__primaryButton d-flex align-items-center timkl__color--blue-dark2 timkl__backgroundColor--violet-dark-important">
                                <Icon name="search" size={15} className='mr-1' />
                                Consulter l'entretien du {moment(previousReport.date).format("DD/MM/YYYY")}
                            </Button>
                        </div>
                    )}
                    
                </div>
                <ContentDropdown prevObjective={prevObjective} setData={setData} data={data} setAllowBtn={setAllowBtn} actualReport={report} canModify={canModify} />
            </div>
            <div className="footer_reportContent">
                <Button className="button__primaryButton button__reportValidation mxy-2"
                    disabled={!(canModify && (edition || allowBtn))}
                    onClick={(e) => handleSubmit(e)}
                    isLoading={loadingBtn} >
                    {edition ? "modifier" : "valider"}
                </Button>
            </div>
        </div>
    )
}

const mapStateToProps = ({ patients: { medicalPatient }, reports: { list: reports } }) => ({
    medicalPatient,
    previousReport: reports?.sort((a, b) => new Date(b.date) - new Date(a.date))[0] ?? null
});

const mapDispatchToProps = { tryCreateReport, tryEditReport, tryFetchReports };

export default connect(mapStateToProps, mapDispatchToProps)(PatientReportForm);
