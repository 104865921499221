import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { PractitionerLayout, DefaultLoader } from "@rdcs/dap-front-library";

import useState from "./useState";

import Questions from "../../actions/questions";
import API from "../../utils/API";
import { Roles, getRoles } from "../../utils/TokenData";
import { isLocalAPI } from "../../utils/utils";

const { tryFetchBreakScoreNoInsulinQuestions, tryFetchBreakScoreQuestions } = Questions;

const userNav = [
    {
        title: "Profil",
        path: "/mon-profil",
        icon: "datas",
    },
    {
        title: "Se déconnecter",
        path: "/deconnexion",
        icon: "close",
    },
];

const AppLayout = ({
    auth,
    auth: {
        user: { roles },
    },
    breadcrumbs,
    children,
    navActive,
    pageClass,
    tryFetchBreakScoreNoInsulinQuestions,
    tryFetchBreakScoreQuestions,
}) => {
    const [{ loading, nav }, { load, addBreakScoreQuestionOnNav }] = useState();

    React.useEffect(() => {
        const breakScoreQuestionAllowedRoles = getRoles(Roles.ROLE_PHARMACIST, Roles.ROLE_NURSE, Roles.ROLE_DOCTOR);

        if (roles.some((role) => breakScoreQuestionAllowedRoles.includes(role))) {
            tryFetchBreakScoreNoInsulinQuestions();
            tryFetchBreakScoreQuestions()
                .then((breakScoreQuestions) => {
                    addBreakScoreQuestionOnNav(breakScoreQuestions);
                })
                .catch(load);
        } else {
            load();
        }
    }, [roles]);

    return loading ? (
        <DefaultLoader />
    ) : (
        <PractitionerLayout
            nav={nav}
            userNav={userNav}
            user={auth.user}
            pageClass={pageClass}
            navActive={navActive}
            breadcrumbs={breadcrumbs}
            roles={roles}
            searchAction={(val) => {
                let pCancelable = null;
                API.request(
                    {
                        path: isLocalAPI
                            ? "/practitioner_search"
                            : "/dap-hcp-patient/api/v1/customers/patients/practitioner-search",
                        queryParams: {
                            val,
                        },
                        token: auth.access_token,
                    },
                    (callback) => (pCancelable = callback)
                );
                return pCancelable;
            }}
        >
            {children}
            <footer className="page__footer">
                <hr />
                <Link to="/cgu" className="page__footer--link">
                    CGU
                </Link>
            </footer>
        </PractitionerLayout>
    );
};

AppLayout.propTypes = {
    auth: PropTypes.shape({
        user: PropTypes.shape({
            roles: PropTypes.arrayOf(PropTypes.string).isRequired,
        }).isRequired,
        access_token: PropTypes.string.isRequired,
    }).isRequired,
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            link: PropTypes.string,
        })
    ),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
    navActive: PropTypes.number,
    pageClass: PropTypes.string,
    tryFetchBreakScoreNoInsulinQuestions: PropTypes.func.isRequired,
    tryFetchBreakScoreQuestions: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = {
    tryFetchBreakScoreQuestions,
    tryFetchBreakScoreNoInsulinQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
