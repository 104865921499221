import React from "react";
import PropTypes from "prop-types";

import { Animated } from "react-animated-css";

import { StepButtons } from "@rdcs/dap-front-library";

import { Select, Form, InputRadio, constant, SelectTreatmentCascader, CheckUpQuestionsForm } from "@rdcs/dap-front-library";
import FreeQuestion from "./FreeQuestion";

const DiagnosisStep1 = ({ informationsQuestions, treatmentGroups, checkUpQuestions, nextStep, defaultValues, checkUpQuestionsForceEnabled }) => {
    const dateYears = React.useMemo(() => {
        const endYear = new Date().getFullYear();
        const yearsArray = [{ label: "-- Choisir --", value: "" }];

        for (let i = 0; i < 100; i++) {
            const year = (endYear - i).toString();

            yearsArray.push({ label: year, value: year });
        }
        return yearsArray;
    }, []);
    const diabeteOptions = React.useMemo(
        () =>
            constant("pathologies").map((pathology) => ({
                label: constant("pathologies", pathology),
                value: pathology,
            })),
        []
    );

    return (
        <Animated animationIn="fadeIn">
            <Form className="formJustified" onSubmit={nextStep}>
                <div className="grid grid__padding-25">
                    <div className="col-sm-100">
                        <InputRadio label="Type de diabète" options={diabeteOptions} defaultValue={defaultValues.pathology} required name="pathology" />
                    </div>
                    <div>
                        <div className="col-sm-100 col-lg-20">
                            <Select
                                name="diabetesDiscoveryYear"
                                label="Année de découverte du diabète"
                                caretIcon="calendar"
                                options={dateYears}
                                defaultValue={defaultValues.patient.diabetesDiscoveryYear}
                                required
                            />
                        </div>
                        <div className="col-sm-100 col-lg-30 pr-0">
                            <SelectTreatmentCascader
                                label="Traitement"
                                name="treatmentGroup"
                                treatmentGroups={treatmentGroups}
                                placeholder="Veuillez sélectionner un traitement"
                                defaultValue={defaultValues.treatmentGroup}
                                required
                            />
                        </div>
                    </div>
                    <div className="diagnostic__informationsQuestions">
                        {informationsQuestions.map((question, i) => (
                            <FreeQuestion
                                key={`informationQuestion${i}`}
                                name={`diagnosticAnswers${i}`}
                                question={question}
                                diagnosticAnswers={defaultValues.diagnosticAnswers}
                            />
                        ))}
                    </div>
                    <div className="clear" />
                    <div className="diagnostic__bilan">
                        <div className="form__group">
                            <p className="label mb-2">
                                {defaultValues.hasLastDiagnosis
                                    ? "Consultations obligatoires lors d'un bilan initial :"
                                    : "Dans le cadre du bilan initial, le patient a-t-il réalisé les consultations ou examens suivants ?"}
                            </p>
                            <CheckUpQuestionsForm
                                checkUpQuestions={checkUpQuestions}
                                defaultValues={defaultValues.patient.checkUpAnswers}
                                disabled={defaultValues.hasLastDiagnosis}
                                required
                                name="checkUpQuestion"
                                forceEnabled={checkUpQuestionsForceEnabled}
                            />
                        </div>
                    </div>
                    <StepButtons nextButtonClassName="text-uppercase" />
                </div>
            </Form>
        </Animated>
    );
};

DiagnosisStep1.propTypes = {
    informationsQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
    treatmentGroups: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            asgGoal: PropTypes.number,
            order: PropTypes.number.isRequired,
            active: PropTypes.bool.isRequired,
            levelOne: PropTypes.object.isRequired,
            levelTwo: PropTypes.object,
            levelThree: PropTypes.object,
        })
    ).isRequired,
    checkUpQuestions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            "@id": PropTypes.string.isRequired,
        })
    ),
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    defaultValues: PropTypes.shape({
        pathology: PropTypes.string,
        patient: PropTypes.shape({
            diabetesDiscoveryYear: PropTypes.number,
            checkUpAnswers: PropTypes.arrayOf(PropTypes.object),
        }).isRequired,
        hasLastDiagnosis: PropTypes.bool,
        treatmentGroup: PropTypes.string,
        diagnosticAnswers: PropTypes.object,
    }).isRequired,
    checkUpQuestionsForceEnabled: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default React.memo(DiagnosisStep1);
