import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    listLoading: false,
    listPatientId: -1,
    listLoaded: 0,

    created: false,
    createError: false,
    createLoading: false,

    editLoading: false,
    editError: false,

    deleteLoading: false,
    deleteError: false
};

function notes(state = initialState, action) {
    switch (action.type) {
        // FETCH
        case types.NOTES_FETCH_REQUESTED: {
            return Object.assign({}, state, {
                listLoading: true,
                listLoaded: 0,
                listPatientId: -1,
            });
        }
        case types.NOTES_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                listLoading: false,
                list: action.data,
                listPatientId: action.patientId,
                listLoaded: Date.now()
            });
        }
        case types.NOTES_FETCH_FAILURE: {
            return Object.assign({}, state, { listLoading: false, list: [] });
        }
        // CREATE
        case types.NOTES_CREATE_REQUESTED: {
            return Object.assign({}, state, {
                createLoading: true,
                created: false
            });
        }
        case types.NOTES_CREATE_SUCCESS: {
            toast.success("La note a bien été créée", { autoClose: 5000 });
            return Object.assign({}, state, {
                createLoading: false,
                createError: false,
                created: action.data,
                list: [action.data, ...state.list],
            });
        }
        case types.NOTES_CREATE_FAILURE: {
            toast.error("La note n'a pas pu être créée");
            return Object.assign({}, state, {
                createLoading: false,
                createError: action.data,
                created: false
            });
        }
        // EDIT
        case types.EDIT_NOTE_REQUESTED: {
            return Object.assign({}, state, {
                editLoading: true,
                editError: false
            });
        }
        case types.EDIT_NOTE_SUCCESS: {
            return Object.assign({}, state, {
                editLoading: false,
                list: action.data,
                listPatientId: action.patientId,
                listLoaded: Date.now()
            });
        }
        case types.EDIT_NOTE_FAILURE: {
            return Object.assign({}, state, {
                editLoading: false,
                editError: action.data
            });
        }
        // REMOVE
        case types.NOTE_REMOVE_REQUESTED: {
            return Object.assign({}, state, {
                deleteLoading: true,
                deleteError: false
            });
        }
        case types.NOTE_REMOVE_SUCCESS: {
            toast.success("La note a été supprimée");
            return Object.assign({}, state, {
                deleteLoading: false,
                list: state.list.filter(note => note.id !== action.data.id)
            });
        }
        case types.NOTE_REMOVE_FAILURE: {
            toast.error("La note n'a pas pu être supprimée");
            return Object.assign({}, state, {
                deleteLoading: false,
                deleteError: action.data
            });
        }
        // DEFAULT
        default:
            return state;
    }
}

export default notes;
