import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { InputCheckCard } from "@rdcs/dap-front-library";

const EpicesForm = ({ questions, answers, userAnswers, setUserAnswers, readOnly }) => {
    useEffect(() => {
        if (answers.length > 0) {
            const initialUserAnswers = questions.map((question) => {
                const index = answers.findIndex((answer) => answer.question === question.label);
                if (index === -1)
                    return {
                        question: question.label,
                        values: null,
                    };

                return {
                    question: question.label,
                    values: answers[index].values,
                };
            });
            setUserAnswers(initialUserAnswers);
        }
    }, [answers, setUserAnswers]);

    const handleAnswerChange = (question, value) => {
        if (!userAnswers) {
            return;
        }

        const newAnswer = {
            question: question.label,
            values: [value],
        };

        const questionIndex = userAnswers.findIndex((answer) => answer.question === question.label);

        let newUserAnswers;
        if (questionIndex !== -1) {
            newUserAnswers = [...userAnswers];
            newUserAnswers[questionIndex] = newAnswer;
        } else {
            newUserAnswers = [...userAnswers, newAnswer];
        }

        setUserAnswers(newUserAnswers);
    };

    return (
        <div className="epices-form mx-4">
            {questions &&
                questions.map((question, index) => {
                    const options = [
                        { label: question.answer1, value: "Oui" },
                        { label: question.answer2, value: "Non" },
                    ];

                    return (
                        <div key={index} style={{ width: "45%" }}>
                            <p>{question.label}</p>
                            <InputCheckCard
                                initialValue={answers ? answers[index]?.values[0] : null}
                                options={options}
                                onChange={(value) => {
                                    const option = options.find((opt) => opt.value === value);
                                    handleAnswerChange(question, value, option);
                                }}
                                disabled={readOnly}
                                epices
                            />
                        </div>
                    );
                })}
        </div>
    );
};

EpicesForm.propTypes = {
    questions: PropTypes.arrayOf(PropTypes.object).isRequired,
    answers: PropTypes.arrayOf(PropTypes.object).isRequired,
    userAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
    setUserAnswers: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

export default EpicesForm;
