import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    list: [],
    listLoading: false,
    listPatientId: -1,
    listLoaded: 0,

    listMeasureTypes: [],
    listMeasureTypesLoading: false,
    listMeasureTypesLoaded: 0,

    created: false,
    createError: false,
    createLoading: false
};

function measures(state = initialState, action) {
    switch (action.type) {
        // FETCH
        case types.MEASURES_FETCH_REQUESTED: {
            return Object.assign({}, state, {
                listLoading: true,
                listPatientId: -1,
                listLoaded: 0,
            });
        }
        case types.MEASURES_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                listLoading: false,
                list: action.data,
                listPatientId: action.patientId,
                listLoaded: Date.now(),
            });
        }
        case types.MEASURES_FETCH_FAILURE: {
            return Object.assign({}, state, { listLoading: false, list: [] });
        }
        // CREATE
        case types.MEASURES_CREATE_REQUESTED: {
            return Object.assign({}, state, {
                createLoading: true,
                createError: false,
                created: false,
            });
        }
        case types.MEASURES_CREATE_SUCCESS: {
            toast.success("La donnée a bien été créée", { autoClose: 5000 });
            return Object.assign({}, state, {
                createLoading: false,
                created: action.data,
                list: [action.data, ...state.list]
            });
        }
        case types.MEASURES_CREATE_FAILURE: {
            toast.error("La donnée n'a pas pu être créée");
            return Object.assign({}, state, {
                createLoading: false,
                createError: action.data,
            });
        }
        // FETCH MEASURE TYPES
        case types.MEASURE_TYPES_FETCH_REQUESTED: {
            return Object.assign({}, state, {
                listMeasureTypesLoading: true,
                listMeasureTypesLoaded: 0,
            });
        }
        case types.MEASURE_TYPES_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                listMeasureTypesLoading: false,
                listMeasureTypes: action.data,
                listMeasureTypesLoaded: Date.now()
            });
        }
        case types.MEASURE_TYPES_FETCH_FAILURE: {
            return Object.assign({}, state, {
                listMeasureTypesLoading: false,
                listMeasureTypes: []
            });
        }
        // DEFAULT
        default:
            return state;
    }
}

export default measures;
