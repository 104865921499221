import React from 'react';

const defaultState = {
    label: '',
    showLabelInput: false,
    responsible: '',
    dueAt: '',
};

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'SELECT_LABEL':
            return {
                ...prevState,
                label: (action.label === 'custom') ? '' : action.label,
                showLabelInput: (action.label === 'custom')
            }
        case 'SET_LABEL':
            return {
                ...prevState,
                label: action.label
            }
        case 'SET_RESPONSIBLE':
            return {
                ...prevState,
                responsible: action.responsible
            }
        case 'SET_DUE_AT':
            return {
                ...prevState,
                dueAt: action.dueAt
            }
        default:
            return prevState;
    }
};

const useState = () => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(() => ({

        selectLabel: (label) => dispatch({ type: 'SELECT_LABEL', label }),

        setLabel: (label) => dispatch({ type: 'SET_LABEL', label }),

        setResponsible: (responsible) => dispatch({ type: 'SET_RESPONSIBLE', responsible }),

        setDueAt: (dueAt) => dispatch({ type: 'SET_DUE_AT', dueAt }),

    }), []);
    
    return [state, methods];
};

export default useState;