import React from "react";

import PropTypes from "prop-types";
import { Animated } from "react-animated-css";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { getViolations, PageTitle } from "@rdcs/dap-front-library";

import { tryEditPatient, tryMedicalPatientDelete } from "../../../actions/patients";

import PatientCard from "../../../components/Patients/PatientCard";
import PatientCoordonneesForm from "../../../components/Patients/PatientCoordonneesForm";
import PatientNavLayout from "../../../components/Patients/PatientNavLayout";

const PatientsNavCoordonneesScreen = ({ patients, tryEditPatient, tryMedicalPatientDelete }) => {
    const { navigate } = useNavigate();
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);
    const [medicalPatient, setMedicalPatient] = React.useState(null);

    const onRemovePatient = React.useCallback(
        () =>
            tryMedicalPatientDelete(medicalPatient).then(() => {
                navigate("/mes-patients");
            }),
        [medicalPatient]
    );

    return (
        <PageTitle title="Timkl - Coordonnées patient">
            <PatientNavLayout
                patientId={patientId}
                onLoad={setMedicalPatient}
                onMedicalPatientUpdate={setMedicalPatient}
            >
                <div className="informationsList">
                    <div className="page-single-patient">
                        {medicalPatient && (
                            <Animated animationIn="fadeIn">
                                <PatientCard patient={medicalPatient.patient} onRemovePatient={onRemovePatient} />
                                <div className="blockItem">
                                    <PatientCoordonneesForm
                                        submit={(patient) => tryEditPatient(patientId, patient)}
                                        profile={medicalPatient.patient}
                                        patientId={patientId}
                                        profileEditLoading={patients.patientEditLoading}
                                        profileEditError={patients.patientEditError}
                                        violations={getViolations(patients.patientEditError)}
                                    />
                                </div>
                            </Animated>
                        )}
                    </div>
                </div>
            </PatientNavLayout>
        </PageTitle>
    );
};

PatientsNavCoordonneesScreen.propTypes = {
    patients: PropTypes.object.isRequired,
    tryEditPatient: PropTypes.func.isRequired,
    tryMedicalPatientDelete: PropTypes.func.isRequired,
};

const mapStateToProps = ({ patients }) => ({ patients });

const mapDispatchToProps = {
    tryEditPatient,
    tryMedicalPatientDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientsNavCoordonneesScreen);
