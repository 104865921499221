import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { tryCreateNote, tryFetchNotes } from "../../../../../../actions/notes";

import { DefaultLoader, DashboardHeaderTitle, getViolations } from "@rdcs/dap-front-library";

import NoteItem from "../../../../../../components/Notes/NoteItem";

import LookLink from "../../LookLink";

import RightSection from "./RightSection";

const Notes = ({ patientId, patientNotes, createLoading, createError, tryFetchNotes, tryCreateNote }) => {
    const [notesLoading, setNotesLoading] = React.useState(true);

    React.useEffect(() => {
        tryFetchNotes(patientId).finally(() => setNotesLoading(false));
    }, []);

    return (
        <section>
            <DashboardHeaderTitle
                title="Dernières notes"
                icon="questionnaire"
                className="mt-4 mb-2"
                renderRightSection={
                    <RightSection patientId={patientId} createLoading={createLoading} createError={createError} tryCreateNote={tryCreateNote} />
                }
            />
            <div>
                {notesLoading ? (
                    <DefaultLoader color="#CCC" />
                ) : patientNotes.length === 0 ? (
                    <p>Aucune note pour ce patient actuellement.</p>
                ) : (
                    <>
                        <div className="reportsList grid grid__padding-10">
                            {patientNotes.map((note) => (
                                <div className="col-md-33 col-sm-100" key={note.id}>
                                    <NoteItem key={note.id} patientId={patientId} note={note} />
                                </div>
                            ))}
                        </div>
                        <p className="mt-2">
                            <LookLink link={`/mes-patients/${patientId}/comptes-rendus`}>Voir les notes précédentes</LookLink>
                        </p>
                    </>
                )}
            </div>
        </section>
    );
};

Notes.propTypes = {
    patientId: PropTypes.number.isRequired,
    patientNotes: PropTypes.arrayOf(PropTypes.object).isRequired,
    createLoading: PropTypes.bool.isRequired,
    createError: PropTypes.object.isRequired,
    tryFetchNotes: PropTypes.func.isRequired,
};

const mapStateToProps = ({ notes: { list, createLoading, createError } }) => ({
    patientNotes: list
        .filter((note) => note.active === true)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 3),
    createLoading,
    createError: getViolations(createError),
});

const mapDispatchToProps = {
    tryFetchNotes,
    tryCreateNote,
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Notes));
