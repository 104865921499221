import * as types from "./types";
import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

export const tryEmailValidationDiagnosis = token => (dispatch) => {
    dispatch({ type: types.EMAIL_VALIDATION_DIAGNOSTIC_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/validation-diagnostic/{token}" : "/dap-hcp-patient/api/v1/customers/patients/validation-diagnotic/{token}",
        uriParams: {
            token,
        },
        method: "GET",
    })
    .then((response) =>
        dispatch({
            type: types.EMAIL_VALIDATION_DIAGNOSTIC_SUCCESS,
            data: response,
        })
    )
    .catch((error) => {
        dispatch({
            type: types.EMAIL_VALIDATION_DIAGNOSTIC_FAILURE,
            data: error,
        });
        throw error;
    });
};