import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    DefaultLoader,
    Icon,
    getViolations,
    PageTitle,
    PractitionerLayoutAnonymous,
    PractitionerLayoutAsideAnonymous,
} from "@rdcs/dap-front-library";

import useState from "./useState";

import { tryFetchProfileConsents, tryFinalizeProfile } from "../../../actions/profile";

import FinalizeSignInForm from "../../../components/SignIn/FinalizeSignInForm";

import API from "../../../utils/API";
import { isLocalAPI } from "../../../utils/utils";

const FinalizeSignInScreen = ({ profile, tryFetchProfileConsents, tryFinalizeProfile }) => {
    const { uid } = useParams();
    const navigate = useNavigate();

    const [{ practitioner, practitionerLoading, practitionerError }, { setPractitioner, setPractitionerError }] =
        useState();

    React.useEffect(() => {
        Promise.all([
            tryFetchProfileConsents(),
            API.request({
                path: isLocalAPI
                    ? "/finalize_practitioner/{uid}"
                    : "/dap-hcp-patient/api/v1/customers/practitioners/finalize/{uid}",
                uriParams: {
                    uid,
                },
            }),
        ])
            .then(([, response]) => setPractitioner(response))
            .catch(setPractitionerError);
    }, []);

    const finalizeProfile = (form) =>
        tryFinalizeProfile(uid, form)
            .then(() => navigate("/"))
            .catch(() => undefined);
    return (
        <PageTitle title="Timkl - Finalisation de votre inscription">
            <PractitionerLayoutAnonymous>
                <div className="grid credentials__container">
                    <PractitionerLayoutAsideAnonymous />
                    <main className="col-sm-50 credentialsMain__container">
                        <div className="credentialsMain">
                            <Link to="/connexion" className="credentialsMain__back">
                                <Icon name="arrow-left" />
                            </Link>
                            <h1 className="credentials__title">Finalisation de votre inscription</h1>
                            {practitionerLoading ? (
                                <DefaultLoader />
                            ) : !practitionerLoading && practitionerError ? (
                                <div className="bgError radius shadow mt-4 mb-2 pt-2 pl-3 pb-2 pr-3">
                                    Demande invalide
                                </div>
                            ) : (
                                <FinalizeSignInForm
                                    practitioner={practitioner}
                                    consents={profile.profileConsents}
                                    submit={finalizeProfile}
                                    loading={profile.profileFinalizeLoading}
                                    violations={getViolations(profile.profileFinalizeError)}
                                />
                            )}
                        </div>
                    </main>
                </div>
            </PractitionerLayoutAnonymous>
        </PageTitle>
    );
};

FinalizeSignInScreen.propTypes = {
    profile: PropTypes.object.isRequired,
    tryFetchProfileConsents: PropTypes.func.isRequired,
    tryFinalizeProfile: PropTypes.func.isRequired,
};

const mapStateToProps = ({ profile }) => ({ profile });

const mapDispatchToProps = {
    tryFetchProfileConsents,
    tryFinalizeProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(FinalizeSignInScreen);
