import React from 'react';

import isAllChecked from './isAllChecked';

const createRefs = (diagnosticRecoNotMandatory, prevRefs = {}) => {
    const refs = {};

    for (const { id } of diagnosticRecoNotMandatory) {
        refs[id] = prevRefs[id] || React.createRef();
    }
    return refs;
};

const reducer = (prevState, action) => {
    let form;

    switch (action.type) {
        case 'DEFINE_REF':
            return {
                ...prevState,
                refs: createRefs(action.diagnosticRecoNotMandatory, prevState.refs)
            }
        case 'INIT_ALL_CHECKED':
            form = { ...action.form };

            delete form.all;
            return {
                ...prevState,
                allChecked: isAllChecked(form),
                isInit: true
            }
        case 'UPDATE_ALL_CHECKED':
            form = { ...action.form };

            delete form.all;
            return {
                ...prevState,
                allChecked: isAllChecked(form),
            }
        default:
            return prevState;
    }
};

const useState = (diagnosticRecoNotMandatory) => {
    const defaultState = React.useMemo(() => ({
        isInit: false,
        allChecked: undefined,
        refs: createRefs(diagnosticRecoNotMandatory),
    }), []);

    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(() => ({

        initAllChecked: form => dispatch({ type: 'INIT_ALL_CHECKED', form }),

        updateAllChecked: (...[, , form]) => dispatch({ type: 'UPDATE_ALL_CHECKED', form }),

    }), []);

    React.useEffect(() => {
        dispatch({ type: 'DEFINE_REF', diagnosticRecoNotMandatory });
    }, [diagnosticRecoNotMandatory.length]);

    return [state, methods];
};

export default useState;