import React from "react";
import PropTypes from "prop-types";
import { DynamicQuestions, Gauge, QuestionnaireAnswerContainer } from "@rdcs/dap-front-library";

const QuestionnaireAnswerDisplay = ({ questions, answers, score, singleQuestionnaire }) => {
    return (
        <QuestionnaireAnswerContainer singleQuestionnaire={singleQuestionnaire}>
            <h3 className="title-3-nunito mt-6 timkl__color--blue-dark" style={{ maxWidth: "515px" }}>
                Au vu des réponses, le patient se situe {score >= 50 ? "au dessus" : "en dessous"} du seuil d’éligibilité.
            </h3>
            <div style={{ maxWidth: "590px" }}>
                <Gauge className="my-4" info="Score du patient" legend="Seuil d’éligibilité" value={Math.round(Math.min(100, score))} />
            </div>
            {singleQuestionnaire && <h3 className="title-3-nunito mt-6 timkl__color--blue-dark">Réponses du questionnaire</h3>}
            <DynamicQuestions questions={questions} answers={answers} readOnly={true} />
        </QuestionnaireAnswerContainer>
    );
};

QuestionnaireAnswerDisplay.propTypes = {
    questions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            active: PropTypes.bool.isRequired,
            answer1: PropTypes.string.isRequired,
            answer2: PropTypes.string.isRequired,
            answer3: PropTypes.string,
            answer4: PropTypes.string,
            answer5: PropTypes.string,
            answer6: PropTypes.string,
            answer7: PropTypes.string,
            answer8: PropTypes.string,
            answer9: PropTypes.string,
        })
    ).isRequired,
    answers: PropTypes.arrayOf(
        PropTypes.shape({
            question: PropTypes.string,
            values: PropTypes.arrayOf(PropTypes.any),
        })
    ).isRequired,
    score: PropTypes.number.isRequired,
    singleQuestionnaire: PropTypes.bool.isRequired,
};

export default QuestionnaireAnswerDisplay;
