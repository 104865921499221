import * as types from "../actions/types";

const initialState = {
    list: [],
    listLoading: false,
    listLoaded: 0,
};

function treatments(state = initialState, action) {
    switch (action.type) {
        case types.TREATMENTS_FETCH_REQUESTED: {
            return Object.assign({}, state, {
                listLoading: true,
                listLoaded: 0,
            });
        }
        case types.TREATMENTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                listLoading: false,
                list: action.data,
                listLoaded: Date.now(),
            });
        }
        case types.TREATMENTS_FETCH_FAILURE: {
            return Object.assign({}, state, { listLoading: false, list: [] });
        }
        default:
            return state;
    }
}

export default treatments;
