import { applyMiddleware, createStore } from "redux";
import reducers from "./reducers";
import logger from "redux-logger";
import thunk from "redux-thunk";

const middlewares = [thunk];

if (process.env.REACT_APP_LOGGER_ENABLED === 'true') {
    middlewares.push(logger);
}

const store = createStore(reducers, applyMiddleware(...middlewares));

export default store;