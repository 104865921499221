import React from 'react';
import {toast} from "react-toastify";

const defaultState = {
    consent: null,
    loading: true,
    questionsLoaded: false,
    answersLoaded: false,
    patient: null,
    answers: []
};

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'SET_CONSENT': {
            if (action.consent === null) {
                toast.error("L'upload a échoué pour une raison inconnue");
            }
            return {
                ...prevState,
                consent: action.consent,
            }
        }
        case 'QUESTIONS_END_LOAD':
            return {
                ...prevState,
                questionsLoaded: true,
                patient: action.patient
            }
        case 'ANSWERS_END_LOAD':
            return {
                ...prevState,
                answersLoaded: true,
            }
        case 'INIT_ANSWERS':
            return {
                ...prevState,
                loading: false,
                answers: action.answers,
            }
        case 'UPDATE_ANSWER':
            return {
                ...prevState,
                answers: prevState.answers.map((oldAnswers, index) => (index === action.index) ? ({ value: action.answer.value, id: action.answer.id }) : oldAnswers)
            }
        default:
            return prevState;
    }
};

const useState = () => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(() => ({

        setConsent: (consent = null) => dispatch({ type: 'SET_CONSENT', consent }),

        questionsEndLoad: ({ patient }) => dispatch({ type: 'QUESTIONS_END_LOAD', patient }),

        answersEndLoad: () => dispatch({ type: 'ANSWERS_END_LOAD' }),

        initAnswer: (studyQuestions, studyAnswers) => {
            const answers = [];
    
            for (const studyAnswer of studyAnswers) {
                const index = studyQuestions.findIndex(studyQuestion => studyQuestion.label === studyAnswer.question);
    
                if (index !== -1) {
                    answers[index] = { value: studyAnswer.value, id: studyAnswer.id };
                }
            }
            dispatch({ type: 'INIT_ANSWERS', answers });
        },

        updateAnswer: (answer, index) => dispatch({ type: 'UPDATE_ANSWER', answer, index })

    }), []);

    return [state, methods];
};

export default useState;