import React from 'react';

const defaultState = {
    justificatory: null,
    justificatoryError: false,
    submitLoading: false,
};

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'SET_JUSTIFICATORY':
            return {
                ...prevState,
                justificatory: action.justificatory,
                justificatoryError: null,
            }
        case 'SUBMIT_JUSTIFICATORY':
            return {
                ...prevState,
                submitLoading: true,
            }
        case 'SUBMIT_JUSTIFICATORY_ERROR':
            return {
                ...prevState,
                justificatoryError: action.error,
                submitLoading: false,
            }
        default:
            return prevState;
    }
};

const useState = () => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(() => ({

        setJustificatory: (justificatory) => dispatch({ type: 'SET_JUSTIFICATORY', justificatory }),

        submitJustificatory: () => dispatch({ type: 'SUBMIT_JUSTIFICATORY' }),

        submitJustificatoryError: (error) => dispatch({ type: 'SUBMIT_JUSTIFICATORY_ERROR', error }),

    }), []);

    return [state, methods];
};

export default useState;