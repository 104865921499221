import React from "react";
import PropTypes from "prop-types";
import { InputCheckCard } from "@rdcs/dap-front-library";

const BeckForm = ({ questions, answers, userAnswers, setUserAnswers, readOnly }) => {
    React.useEffect(() => {
        setUserAnswers(answers);
    }, [answers]);

    const handleAnswerChange = (question, label) => {
        if (!userAnswers) {
            return;
        }

        const newAnswer = {
            question: question.label,
            values: [label],
        };

        const questionIndex = userAnswers.findIndex((answer) => answer.question === question.label);

        let newUserAnswers;
        if (questionIndex !== -1) {
            newUserAnswers = [...userAnswers];
            newUserAnswers[questionIndex] = newAnswer;
        } else {
            newUserAnswers = [...userAnswers, newAnswer];
        }

        setUserAnswers(newUserAnswers);
    };

    return (
        <div>
            {questions &&
                questions
                    .sort((a, b) => a.order - b.order)
                    .map((question, index) => {
                        const options = [
                            { label: question.answer1, value: 0 },
                            { label: question.answer2, value: 1 },
                            { label: question.answer3, value: 2 },
                            { label: question.answer4, value: 3 },
                        ];

                        let initial = null;

                        options.forEach((option) => {
                            let optionLabel = option.label.trim().toLowerCase().replace(/’/g, "'");

                            let answer = answers.find((answer) => answer.question.trim().toLowerCase() === question.label.trim().toLowerCase());

                            if (!answer) {
                                return;
                            }

                            let answerLabel = answer.values[0].trim().toLowerCase().replace(/’/g, "'");

                            if (optionLabel === answerLabel) {
                                initial = option.value;
                            }
                        });

                        return (
                            <div key={index}>
                                <p className="mt-2 mb-1">{question.label}</p>
                                <InputCheckCard
                                    initialValue={initial}
                                    options={options}
                                    onChange={(label) => {
                                        handleAnswerChange(question, label);
                                    }}
                                    disabled={readOnly}
                                />
                            </div>
                        );
                    })}
        </div>
    );
};

BeckForm.propTypes = {
    questions: PropTypes.arrayOf(PropTypes.object).isRequired,
    answers: PropTypes.arrayOf(PropTypes.object).isRequired,
    userAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
    setUserAnswers: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

export default BeckForm;
