import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { MultipleQuestionnaireContainer } from "@rdcs/dap-front-library";

import QuestionnaireAnswerDisplay from "./QuestionnaireAnswerDisplay";
import QuestionnaireListDisplay from "./QuestionnaireListDisplay";

const MultiplePastQuestionnaires = ({
    breakScoreNoInsulinQuestions,
    breakScoreQuestions,
    isInsulinoTreated,
    questionnaireList,
    questionnaires,
    setFillNewQuestionnaire,
}) => {
    const [indexToDisplay, setIndexToDisplay] = React.useState(0);

    return (
        <MultipleQuestionnaireContainer>
            <QuestionnaireListDisplay
                questionnaireList={questionnaireList}
                setIndexToDisplay={setIndexToDisplay}
                indexToDisplay={indexToDisplay}
                setFillNewQuestionnaire={setFillNewQuestionnaire}
            />
            <QuestionnaireAnswerDisplay
                key={indexToDisplay}
                questions={isInsulinoTreated ? breakScoreQuestions : breakScoreNoInsulinQuestions}
                answers={questionnaires[indexToDisplay]?.answers}
                score={questionnaireList[indexToDisplay]?.score}
                singleQuestionnaire={false}
            />
        </MultipleQuestionnaireContainer>
    );
};

MultiplePastQuestionnaires.propTypes = {
    breakScoreNoInsulinQuestions: PropTypes.arrayOf(PropTypes.object),
    breakScoreQuestions: PropTypes.arrayOf(PropTypes.object),
    isInsulinoTreated: PropTypes.bool.isRequired,
    questionnaireList: PropTypes.arrayOf(PropTypes.object),
    questionnaires: PropTypes.arrayOf(PropTypes.object),
    setFillNewQuestionnaire: PropTypes.func.isRequired,
};

const mapStateToProps = ({ questions: { breakScoreQuestions, breakScoreAnswers, breakScoreNoInsulinQuestions } }) => ({
    breakScoreQuestions,
    breakScoreNoInsulinQuestions,
    breakScoreAnswers,
});

export default connect(mapStateToProps)(MultiplePastQuestionnaires);
