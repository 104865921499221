import React from 'react';

const defaultState = {
    practitioner: null,
    practitionerLoading: true,
    practitionerError: false,
};

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'SET_PRACTITIONER':
            return {
                ...prevState,
                practitioner: action.practitioner,
                practitionerLoading: false,
            }
        case 'SET_PRACTITIONER_ERROR':
            return {
                ...prevState,
                practitionerError: true,
                practitionerLoading: false,
            }
        default:
            return prevState;
    }
};

const useState = () => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(() => ({

        setPractitioner: (practitioner) => dispatch({ type: 'SET_PRACTITIONER', practitioner }),

        setPractitionerError: () => dispatch({ type: 'SET_PRACTITIONER_ERROR' }),

    }), []);

    return [state, methods];
};

export default useState;