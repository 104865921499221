import React from "react";

import "moment/locale/fr";
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { AppLoading } from "@rdcs/dap-front-library";

import App from "./App";
import "./index.css";
import ErrorScreen from "./screens/Utils/ErrorScreen";
import store from "./store";
import API from "./utils/API";
import { NavigationProvider } from "./utils/navigationProvider";
import { isLocalAPI } from "./utils/utils";

// TODO : Find better way to store these var in dap-front-library
window.fileRetrieveUrl = isLocalAPI
    ? "http://localhost:81/files/retrieve"
    : window.env.API_URL + "/dap-hcp-patient/api/v1/files/retrieve";
window.fileRetrieveHeaders = {
    client_id: window.env.API_CLIENT_ID,
    client_secret: window.env.API_CLIENT_SECRET,
    ApiKey: window.env.AUTH_API_KEY,
    Authorization: isLocalAPI ? localStorage.getItem("id_token") : "Bearer " + localStorage.getItem("access_token"),
};

ReactDOM.render(<AppLoading />, document.getElementById("root"));

API.request({
    path: isLocalAPI ? "/constants" : "/dap-hcp-patient/api/v1/settings/constants",
})
    .then((constants) => {
        window.CONSTANTS = constants;
        ReactDOM.render(
            <Router>
                <Provider store={store}>
                    <NavigationProvider>
                        <App />
                    </NavigationProvider>
                </Provider>
            </Router>,
            document.getElementById("root")
        );
    })
    .catch(() => {
        ReactDOM.render(
            <ErrorScreen title="Erreur" content="Le service est indisponible, veuillez réessayer ultérieurement" />,
            document.getElementById("root")
        );
    });
