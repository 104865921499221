import React from "react";

import { PractitionerLayoutAnonymous, PageTitle } from "@rdcs/dap-front-library";

const AccessDeniedExceptionScreen = () => (
    <PageTitle title='Timkl - Accès non autorisé'>
        <PractitionerLayoutAnonymous>
            <div className="appLoading">
                <div className="appLoading__container">
                    <h1 className="title-1">403</h1>
                    <p className="text-3">
                        Vous n'avez pas les droits requis pour accéder à la
                        page demandée
                    </p>
                </div>
            </div>
        </PractitionerLayoutAnonymous>
    </PageTitle>
);

AccessDeniedExceptionScreen.propTypes = {};

export default AccessDeniedExceptionScreen;
