import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { SingleQuestionnaireHeader } from "@rdcs/dap-front-library";

import QuestionnaireAnswerDisplay from "./QuestionnaireAnswerDisplay";
import { formatDate } from "../../utils/utils";

const SinglePastQuestionnaire = ({
    breakScoreNoInsulinQuestions,
    breakScoreQuestions,
    isInsulinoTreated,
    questionnaire,
    setFillNewQuestionnaire,
}) => {
    const date = formatDate(questionnaire.createdAt);
    return (
        <div>
            <SingleQuestionnaireHeader date={date} setFillNewQuestionnaire={setFillNewQuestionnaire} />
            {questionnaire && (
                <QuestionnaireAnswerDisplay
                    questions={isInsulinoTreated ? breakScoreQuestions : breakScoreNoInsulinQuestions}
                    answers={questionnaire.answers}
                    score={questionnaire.score}
                    singleQuestionnaire={true}
                />
            )}
        </div>
    );
};

SinglePastQuestionnaire.propTypes = {
    breakScoreNoInsulinQuestions: PropTypes.arrayOf(PropTypes.object),
    breakScoreQuestions: PropTypes.arrayOf(PropTypes.object),
    isInsulinoTreated: PropTypes.bool.isRequired,
    questionnaire: PropTypes.object,
    setFillNewQuestionnaire: PropTypes.func.isRequired,
};

const mapStateToProps = ({ questions: { breakScoreQuestions, breakScoreNoInsulinQuestions } }) => ({
    breakScoreQuestions,
    breakScoreNoInsulinQuestions,
});

export default connect(mapStateToProps)(SinglePastQuestionnaire);
