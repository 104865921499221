import React from "react";
import PropTypes from 'prop-types';

import { DropDownItem } from "@rdcs/dap-front-library";

import RecoDescription from './RecoDescription';

const DiagnosticReco = React.forwardRef(({
    reco: {
        id,
        active,
        recommendation: {
            title,
            description
        },
        explanations,
    },
    disabled = false,
    renderRightSection = undefined,
}, ref) => (
    <DropDownItem
        name={id.toString()}
        title={title}
        defaultChecked={active}
        disabled={disabled}
        renderRightSection={renderRightSection}
        ref={ref}
    >
        <RecoDescription
            explanations={explanations}
            description={description}
        />
    </DropDownItem>
));

DiagnosticReco.propTypes = {
    reco: PropTypes.shape({
        id: PropTypes.number.isRequired,
        recommendation: PropTypes.shape({
            title: PropTypes.string.isRequired,
            active: PropTypes.bool.isRequired,
            description: PropTypes.string,
        }).isRequired,
        explanations: PropTypes.object,
    }).isRequired,
    disabled: PropTypes.bool,
    renderRightSection: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
};

export default React.memo(DiagnosticReco);