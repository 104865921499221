import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { AdminLayoutAnonymous, AdminLayoutAsideAnonymous, DefaultLoader, PageTitle } from "@rdcs/dap-front-library";

import { tryEmailValidationDiagnosis } from "../../actions/email";

const Content = ({ state }) => {
    if (state === null) {
        return <DefaultLoader />;
    }

    if (state === false) {
        return "Une erreur s'est produite, veuillez vous rapprocher de votre facilitateur délégué.";
    }

    return "Le parcours de soin défini par le facilitateur a bien été validé.";
};

const DiagnosticValidationScreen = ({ tryEmailValidationDiagnosis }) => {
    const { token } = useParams();
    const [state, setState] = useState(null);

    useEffect(() => {
        if (state === null) {
            tryEmailValidationDiagnosis(token)
                .then(() => {
                    setState(true);
                })
                .catch(() => {
                    setState(false);
                });
        }
    }, [state]);

    return (
        <PageTitle title="Timkl - Validation de la délégation">
            <AdminLayoutAnonymous>
                <div className="grid credentials__container">
                    <AdminLayoutAsideAnonymous />
                    <main className="col-sm-50 credentialsMain__container">
                        <div className="credentialsMain">
                            <div className="credentials__title">
                                <Content state={state} />
                            </div>
                        </div>
                    </main>
                </div>
            </AdminLayoutAnonymous>
        </PageTitle>
    );
};

DiagnosticValidationScreen.propTypes = {
    tryEmailValidationDiagnosis: PropTypes.func.isRequired,
};

const mapStateToProps = (_) => ({});

const mapDispatchToProps = {
    tryEmailValidationDiagnosis,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticValidationScreen);
