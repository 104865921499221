import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { ScrollToTop } from "@rdcs/dap-front-library";

import PrivateRoute from "./components/Utils/PrivateRoute";

import BreakScoreQuestionnaireScreen from "./screens/BreakScoreQuestionnaireScreen";

import SubmitJustificatoryScreen from "./screens/Email/SubmitJustificatoryScreen";
import DiagnosticValidationScreen from "./screens/Email/DiagnosticValidationScreen";
import NotFoundExceptionScreen from "./screens/Exceptions/NotFoundException";

import LoginScreen from "./screens/Login/LoginScreen";
import LoginSuccessScreen from "./screens/Login/LoginSuccessScreen";
import LogoutScreen from "./screens/Login/LogoutScreen";

import ContactScreen from "./screens/Page/ContactScreen";
import DataPolicyScreen from "./screens/Page/DataPolicyScreen";
import LegalNoticeScreen from "./screens/Page/LegalNoticeScreen";
import TermsOfUsageScreen from "./screens/Page/TermsOfUsageScreen";

import PatientsAddScreen from "./screens/Patients/PatientsAddScreen";
import PatientsDiagnosticScreen from "./screens/Patients/PatientsDiagnosticScreen";
import PatientsNavBeckScreen from "./screens/Patients/PatientsNav/PatientsNavBeckScreen";
import PatientsNavBreakScoreScreen from "./screens/Patients/PatientsNav/PatientsNavBreakScoreScreen";
import PatientsNavConsultsScreen from "./screens/Patients/PatientsNav/PatientsNavConsultsScreen";
import PatientsNavCoordonneesScreen from "./screens/Patients/PatientsNav/PatientsNavCoordonneesScreen";
import PatientsNavDashboardScreen from "./screens/Patients/PatientsNav/PatientsNavDashboardScreen";
import PatientsNavDataScreen from "./screens/Patients/PatientsNav/PatientsNavDataScreen";
import PatientsNavEpicesScreen from "./screens/Patients/PatientsNav/PatientsNavEpicesScreen";
import PatientsNavExamsScreen from "./screens/Patients/PatientsNav/PatientsNavExamsScreen";
import PatientsNavInfosScreen from "./screens/Patients/PatientsNav/PatientsNavInfosScreen";
import PatientsNavLastDiagnosticScreen from "./screens/Patients/PatientsNav/PatientsNavLastDiagnosticScreen";
import PatientsNavNotesScreen from "./screens/Patients/PatientsNav/PatientsNavNotesScreen";
import PatientsNavProSanteScreen from "./screens/Patients/PatientsNav/PatientsNavProSante";
import PatientsNavStudyQuestionsScreen from "./screens/Patients/PatientsNav/PatientsNavStudyQuestionsScreen";
import PatientsNavTasksScreen from "./screens/Patients/PatientsNav/PatientsNavTasksScreen";
import PatientsReportScreen from "./screens/Patients/PatientsNav/PatientsReportScreen";
import PatientsScreen from "./screens/Patients/PatientsScreen";

import ProfileScreen from "./screens/Profile/ProfileScreen";

import RecommandationsScreen from "./screens/Recommandations/RecommandationsScreen";

import FinalizeSignInScreen from "./screens/SignIn/FinalizeSignInScreen";
import SignInScreen from "./screens/SignIn/SignInScreen";

import RedirectToPatientDashboard from "./utils/redirectToPatientDashboard";
import { allowedRoles, Roles, getRoles } from "./utils/TokenData";
import useCancelRequestsOnNavigation from "./utils/useCancelRequestsOnNavigation";

const App = () => {
    // TODO Find a way to cancel pending API requests when navigating with React Router v6
    // useCancelRequestsOnNavigation();
    return (
        <ScrollToTop>
            <ToastContainer autoClose={3000} />
            <Routes>
                <Route path="/" element={<Navigate to="/connexion" />} />
                <Route path="/login_success" element={<LoginSuccessScreen />} />
                <Route path="/connexion" element={<LoginScreen />} />
                <Route path="/inscription" element={<SignInScreen />} />
                <Route path="/finalisation-inscription/:uid" element={<FinalizeSignInScreen />} />
                <Route path="/cgu" element={<TermsOfUsageScreen />} />
                <Route path="/politique-de-donnees" element={<DataPolicyScreen />} />
                <Route path="/mentions-legales" element={<LegalNoticeScreen />} />
                <Route path="/contact" element={<ContactScreen />} />
                <Route path="/email/submit-justificatory/:uid" element={<SubmitJustificatoryScreen />} />
                <Route path="/email/validation-diagnostic/:token" element={<DiagnosticValidationScreen />} />
                <Route path="/deconnexion" element={<LogoutScreen />} />
                <Route path="*" element={<NotFoundExceptionScreen />} />

                <Route element={<PrivateRoute allowedRoles={allowedRoles} />}>
                    <Route path="/mes-patients" element={<PatientsScreen />} />
                    <Route path="/mes-patients/ajouter" element={<PatientsAddScreen />} />
                    <Route path="/mes-patients/ajouter/:id" element={<PatientsAddScreen />} />
                    <Route path="/mes-patients/:id/dashboard" element={<PatientsNavDashboardScreen />} />
                    <Route path="/mes-patients/:id/informations" element={<PatientsNavInfosScreen />} />
                    <Route path="/mes-patients/:id/visites" element={<PatientsNavConsultsScreen />} />
                    <Route path="/mes-patients/:id/donnees" element={<PatientsNavDataScreen />} />
                    <Route path="/mes-patients/:id/medecins" element={<PatientsNavProSanteScreen />} />
                    <Route path="/mes-patients/:id/actions" element={<PatientsNavTasksScreen />} />
                    <Route path="/mes-patients/:id/nouvelle-consultation" element={<PatientsDiagnosticScreen />} />
                    <Route path="/mes-patients/:id/coordonnees" element={<PatientsNavCoordonneesScreen />} />
                    <Route
                        path="/mes-patients/:id/derniere-consultation"
                        element={<PatientsNavLastDiagnosticScreen />}
                    />
                    <Route path="/mes-patients/:id/examens" element={<PatientsNavExamsScreen />} />
                    <Route path="/mes-patients/:id/notes" element={<PatientsNavNotesScreen />} />
                    <Route path="/mes-patients/:id/reports" element={<PatientsReportScreen />} />
                    <Route path="/recommandations-de-la-has" element={<RecommandationsScreen />} />
                    <Route path="/mon-profil" element={<ProfileScreen />} />
                </Route>

                <Route
                    element={
                        <PrivateRoute
                            allowedRoles={getRoles(Roles.ROLE_DOCTOR, Roles.ROLE_NURSE, Roles.ROLE_PHARMACIST)}
                        />
                    }
                >
                    <Route path="/mes-patients/:id/study-questions" element={<PatientsNavStudyQuestionsScreen />} />
                    <Route path="/mes-patients/:id/break-score-screen" element={<PatientsNavBreakScoreScreen />} />
                    <Route path="/mes-patients/:id/questionnaire-beck" element={<PatientsNavBeckScreen />} />
                    <Route path="/mes-patients/:id/questionnaire-epices" element={<PatientsNavEpicesScreen />} />
                    <Route path="/questionnaire-rupture" element={<BreakScoreQuestionnaireScreen />} />
                </Route>

                <Route path="/mes-patients/:id" element={<RedirectToPatientDashboard />} />
            </Routes>
        </ScrollToTop>
    );
};

export default App;
