import React from 'react';

const defaultState = {
    filteredList: [],
    search: '',
    pathologiesClicked: [],
    treatmentsClicked: [],
    dataLoading: true
};

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'INIT':
            return {
                ...prevState,
                dataLoading: false,
                filteredList: action.recommandationsList
            }
        case 'END_LOAD':
            return {
                ...prevState,
                dataLoading: false,
            }
        case 'SET_FILTERED_LIST':
            return {
                ...prevState,
                filteredList: action.filteredList,
            }
        case 'SET_SEARCH':
            return {
                ...prevState,
                search: action.search,
            }
        case 'SET_PATHOLOGIES_CLICKED':
            return {
                ...prevState,
                pathologiesClicked: action.pathologiesClicked,
            }
        case 'SET_TREATMENTS_CLICKED':
            return {
                ...prevState,
                treatmentsClicked: action.treatmentsClicked,
            }
        default:
            return prevState;
    }
};

const useState = () => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(() => ({

        init: (recommandationsList) => dispatch({ type: 'INIT', recommandationsList }),

        endLoad: () => dispatch({ type: 'END_LOAD' }),

        setFilteredList: (filteredList) => dispatch({ type: 'SET_FILTERED_LIST', filteredList }),

        setSearch: (search) => dispatch({ type: 'SET_SEARCH', search }),

        setPathologiesClicked: (pathologiesClicked) => dispatch({ type: 'SET_PATHOLOGIES_CLICKED', pathologiesClicked }),

        setTreatmentsClicked: (treatmentsClicked) => dispatch({ type: 'SET_TREATMENTS_CLICKED', treatmentsClicked }),

    }), []);

    return [state, methods];
};

export default useState;