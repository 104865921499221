import * as types from "./types";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from "../store";

const questions = {};

for (const { type, upType, pascalName, camelName, answers } of types.QUESTIONNAIRE_TYPES) {
    questions[`tryFetch${pascalName}Questions`] = () => (dispatch) => {
        const {
            auth: { access_token },
            questions,
        } = store.getState();

        if (questions[`${camelName}QuestionsLoaded`]) {
            return Promise.resolve(questions[`${camelName}Questions`]);
        }

        dispatch({
            type: types.QUESTIONNAIRES[`${upType}_QUESTIONS_FETCH_REQUESTED`],
        });
        return API.request({
            path: isLocalAPI ? "/questions" : "/dap-hcp-patient/api/v1/questionnaires/questions",
            queryParams: {
                pagination: false,
                type,
            },
            token: access_token,
        })
            .then((response) => {
                const questions = response["hydra:member"].filter(({ active }) => active);

                dispatch({
                    type: types.QUESTIONNAIRES[`${upType}_QUESTIONS_FETCH_SUCCESS`],
                    data: questions,
                });
                return questions;
            })
            .catch((error) =>
                dispatch({
                    type: types.QUESTIONNAIRES[`${upType}_QUESTIONS_FETCH_FAILURE`],
                    data: error,
                })
            );
    };

    if (answers) {
        for (const answer of answers) {
            switch (answer.toLowerCase()) {
                case "fetch":
                    questions[`tryFetch${pascalName}Answers`] = (patientId) => (dispatch) => {
                        const {
                            auth: { access_token },
                            questions,
                        } = store.getState();

                        if (questions[`${camelName}AnswersLoaded`] && questions[`${camelName}AnswersPatientId`] === patientId) {
                            return Promise.resolve(questions[`${camelName}Answers`]);
                        }

                        dispatch({
                            type: types.QUESTIONNAIRES[`${upType}_ANSWERS_FETCH_REQUESTED`],
                        });
                        return API.request({
                            path: isLocalAPI ? "/answers" : "/dap-hcp-patient/api/v1/questionnaires/answers",
                            queryParams: {
                                pagination: false,
                                "patient.id": patientId,
                                type,
                            },
                            token: access_token,
                        })
                            .then((response) => {
                                dispatch({
                                    type: types.QUESTIONNAIRES[`${upType}_ANSWERS_FETCH_SUCCESS`],
                                    data: response["hydra:member"],
                                    patientId,
                                });
                                return response["hydra:member"];
                            })
                            .catch((error) => {
                                dispatch({
                                    type: types.QUESTIONNAIRES[`${upType}_ANSWERS_FETCH_FAILURE`],
                                    data: error,
                                });
                                throw error;
                            });
                    };
                    break;
                case "add":
                    questions[`tryAdd${pascalName}Answer`] = (answer) => (dispatch) => {
                        const {
                            auth: { access_token },
                        } = store.getState();

                        dispatch({
                            type: types.QUESTIONNAIRES[`${upType}_ANSWERS_ADD_REQUESTED`],
                        });
                        return API.request({
                            path: isLocalAPI ? "/answers" : "/dap-hcp-patient/api/v1/questionnaires/answers",
                            token: access_token,
                            method: "POST",
                            data: answer,
                        })
                            .then((response) => {
                                dispatch({
                                    type: types.QUESTIONNAIRES[`${upType}_ANSWERS_ADD_SUCCESS`],
                                    data: response,
                                });
                                return response;
                            })
                            .catch((error) => {
                                dispatch({
                                    type: types.QUESTIONNAIRES[`${upType}_ANSWERS_ADD_FAILURE`],
                                    data: error,
                                });
                                throw error;
                            });
                    };
                    break;
                case "edit":
                    questions[`tryEdit${pascalName}Answer`] = (answerId, answer) => (dispatch) => {
                        const {
                            auth: { access_token },
                        } = store.getState();

                        dispatch({
                            type: types.QUESTIONNAIRES[`${upType}_ANSWERS_EDIT_REQUESTED`],
                        });
                        return API.request({
                            path: isLocalAPI ? "/answers/{id}" : "/dap-hcp-patient/api/v1/questionnaires/answers/{id}",
                            uriParams: {
                                id: answerId,
                            },
                            token: access_token,
                            method: "PUT",
                            data: answer,
                        })
                            .then((response) => {
                                dispatch({
                                    type: types.QUESTIONNAIRES[`${upType}_ANSWERS_EDIT_SUCCESS`],
                                    data: response,
                                });
                            })
                            .catch((error) => {
                                dispatch({
                                    type: types.QUESTIONNAIRES[`${upType}_ANSWERS_EDIT_FAILURE`],
                                    data: error,
                                });
                                throw error;
                            });
                    };
                    break;
            }
        }
    }
}

export const tryAddPotentialPatient = (potentialPatient) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.ADD_POTENTIAL_PATIENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/potential_patients" : "/dap-hcp-patient/api/v1/break-score-questionnaires/potential-patients",
        token: access_token,
        method: "POST",
        data: {
            allowDataUsageForStudy: true,
            firstName: potentialPatient.firstName,
            lastName: potentialPatient.lastName,
            birthDate: potentialPatient.birthDate,
            questionnaire: {
                type: potentialPatient.type,
                answers: potentialPatient.answers,
            },
            consent: potentialPatient.consent,
        },
    })
        .then((response) => {
            dispatch({
                type: types.ADD_POTENTIAL_PATIENT_SUCCESS,
                data: response,
            });
            return response;
        })
        .catch((error) => {
            dispatch({
                type: types.ADD_POTENTIAL_PATIENT_FAILURE,
                data: error,
            });
            throw error;
        });
};

export const tryEditPotentialPatient = (potentialPatient, data) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.EDIT_POTENTIAL_PATIENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/potential_patients/{id}" : "/dap-hcp-patient/api/v1/break-score-questionnaires/potential-patients/{id}",
        uriParams: {
            id: potentialPatient.id,
        },
        method: "PUT",
        token: access_token,
        data: {
            ...potentialPatient,
            answers: potentialPatient.answers.map((answer) => ({
                id: answer["@id"],
            })),
            consent: potentialPatient?.consent?.["@id"],
            filledBy: potentialPatient.filledBy["@id"],
            ...data,
            pointOfContact: data.pointOfContact === "" ? null : data.pointOfContact,
        },
    })
        .then((response) => {
            dispatch({
                type: types.EDIT_POTENTIAL_PATIENT_SUCCESS,
                data: response,
            });
            return response;
        })
        .catch((error) => {
            dispatch({
                type: types.EDIT_POTENTIAL_PATIENT_FAILURE,
                data: error,
            });
            throw error;
        });
};

export const tryDeletePotentialPatient = (potentialPatientId) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.DELETE_POTENTIAL_PATIENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/potential_patients/{id}" : "/dap-hcp-patient/api/v1/break-score-questionnaires/potential-patients/{id}",
        uriParams: {
            id: potentialPatientId,
        },
        method: "DELETE",
        token: access_token,
    })
        .then(() => dispatch({ type: types.DELETE_POTENTIAL_PATIENT_SUCCESS }))
        .catch(() => dispatch({ type: types.DELETE_POTENTIAL_PATIENT_FAILURE }));
};

export default questions;
