import React from "react";
import PropTypes from 'prop-types';

import { connect } from "react-redux";

import { PractitionerLayoutAnonymous, PractitionerLayoutAsideAnonymous, PageTitle } from "@rdcs/dap-front-library";

import AppLayout from "../../../components/Layout/AppLayout";

import Content from './Content';

const TermsOfUsageScreen = ({ user }) => (
    <PageTitle title='Timkl - TermsOfUsage'>
        {user ? (
            <AppLayout navActive={null}>
                <Content user={user} />
            </AppLayout>
        ) : (
            <PractitionerLayoutAnonymous>
                <PractitionerLayoutAsideAnonymous />
                <Content user={user} />
            </PractitionerLayoutAnonymous>
        )}
    </PageTitle>
);

TermsOfUsageScreen.propTypes = {
    user: PropTypes.object,
};

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(TermsOfUsageScreen);
