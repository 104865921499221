import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { tryAddPotentialPatient, tryEditPotentialPatient, tryDeletePotentialPatient } from "../../actions/questions";
import API from "../../utils/API";
import { isLocalAPI } from "../../utils/utils";
import { BreadcrumbStep, DefaultLoader } from "@rdcs/dap-front-library";
import AppLayout from "../../components/Layout/AppLayout";
import BreakScoreQuestionnaireForm1 from "../../components/BreakScoreQuestionnaire/BreakScoreQuestionnaireForm1";
import BreakScoreQuestionnaireForm2 from "../../components/BreakScoreQuestionnaire/BreakScoreQuestionnaireForm2";
import store from "../../store";
import Step3 from "./Steps/Steps3";
import useState from "./useState";
import Questions from "../../actions/questions";

const { tryFetchBreakScoreNoInsulinQuestions } = Questions;

const breadcrumbs = [{ label: "Questionnaire de rupture" }];
const steps = ["Informations", "Questions", "Résultat"];

const BreakScoreQuestionnaireScreen = ({
    tryFetchBreakScoreNoInsulinQuestions,
    accessToken,
    questions: { breakScoreQuestions, breakScoreQuestionsLoading, createLoading, editLoading, deleteLoading },
    tryAddPotentialPatient,
    tryEditPotentialPatient,
}) => {
    const [{ loading, currentStep, isEligible, patient }, { load, prevStep, nextStepFrom0To1, nextStepFrom1To2 }] = useState(breakScoreQuestions.length === 0);

    const [isNonInsulinoQuestions, setNonInsulinoQuestions] = React.useState();
    const [isNonInsulino, setNonInsulino] = React.useState(false);
    const [isDoctor, setDoctor] = React.useState(false);
    const [rppsNumber, setRppsNumber] = React.useState("");

    React.useEffect(() => {
        const role = store.getState().auth.user.roles;
        if (role.includes("ROLE_DOCTOR")) {
            setDoctor(true);
            return API.request({
                token: accessToken,
                path: isLocalAPI
                    ? `/rpps_practitioners/${store.getState().auth.user.id}`
                    : `/dap-hcp-patient/api/v1/customers/practitioners/rpps/${store.getState().auth.user.id}`,
            })
                .then((response) => setRppsNumber(response["@id"]))
                .catch((error) => error.message);
        }
    }, []);

    React.useEffect(() => {
        if (loading && !breakScoreQuestionsLoading) {
            load();
        }
    }, [breakScoreQuestionsLoading]);

    React.useEffect(() => {
        tryFetchBreakScoreNoInsulinQuestions()
            .then((breakScoreNoInsulinQuestions) => {
                setNonInsulinoQuestions(breakScoreNoInsulinQuestions);
            })
            .catch(load);
    }, []);

    const onSubmit = React.useCallback(
        (responses) => {
            if (createLoading) {
                return;
            }
            const whichQuestions = isNonInsulino ? breakScoreQuestions : isNonInsulinoQuestions;
            const whichType = isNonInsulino ? "break-score" : "break-score-no-insulin";
            const answers = whichQuestions.map(({ label }, i) => {
                const responseKey = `response${i}`;
                const response = responses[responseKey];

                return {
                    values: [response],
                    question: label,
                };
            });

            const params = {
                ...patient,
                type: whichType,
                answers,
            };

            if (params.id) {
                delete params.id;
                delete params.filledBy;
            }
            tryAddPotentialPatient(params)
                .then((p) => nextStepFrom1To2(p, answers))
                .catch(() => nextStepFrom1To2(true, answers));
        },
        [patient, breakScoreQuestions, createLoading]
    );

    const onSave = React.useCallback(
        (data) => {
            if (isDoctor) {
                data.practitioner = rppsNumber;
            }
            return tryEditPotentialPatient(patient, data);
        },
        [patient]
    );

    const onDelete = React.useCallback(() => {
        if (patient.id) {
            tryDeletePotentialPatient(patient.id);
        }
    }, [patient.id]);

    const stepRender = React.useMemo(() => {
        if (loading) {
            return <DefaultLoader />;
        }

        switch (currentStep) {
            case 0:
                return <BreakScoreQuestionnaireForm1 onSubmit={nextStepFrom0To1} patient={patient} setNonInsulino={setNonInsulino} />;
            case 1:
                return (
                    <BreakScoreQuestionnaireForm2
                        onSubmit={onSubmit}
                        prevStep={prevStep}
                        questions={isNonInsulino ? breakScoreQuestions : isNonInsulinoQuestions}
                        isLoading={createLoading}
                        answers={patient.answers}
                    />
                );
            case 2:
                return (
                    <Step3
                        prevStep={prevStep}
                        onSubmit={console.log}
                        isEligible={isEligible}
                        isLoading={editLoading || deleteLoading}
                        accessToken={accessToken}
                        onSave={onSave}
                        onDelete={onDelete}
                        score={patient.score}
                        isDoctor={isDoctor}
                        patientId={patient.id}
                    />
                );
            default:
                return undefined;
        }
    }, [currentStep, loading, createLoading, onSubmit]);

    return (
        <AppLayout navActive={2} breadcrumbs={breadcrumbs}>
            <header className="headerPage">
                <div className="headerPage__title">
                    <h1 className="title-1">Questionnaire d'identification de nouveaux patients</h1>
                </div>
                <div className="headerPage__actions">
                    <BreadcrumbStep index={currentStep} steps={steps} />
                </div>
            </header>
            {stepRender}
        </AppLayout>
    );
};

BreakScoreQuestionnaireScreen.propTypes = {
    accessToken: PropTypes.string.isRequired,
    questions: PropTypes.object.isRequired,
    tryAddPotentialPatient: PropTypes.func.isRequired,
    tryEditPotentialPatient: PropTypes.func.isRequired,
    tryDeletePotentialPatient: PropTypes.func.isRequired,
    tryFetchBreakScoreNoInsulinQuestions: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { access_token: accessToken, user }, questions }) => ({ accessToken, user, questions });

const mapDispatchToProps = {
    tryAddPotentialPatient,
    tryEditPotentialPatient,
    tryDeletePotentialPatient,
    tryFetchBreakScoreNoInsulinQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(BreakScoreQuestionnaireScreen);
