import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { Icon, Modal } from "@rdcs/dap-front-library";

import PatientReportForm from "../PatientReportForm/index";

const PatientReport = ({ patient, mostRecentReport, previousReport }) => {
    const modalRef = React.useRef(null);
    const readModalRef = React.useRef(null);
    const { id } = useParams();

    return (
        <div className="headerPage__actions">
            <Modal
                reportModal
                buttonClass="button button__primaryButton d-flex timkl__color--blue-dark2 timkl__backgroundColor--violet-dark-important"
                buttonContent={
                    <>
                        <Icon size={20} name="add-circle-dot" />
                        <span className="ml-1">Lancer un entretien de suivi</span>
                    </>
                }
                containerClass="report__modal__container"
                ref={modalRef}
                className="report__modal__content"
                closeButtonClass="closeModalLarge"
            >
                <PatientReportForm
                    title="Lancer un entretien de suivi"
                    patientId={patient.id}
                    prevObjective={mostRecentReport?.objective}
                    onSubmit={() => {
                        modalRef.current.toggleModal();
                    }}
                    onOpenPreviousInterview={() => {
                        modalRef.current.toggleModal();
                        readModalRef.current.toggleModal();
                    }}
                />
            </Modal>
            {previousReport && <Modal
                reportModal
                containerClass="report__modal__container"
                className="report__modal__content"
                closeButtonClass="closeModalLarge"
                onClose={() => {
                    modalRef.current.toggleModal();
                }}
                ref={readModalRef}
            >
            <PatientReportForm
                title="Lancer un entretien de suivi"
                patientId={previousReport.patient["@id"]}
                report={previousReport}
                edition
                canModify={false}
                onSubmit={() => {
                    readModalRef.current.toggleModal();
                }}
            />
            </Modal>}
        </div>
    );
};

const mapStateToProps = ({ reports: { list: reports } }) => ({
    previousReport: reports?.sort((a, b) => new Date(b.date) - new Date(a.date))[0] ?? null
});
  
export default connect(mapStateToProps)(PatientReport);