import * as types from "./types";

import { toast } from "react-toastify";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from '../store';

const getReport = (access_token, patientId) => (
    API.request({
        path: isLocalAPI ? "/reports" : "/dap-hcp-patient/api/v1/customers/patients/reports",
        queryParams: {
            patient: patientId
        },
        token: access_token
    })
);

export const tryFetchReports = patientId => dispatch => {
    const {
        auth: {
            access_token,
        },
        reports: {
            list,
            listLoaded,
            listPatientId
        }
    } = store.getState();

    if (listLoaded && listPatientId === patientId) {
        return Promise.resolve(list);
    }

    dispatch({ type: types.REPORTS_FETCH_REQUESTED });
    return getReport(access_token, patientId)
        .then(response => dispatch({ type: types.REPORTS_FETCH_SUCCESS, data: response["hydra:member"], patientId }))
        .catch(error => dispatch({ type: types.REPORTS_FETCH_FAILURE, data: error }))
    ;
};

export const tryCreateReport = data => dispatch => {
    const {
        auth: {
            access_token,
        },
    } = store.getState();

    const thematics = data.thematics.map(thematic => thematic["@id"]);

    const patientPointOfAttentions = data.patientPointOfAttentions.map(item => ({
        pointOfAttention: item.pointOfAttention["@id"]
    }));

    dispatch({ type: types.REPORTS_CREATE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/reports" : "/dap-hcp-patient/api/v1/customers/patients/reports",
        method: "POST",
        token: access_token,
        data: {
            ...data,
            thematics,
            patientPointOfAttentions : patientPointOfAttentions
        }
    })
    .then(response => dispatch({ type: types.REPORTS_CREATE_SUCCESS, data: response }))
    .catch(error => dispatch({ type: types.REPORTS_CREATE_FAILURE, data: error }));
};

export const tryEditReport = (id, data) => dispatch => {

    const thematics = data.thematics.map(thematic => thematic["@id"]);

    const patientPointOfAttentions = data.patientPointOfAttentions.map(item => ({
        pointOfAttention: item.pointOfAttention["@id"]
    }));

    const {
        auth: {
            access_token,
        },
    } = store.getState();

    dispatch({ type: types.EDIT_REPORT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/reports/{id}" : "/dap-hcp-patient/api/v1/customers/patients/reports/{id}",
        uriParams: {
            id
        },
        token: access_token,
        method: "PUT",
        data: {
            ...data, 
            thematics,
            patientPointOfAttentions : patientPointOfAttentions
        }
    })
    .then(({ patient: { id } }) => {
        toast.success("Le compte-rendu a été modifié");
        dispatch({type : types.EDIT_REPORT_SUCCESS})
    })
    .catch(error => {
        toast.error("Le compte-rendu n'a pas pu être modifiée");
        dispatch({ type: types.EDIT_REPORT_FAILURE, data: error });
        throw error;
    })
};

export const tryRemoveReport = id => dispatch => {
    const {
        auth: {
            access_token,
        },
    } = store.getState();

    dispatch({ type: types.REPORT_REMOVE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/reports/{id}" : "/dap-hcp-patient/api/v1/customers/patients/reports/{id}",
        uriParams: {
            id
        },
        token: access_token,
        method: "PUT",
        data: { active: false }
    })
    .then(response => dispatch({ type: types.REPORT_REMOVE_SUCCESS, data: response }))
    .catch(error => dispatch({ type: types.REPORT_REMOVE_FAILURE, data: error }));
};
