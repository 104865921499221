import React from 'react';
import PropTypes from 'prop-types';

const RecoDescription = ({
    explanations,
    description,
}) => (
    <>
        {explanations &&
        <p className="checkbox__explanation">
            Critère(s) impliquant la génération de cette recommandation : {Object.values(explanations || {}).join(', ')}
        </p>}
        <p>{description}</p>
    </>
);

RecoDescription.propTypes = {
    explanations: PropTypes.object,
    description: PropTypes.string,
};

export default React.memo(RecoDescription);