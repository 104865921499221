import * as types from "./types";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from '../store';

export const tryFetchMeasures = (patientId = null) => dispatch => {
    const {
        auth: {
            access_token,
        },
        measures: {
            list,
            listPatientId,
            listLoaded,
        }
    } = store.getState();

    if (listLoaded && listPatientId === patientId) {
        return Promise.resolve(list);
    }
    dispatch({ type: types.MEASURES_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/measures" : "/dap-hcp-patient/api/v1/clinical-data/measures",
        queryParams: {
            "order[date]": "asc",
            pagination: false,
            "patient.id": patientId
        },
        token: access_token
    })
    .then(response => {
        dispatch({ type: types.MEASURES_FETCH_SUCCESS, data: response["hydra:member"], patientId });
        return response["hydra:member"];
    })
    .catch(error => dispatch({ type: types.MEASURES_FETCH_FAILURE, data: error }));
}

export const tryCreateMeasure = data => dispatch => {
    const {
        auth: {
            access_token,
        },
    } = store.getState();

    dispatch({ type: types.MEASURES_CREATE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/measures" : "/dap-hcp-patient/api/v1/clinical-data/measures",
        method: "POST",
        token: access_token,
        data: data
    })
    .then(response => {
        dispatch({ type: types.MEASURES_CREATE_SUCCESS, data: response });
        return response;
    })
    .catch(error => dispatch({ type: types.MEASURES_CREATE_FAILURE, data: error }));
}

export const tryFetchMeasureTypes = () => dispatch => {
    const {
        auth: {
            access_token,
        },
        measures: {
            listMeasureTypes,
            listMeasureTypesLoaded,
        }
    } = store.getState();

    if (listMeasureTypesLoaded) {
        return Promise.resolve(listMeasureTypes);
    }    
    dispatch({ type: types.MEASURE_TYPES_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/measure_types" : "/dap-hcp-patient/api/v1/clinical-data/measure-types",
        token: access_token
    })
    .then(response => {
        dispatch({ type: types.MEASURE_TYPES_FETCH_SUCCESS, data: response["hydra:member"] });
        return response["hydra:member"];
    })
    .catch(error => {
        dispatch({ type: types.MEASURE_TYPES_FETCH_FAILURE, data: error });
        throw error;
    });
}
