import * as types from "../actions/types";
import { toast } from "react-toastify";

const initialState = {
    medicalPatient: null,
    medicalPatientPatientId: -1,
    medicalPatientLoaded: 0,
    medicalPatientLoading: false,

    medicalPatients: {},
    medicalPatientsLoaded: 0,
    medicalPatientsLoading: false,
    medicalPatientsCount: false,

    patientCreated: false,
    patientCreateError: false,
    patientCreateLoading: false,

    searchPatient: [],
    searchPatientLoading: false,

    singlePatientLoading: false,
    singlePatient: null,
    singlePatientLoaded: 0,
    singlePatientId: -1,

    patientEdited: false,
    patientEditLoading: false,
    patientEditError: false,

    patientAdded: false,
    patientAddLoading: false,

    createAppointmentLoading: false,
    createAppointmentError: false,

    editAppointmentLoading: false,
    editAppointmentError: false,

    deleteAppointmentLoading: false,
    deleteAppointmentError: false,

    patientAppointments: [],
    patientAppointmentsLoaded: 0,
    patientAppointmentsPatientId: -1,
    patientAppointmentsLoading: false,

    patientTasks: [],
    patientTasksLoaded: 0,
    patientTasksPatientId: -1,
    patientTasksLoading: false,

    createTaskLoading: false,

    taskLabels: [],
    taskLabelsLoaded: 0,
    taskLabelsLoading: false,

    potentialPatient: {},
    potentialPatientLoading: false,
    potentialPatientError: false,
};

function patients(state = initialState, action) {
    switch (action.type) {
        // SEARCH
        case types.SEARCH_PATIENT_REQUESTED: {
            return Object.assign({}, state, { searchPatientLoading: true });
        }
        case types.SEARCH_PATIENT_SUCCESS: {
            return Object.assign({}, state, {
                searchPatientLoading: false,
                searchPatient: action.data,
            });
        }
        case types.SEARCH_PATIENT_FAILURE: {
            return Object.assign({}, state, {
                searchPatientLoading: false,
                searchPatient: [],
            });
        }
        // FETCH MEDICAL PATIENT
        case types.MEDICAL_PATIENT_REQUESTED: {
            return Object.assign({}, state, {
                medicalPatientLoading: true,
                medicalPatientLoaded: 0,
                medicalPatientPatientId: -1,
            });
        }
        case types.MEDICAL_PATIENT_SUCCESS: {
            return Object.assign({}, state, {
                medicalPatientLoading: false,
                medicalPatient: action.data,
                medicalPatientLoaded: Date.now(),
                medicalPatientPatientId: action.patientId,
            });
        }
        case types.MEDICAL_PATIENT_FAILURE: {
            return Object.assign({}, state, {
                medicalPatientLoading: false,
                medicalPatient: null,
            });
        }
        // FETCH MEDICAL PATIENTS
        case types.MEDICAL_PATIENTS_REQUESTED: {
            return Object.assign({}, state, {
                medicalPatientsLoading: true,
            });
        }
        case types.MEDICAL_PATIENTS_SUCCESS: {
            return Object.assign({}, state, {
                medicalPatientsLoading: false,
                medicalPatientsCount: action.data["hydra:totalItems"],
                medicalPatientsLoaded: state.medicalPatientsLoaded || Date.now(),
                medicalPatients: {
                    ...(action.datasExpired ? {} : state.medicalPatients),
                    [action.page]: action.data["hydra:member"],
                },
            });
        }
        case types.MEDICAL_PATIENTS_FAILURE: {
            return Object.assign({}, state, {
                medicalPatientsLoading: false,
            });
        }
        // FETCH SINGLE PATIENT
        case types.SINGLE_PATIENT_REQUESTED: {
            return Object.assign({}, state, {
                singlePatientLoading: true,
                singlePatientLoaded: 0,
                singlePatientId: -1,
            });
        }
        case types.SINGLE_PATIENT_SUCCESS: {
            return Object.assign({}, state, {
                singlePatientLoading: false,
                singlePatient: action.data,
                singlePatientLoaded: Date.now(),
                singlePatientId: action.id,
            });
        }
        case types.SINGLE_PATIENT_FAILURE: {
            return Object.assign({}, state, {
                singlePatientLoading: false,
                singlePatient: null,
            });
        }
        // CREATE
        case types.PATIENTS_CREATE_REQUESTED: {
            return Object.assign({}, state, {
                patientCreateLoading: true,
                patientCreated: false,
            });
        }
        case types.PATIENTS_CREATE_SUCCESS: {
            toast.success("Le patient va recevoir un email afin de finaliser son inscription", { autoClose: 5000 });
            return Object.assign({}, state, {
                patientCreateLoading: false,
                patientCreateError: false,
                patientCreated: action.data,
            });
        }
        case types.PATIENTS_CREATE_FAILURE: {
            toast.error("Le patient n'a pas pu être créé");
            return Object.assign({}, state, {
                patientCreateLoading: false,
                patientCreateError: action.data,
                patientCreated: false,
            });
        }
        // EDIT
        case types.PATIENTS_EDIT_REQUESTED: {
            return Object.assign({}, state, {
                patientEditLoading: true,
                patientEdited: false,
                editErrors: {},
            });
        }
        case types.PATIENTS_EDIT_SUCCESS: {
            const medicalPatients = {};
            const medicalPatient = { ...state.medicalPatient, patient: action.data };

            for (const page in medicalPatients) {
                medicalPatients[page] = state.medicalPatients[page].map((_medicalPatient) =>
                    _medicalPatient.patient.id === action.data.id ? medicalPatient : _medicalPatient
                );
            }

            toast.success("Le profil du patient a été modifié");
            return Object.assign({}, state, {
                patientEditLoading: false,
                patientEditError: false,
                patientEdited: true,
                singlePatient: action.data,
                medicalPatients,
                medicalPatient,
            });
        }
        case types.PATIENTS_EDIT_FAILURE: {
            toast.error("Le profil du patient n'a pas pu être modifié");
            return Object.assign({}, state, {
                patientEditLoading: false,
                patientEditError: action.data,
                patientEdited: false,
            });
        }
        // ADD PATIENT TO MEDICAL
        case types.MEDICAL_PATIENT_CREATE_REQUESTED: {
            return Object.assign({}, state, {
                patientAddLoading: true,
                patientAdded: false,
            });
        }
        case types.MEDICAL_PATIENT_CREATE_SUCCESS: {
            toast.success("Le patient va recevoir un email afin d'accepter de vous partager ses données médicales", {
                autoClose: 5000,
            });

            return Object.assign({}, state, {
                patientAddLoading: false,
                patientAdded: action.data.patient,
                medicalPatientLoaded: 0,
            });
        }
        case types.MEDICAL_PATIENT_CREATE_FAILURE: {
            if (action.data?.body["hydra:description"]?.includes("already")) {
                toast.error("Ce patient figure déjà dans votre liste de patients.");
            } else {
                toast.error("Le patient n'a pas pu être ajouté");
            }
            return Object.assign({}, state, {
                patientAddLoading: false,
                patientAdded: false,
            });
        }
        // DELETE
        case types.MEDICAL_PATIENT_DELETE_REQUESTED: {
            return Object.assign({}, state, { patientDeleteLoading: true });
        }
        case types.MEDICAL_PATIENT_DELETE_SUCCESS: {
            toast.success("Le patient a été retiré");
            return Object.assign({}, state, {
                patientDeleteLoading: false,
                medicalPatientLoaded: 0,
            });
        }
        case types.MEDICAL_PATIENT_DELETE_FAILURE: {
            toast.error("Le patient n'a pas pu être retiré");
            return Object.assign({}, state, { patientDeleteLoading: false });
        }

        // Fetch patient appointments
        case types.FETCH_PATIENT_APPOINTMENTS_REQUESTED: {
            return Object.assign({}, state, {
                patientAppointmentsLoading: true,
                patientAppointmentsLoaded: 0,
                patientAppointmentsPatientId: -1,
            });
        }
        case types.FETCH_PATIENT_APPOINTMENTS_SUCCESS: {
            return Object.assign({}, state, {
                patientAppointmentsLoading: false,
                patientAppointments: action.data,
                patientAppointmentsLoaded: Date.now(),
                patientAppointmentsPatientId: action.patientId,
            });
        }
        case types.FETCH_PATIENT_APPOINTMENTS_FAILURE: {
            return Object.assign({}, state, {
                patientAppointmentsLoading: false,
            });
        }
        // Create patient appointment
        case types.CREATE_PATIENT_APPOINTMENT_REQUESTED: {
            return Object.assign({}, state, {
                createAppointmentLoading: true,
                createAppointmentError: false,
            });
        }
        case types.CREATE_PATIENT_APPOINTMENT_SUCCESS: {
            toast.success("Le rendez-vous a été créé");
            return Object.assign({}, state, {
                createAppointmentLoading: false,
                patientAppointments: [...state.patientAppointments, action.data],
            });
        }
        case types.CREATE_PATIENT_APPOINTMENT_FAILURE: {
            toast.error("Le rendez-vous n'a pas pu être créé");
            return Object.assign({}, state, {
                createAppointmentLoading: false,
                createAppointmentError: action.data,
            });
        }
        // Edit patient appointment
        case types.EDIT_PATIENT_APPOINTMENT_REQUESTED: {
            return Object.assign({}, state, {
                editAppointmentLoading: true,
                editAppointmentError: false,
            });
        }
        case types.EDIT_PATIENT_APPOINTMENT_SUCCESS: {
            toast.success("Le rendez-vous a été modifié");
            return Object.assign({}, state, {
                editAppointmentLoading: false,
                patientAppointments: state.patientAppointments.map((item) => (item["@id"] === action.data["@id"] ? action.data : item)),
            });
        }

        case types.EDIT_PATIENT_APPOINTMENT_FAILURE: {
            toast.error("Le rendez-vous n'a pas pu être modifié");
            return Object.assign({}, state, {
                editAppointmentLoading: false,
                editAppointmentError: action.data,
            });
        }
        // Remove patient appointment
        case types.DELETE_PATIENT_APPOINTMENT_REQUESTED: {
            return Object.assign({}, state, { deleteAppointmentLoading: true });
        }
        case types.DELETE_PATIENT_APPOINTMENT_SUCCESS: {
            toast.success("Le rendez-vous a été supprimé");
            return Object.assign({}, state, {
                deleteAppointmentLoading: false,
            });
        }
        case types.DELETE_PATIENT_APPOINTMENT_FAILURE: {
            toast.error("Le rendez-vous n'a pas pu être supprimé");
            return Object.assign({}, state, {
                deleteAppointmentLoading: false,
            });
        }
        // Fetch patient tasks
        case types.FETCH_PATIENT_TASKS_REQUESTED: {
            return Object.assign({}, state, {
                patientTasksLoading: true,
                patientTasksLoaded: 0,
                patientTasksPatientId: -1,
            });
        }
        case types.FETCH_PATIENT_TASKS_SUCCESS: {
            return Object.assign({}, state, {
                patientTasksLoading: false,
                patientTasks: action.data,
                patientTasksLoaded: Date.now(),
                patientTasksPatientId: action.patientId,
            });
        }
        case types.FETCH_PATIENT_TASKS_FAILURE: {
            return Object.assign({}, state, {
                patientTasksLoading: false,
            });
        }
        // Create patient task
        case types.CREATE_PATIENT_TASK_REQUESTED: {
            return Object.assign({}, state, {
                createTaskLoading: true,
                createTaskError: false,
            });
        }
        case types.CREATE_PATIENT_TASK_SUCCESS: {
            toast.success("La tâche a été créée");
            return Object.assign({}, state, {
                createTaskLoading: false,
                patientTasks: [...state.patientTasks, action.data],
            });
        }
        case types.CREATE_PATIENT_TASK_FAILURE: {
            toast.error("La tâche n'a pas pu être créée");
            return Object.assign({}, state, {
                createTaskLoading: false,
                createTaskError: action.data,
            });
        }
        // Edit patient task
        case types.EDIT_PATIENT_TASK_REQUESTED: {
            return Object.assign({}, state, {
                editTaskLoading: true,
                editTaskError: false,
            });
        }
        case types.EDIT_PATIENT_TASK_SUCCESS: {
            toast.success("La tâche a été modifiée");
            return Object.assign({}, state, {
                editTaskLoading: false,
                patientTasks: state.patientTasks.map((item) => (item["@id"] === action.data["@id"] ? action.data : item)),
            });
        }
        case types.EDIT_PATIENT_TASK_FAILURE: {
            toast.error("La tâche n'a pas pu être modifiée");
            return Object.assign({}, state, {
                editTaskLoading: false,
                editTaskError: action.data,
            });
        }
        // Fetch patient tasks
        case types.FETCH_TASK_LABELS_REQUESTED: {
            return Object.assign({}, state, {
                taskLabelsLoading: true,
                taskLabelsLoaded: 0,
            });
        }
        case types.FETCH_TASK_LABELS_SUCCESS: {
            return Object.assign({}, state, {
                taskLabelsLoading: false,
                taskLabels: action.data,
                taskLabelsLoaded: Date.now(),
            });
        }
        case types.FETCH_TASK_LABELS_FAILURE: {
            return Object.assign({}, state, {
                taskLabelsLoading: false,
            });
        }

        // Call when new diagnosis is established
        case types.DIAGNOSIS_EDIT_SUCCESS: {
            return Object.assign({}, state, {
                singlePatient: {
                    ...state.singlePatient,
                    lastDiagnostic: {
                        "@id": action.data["@id"],
                        "@type": action.data["@type"],
                        pathology: action.data.pathology,
                        treatmentGroup: action.data.treatmentGroup["@id"],
                        goal: action.data.goal,
                        createdAt: action.data.createAt,
                        updatedAt: action.data.updatedAt,
                    },
                },
                medicalPatient: {
                    ...state.medicalPatient,
                    patient: {
                        ...state.medicalPatient.patient,
                        lastDiagnostic: {
                            ...state.medicalPatient.patient.lastDiagnostic,
                            "@id": action.data["@id"],
                            id: action.data.id,
                            pathology: action.data.pathology,
                        },
                    },
                },
            });
        }

        case types.POTENTIAL_PATIENT_FETCH_REQUESTED: {
            return Object.assign({}, state, { potentialPatientLoading: true, potentialPatientError: false });
        }
        case types.POTENTIAL_PATIENT_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                potentialPatientLoading: false,
                potentialPatient: action.data,
            });
        }
        case types.POTENTIAL_PATIENT_FETCH_FAILURE: {
            return Object.assign({}, state, {
                potentialPatientLoading: false,
                potentialPatient: null,
                potentialPatientError: action.data,
            });
        }

        // DEFAULT
        default:
            return state;
    }
}

export default patients;
