import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import {
    AppointmentRealised,
    AppointmentRecommended,
    AppointmentScheduled,
    DefaultLoader,
    Icon,
    PageTitle,
} from "@rdcs/dap-front-library";

import { tryFetchDiagnosisRecos } from "../../../actions/diagnosis";
import {
    tryCreatePatientAppointment,
    tryEditPatientAppointment,
    tryFetchPatientAppointments,
} from "../../../actions/patients";
import { tryFetchPractitioners } from "../../../actions/practitioners";
import { userCan } from "../../../reducers/auth";
import moment from "moment";

import PatientNavLayout from "../../../components/Patients/PatientNavLayout";

const PatientsNavConsultsScreen = ({
    diagnosis,
    patients,
    tryCreatePatientAppointment,
    tryEditPatientAppointment,
    tryFetchDiagnosisRecos,
    tryFetchPatientAppointments,
    tryFetchPractitioners,
}) => {
    const { id } = useParams();

    const patientId = React.useMemo(() => parseInt(id), [id]);

    const [patientAppointmentsLoading, setPatientAppointmentsLoading] = React.useState(true);
    const [medicalPatientLoading, setMedicalPatientLoading] = React.useState(true);

    const onLoad = React.useCallback((medicalPatient) => {
        tryFetchDiagnosisRecos(medicalPatient.patient.lastDiagnostic).finally(() => setMedicalPatientLoading(false));
    }, []);

    React.useEffect(() => {
        tryFetchPatientAppointments(patientId).finally(() => setPatientAppointmentsLoading(false));
    }, []);

    /* VISITS */
    // Retrieve all consultation's appointments.
    const consultAppointments = React.useMemo(
        () =>
            patients.patientAppointments.filter(({ recommendations }) =>
                recommendations.find(({ type }) => type === "consultation")
            ),
        [patients.patientAppointments]
    );

    // Retrieve all consultation's appointments done.
    const appointmentsDone = React.useMemo(
        () =>
            consultAppointments
                .filter(({ doneAt }) => doneAt !== null)
                .sort((a, b) => new Date(a.doneAt) - new Date(b.doneAt)),
        [consultAppointments]
    );

    // Retrieve all consultation's recommendations.
    const recoConsults = React.useMemo(() => {
        const now = moment().unix();

        return (
            diagnosis.recos
                // Keep consultations only
                .filter(({ recommendation: { type } }) => type === "consultation")
                // Bind Diagnostic recos with appointments
                .map((reco) => {
                    for (const consultAppointment of consultAppointments) {
                        if (
                            consultAppointment.recommendations.find(
                                (recommendation) => recommendation.id === reco.recommendation.id
                            )
                        ) {
                            return {
                                ...reco,
                                statusColor: "green",
                                appointment: consultAppointment,
                            };
                        }
                    }
                    return { ...reco, statusColor: now < moment(reco.dueAt).unix() ? "orange" : "red" };
                })
                // Remove all consultation done.
                .filter(({ appointment = null }) => !appointment?.doneAt)
                // Sort by color and after, sort by dueAt
                .sort((a, b) => {
                    const codeA = a.statusColor.charCodeAt(0);
                    const codeB = b.statusColor.charCodeAt(0);

                    if (codeA !== codeB) {
                        return codeB - codeA;
                    }
                    return new Date(a.appointment?.date || a.dueAt) - new Date(b.appointment?.date || b.dueAt);
                })
        );
    }, [diagnosis.recos, consultAppointments]);

    const [recoConsultsMustPlan, recoConsultsPlanned] = React.useMemo(() => {
        const recoConsultsMustPlanLocal = [];
        const recoConsultsPlannedLocal = [];

        for (const recoConsult of recoConsults) {
            if (recoConsult.statusColor === "green") {
                recoConsultsPlannedLocal.push(recoConsult);
            } else {
                recoConsultsMustPlanLocal.push(recoConsult);
            }
        }
        return [recoConsultsMustPlanLocal, recoConsultsPlannedLocal];
    }, [recoConsults]);

    return (
        <PageTitle title="Timkl - Mes Patients">
            <PatientNavLayout patientId={patientId} onLoad={onLoad}>
                <>
                    <div className="contentHolder__title">
                        <p className="txtSizeSmall text-gray">
                            Liste de toutes les visites que le patient doit planifier.
                        </p>
                    </div>
                    <div className="consultsList">
                        {medicalPatientLoading ? (
                            <DefaultLoader />
                        ) : (
                            recoConsultsMustPlan.map((recoInstance) => (
                                <AppointmentRecommended
                                    key={recoInstance.id}
                                    dueAt={recoInstance.dueAt}
                                    statusColor={recoInstance.statusColor}
                                    recommendation={recoInstance.recommendation}
                                    showDescription={true}
                                    exams={diagnosis.recos.filter(
                                        (r) => r.recommendation.consultationName === recoInstance.recommendation.title
                                    )}
                                    patientIri={"/patients/" + patientId}
                                    searchPractitionerAction={tryFetchPractitioners}
                                    createAppointmentLoading={patients.createAppointmentLoading}
                                    createAppointmentAction={tryCreatePatientAppointment}
                                    disableCreateAppointment={userCan("ROLE_DOCTOR")}
                                />
                            ))
                        )}
                    </div>
                    <br />
                    <br />
                    {/* Visites planifiées */}
                    <div className="contentHolder__title form__group">
                        <Icon name="stats" className="contentHolder__title-icon" />
                        <h1 className="title-1">Visites planifiées</h1>
                    </div>
                    {patientAppointmentsLoading ? (
                        <DefaultLoader />
                    ) : (
                        <>
                            <div className="consultsList">
                                {recoConsultsPlanned.map(({ appointment }) => (
                                    <AppointmentScheduled
                                        key={appointment.id}
                                        appointment={appointment}
                                        confirmAppointmentLoading={patients.editAppointmentLoading}
                                        confirmAppointmentAction={tryEditPatientAppointment}
                                    />
                                ))}
                            </div>
                            <br />
                            <br />
                            <div className="contentHolder__title form__group">
                                <Icon name="stats" className="contentHolder__title-icon" />
                                <h1 className="title-1">Visites réalisées</h1>
                            </div>
                            <div className="consultsList">
                                {appointmentsDone.map((appointment) => (
                                    <AppointmentRealised key={appointment.id} appointment={appointment} />
                                ))}
                            </div>
                        </>
                    )}
                </>
            </PatientNavLayout>
        </PageTitle>
    );
};

PatientsNavConsultsScreen.propTypes = {
    diagnosis: PropTypes.object.isRequired,
    patients: PropTypes.object.isRequired,
    tryCreatePatientAppointment: PropTypes.func.isRequired,
    tryEditPatientAppointment: PropTypes.func.isRequired,
    tryFetchDiagnosisRecos: PropTypes.func.isRequired,
    tryFetchPatientAppointments: PropTypes.func.isRequired,
    tryFetchPractitioners: PropTypes.func.isRequired,
};

const mapStateToProps = ({ diagnosis, patients }) => ({ diagnosis, patients });

const mapDispatchToProps = {
    tryCreatePatientAppointment,
    tryEditPatientAppointment,
    tryFetchDiagnosisRecos,
    tryFetchPatientAppointments,
    tryFetchPractitioners,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientsNavConsultsScreen);
