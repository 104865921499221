import PropTypes from "prop-types";
import { userCan } from "../../reducers/auth";

const UserCan = ({ children, roles }) => (userCan(roles) ? children : null);

UserCan.propTypes = {
    roles: PropTypes.array.isRequired,
    children: PropTypes.node
};

export default UserCan;
