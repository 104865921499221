import * as types from "./types";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import { userRole } from "../reducers/auth";
import moment from "moment";

import store from "../store";

export const trySearchPatient = (searchParams) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.SEARCH_PATIENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/patients" : "/dap-hcp-patient/api/v1/customers/patients",
        queryParams: searchParams,
        token: access_token,
    })
        .then((response) =>
            dispatch({
                type: types.SEARCH_PATIENT_SUCCESS,
                data: response["hydra:member"],
            })
        )
        .catch((error) => dispatch({ type: types.SEARCH_PATIENT_FAILURE, data: error }));
};

const medicalPatientsExpired = (medicalPatientsLoaded) => !medicalPatientsLoaded;

export const tryMedicalPatient = (id) => (dispatch) => {
    const {
        auth: { access_token },
        patients: { medicalPatient, medicalPatientLoaded, medicalPatientPatientId, medicalPatientsLoaded, medicalPatients },
    } = store.getState();
    id = parseInt(id);

    return new Promise((resolve, reject) => {
        if (medicalPatientLoaded && medicalPatientPatientId === id) {
            return resolve(medicalPatient);
        }

        if (!medicalPatientsExpired(medicalPatientsLoaded)) {
            for (const page in medicalPatients) {
                const localMedicalPatient = medicalPatients[page].find(({ patient }) => patient.id === id);

                if (localMedicalPatient !== undefined) {
                    dispatch({
                        type: types.MEDICAL_PATIENT_SUCCESS,
                        data: localMedicalPatient,
                        patientId: id,
                    });
                    resolve(localMedicalPatient);
                }
            }
        }
        reject();
    }).catch(() => {
        dispatch({ type: types.MEDICAL_PATIENT_REQUESTED });
        return API.request({
            path: isLocalAPI ? `/access_rights` : `/dap-hcp-patient/api/v1/ciam/access/rights`,
            token: access_token,
            queryParams: {
                patient: id,
            },
        })
            .then((response) => {
                if (response["hydra:totalItems"] !== 1) {
                    throw new Error("Patient not found !");
                }
                dispatch({
                    type: types.MEDICAL_PATIENT_SUCCESS,
                    data: response["hydra:member"][0],
                    patientId: id,
                });
                return response["hydra:member"][0];
            })
            .catch((error) => {
                dispatch({ type: types.MEDICAL_PATIENT_FAILURE });
                throw error;
            });
    });
};

export const itemsPerPage = 8;

export const tryMedicalPatients = (page) => (dispatch) => {
    let datasExpired = false;

    const {
        auth: { access_token },
        patients: { medicalPatientsLoaded, medicalPatients },
    } = store.getState();

    if (medicalPatientsExpired(medicalPatientsLoaded)) {
        datasExpired = true;
    } else if (medicalPatients[page] !== undefined) {
        return Promise.resolve(medicalPatients[page]);
    }

    dispatch({ type: types.MEDICAL_PATIENTS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/access_rights" : "/dap-hcp-patient/api/v1/ciam/access/rights",
        token: access_token,
        queryParams: { itemsPerPage, page },
    })
        .then((response) => {
            dispatch({
                type: types.MEDICAL_PATIENTS_SUCCESS,
                data: response,
                page,
                datasExpired,
            });
            return response["hydra:member"];
        })
        .catch((error) => {
            dispatch({ type: types.MEDICAL_PATIENTS_FAILURE, data: error });
            throw error;
        });
};

export const tryCreatePatient = (data) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.PATIENTS_CREATE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/patients" : "/dap-hcp-patient/api/v1/customers/patients",
        method: "POST",
        token: access_token,
        data: data,
        patientCreationByHCP: true,
    })
        .then((response) => {
            dispatch({ type: types.PATIENTS_CREATE_SUCCESS, data: response });
            return response;
        })
        .catch((error) => {
            dispatch({ type: types.PATIENTS_CREATE_FAILURE, data: error });
            throw error;
        });
};

export const tryEditPatient = (patientId, data) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.PATIENTS_EDIT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/patients/{id}" : "/dap-hcp-patient/api/v1/customers/patients/{id}",
        uriParams: {
            id: patientId,
        },
        method: "PUT",
        token: access_token,
        data: data,
    })
        .then((response) => {
            dispatch({ type: types.PATIENTS_EDIT_SUCCESS, data: response });
            return response;
        })
        .catch((error) => {
            dispatch({ type: types.PATIENTS_EDIT_FAILURE, data: error });
            throw error;
        });
};

export const tryMedicalPatientCreate = (patient) => (dispatch) => {
    const url = `/${userRole()}/`;

    const {
        auth: {
            access_token,
            user: { id },
        },
    } = store.getState();

    dispatch({ type: types.MEDICAL_PATIENT_CREATE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/access_rights" : "/dap-hcp-patient/api/v1/ciam/access/rights",
        method: "POST",
        token: access_token,
        data: {
            patient: "/patients/" + patient.id,
            user: url + id,
            rights: "rw",
        },
    })
        .then((response) => {
            dispatch({
                type: types.MEDICAL_PATIENT_CREATE_SUCCESS,
                data: response,
            });
            return response;
        })
        .catch((error) => {
            dispatch({
                type: types.MEDICAL_PATIENT_CREATE_FAILURE,
                data: error,
            });
            throw error;
        });
};

export const trySinglePatient = (id) => (dispatch) => {
    const {
        auth: { access_token },
        patients: { singlePatient, singlePatientId, singlePatientLoaded },
    } = store.getState();

    if (singlePatientLoaded && singlePatientId === id) {
        return Promise.resolve(singlePatient);
    }

    dispatch({ type: types.SINGLE_PATIENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/patients/{id}" : "/dap-hcp-patient/api/v1/customers/patients/{id}",
        uriParams: {
            id,
        },
        token: access_token,
    })
        .then((response) => {
            dispatch({ type: types.SINGLE_PATIENT_SUCCESS, data: response, id });
            return response;
        })
        .catch((error) => dispatch({ type: types.SINGLE_PATIENT_FAILURE, data: error }));
};

export const tryMedicalPatientDelete = (accessRight) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.MEDICAL_PATIENT_DELETE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/access_rights/{id}" : "/dap-hcp-patient/api/v1/ciam/access/rights/{id}",
        uriParams: {
            id: accessRight.id,
        },
        method: "DELETE",
        token: access_token,
    })
        .then(() =>
            dispatch({
                type: types.MEDICAL_PATIENT_DELETE_SUCCESS,
                data: accessRight,
            })
        )
        .catch((error) =>
            dispatch({
                type: types.MEDICAL_PATIENT_DELETE_FAILURE,
                data: error,
            })
        );
};

export const tryFetchPatientAppointments = (patientId) => (dispatch) => {
    const {
        auth: { access_token },
        patients: { patientAppointments, patientAppointmentsLoaded, patientAppointmentsPatientId },
    } = store.getState();

    if (patientAppointmentsLoaded && patientAppointmentsPatientId === patientId) {
        return Promise.resolve(patientAppointments);
    }

    dispatch({ type: types.FETCH_PATIENT_APPOINTMENTS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/appointments" : "/dap-hcp-patient/api/v1/customers/patients/appointments",
        queryParams: {
            patient: patientId,
        },
        token: access_token,
    })
        .then((response) =>
            dispatch({
                type: types.FETCH_PATIENT_APPOINTMENTS_SUCCESS,
                data: response["hydra:member"],
                patientId,
            })
        )
        .catch((error) =>
            dispatch({
                type: types.FETCH_PATIENT_APPOINTMENTS_FAILURE,
                data: error,
            })
        );
};

export const tryCreatePatientAppointment = (appointment) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    const aDate = moment(appointment.date);
    const actualDate = moment(new Date());

    if (!aDate.isAfter(actualDate)) {
        appointment.doneAt = appointment.date;
    } else {
        appointment.doneAt = null;
    }

    dispatch({ type: types.CREATE_PATIENT_APPOINTMENT_REQUESTED });
    const requestData =
        appointment.practitioner === undefined
            ? {
                  recommendations: appointment.recommendations,
                  patient: appointment.patientIri,
                  date: appointment.date,
                  topic: appointment.topic,
                  doneAt: appointment.doneAt,
              }
            : {
                  recommendations: appointment.recommendations,
                  patient: appointment.patientIri,
                  practitioner: appointment.practitioner["@id"],
                  date: appointment.date,
                  topic: appointment.topic,
                  doneAt: appointment.doneAt,
              };
    return API.request({
        path: isLocalAPI ? "/appointments" : "/dap-hcp-patient/api/v1/customers/patients/appointments",
        method: "POST",
        token: access_token,
        data: requestData,
    })
        .then((response) => {
            dispatch({
                type: types.CREATE_PATIENT_APPOINTMENT_SUCCESS,
                data: response,
            });
            const patientId = appointment.patientIri?.match(/\d+/)?.[0];

            if (patientId) {
                dispatch(tryFetchPatientAppointments(patientId));
            }

            return response;
        })
        .catch((error) =>
            dispatch({
                type: types.CREATE_PATIENT_APPOINTMENT_FAILURE,
                data: error,
            })
        );
};

export const tryEditPatientAppointment = (appointment) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.EDIT_PATIENT_APPOINTMENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/appointments/{id}" : "/dap-hcp-patient/api/v1/customers/patients/appointments/{id}",
        uriParams: {
            id: appointment.id,
        },
        method: "PUT",
        token: access_token,
        data: appointment,
    })
        .then((response) =>
            dispatch({
                type: types.EDIT_PATIENT_APPOINTMENT_SUCCESS,
                data: response,
            })
        )
        .catch((error) =>
            dispatch({
                type: types.EDIT_PATIENT_APPOINTMENT_FAILURE,
                data: error,
            })
        );
};

export const tryDeletePatientAppointment = (appointmentId) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.DELETE_PATIENT_APPOINTMENT_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/appointments/{id}" : "/dap-hcp-patient/api/v1/customers/patients/appointments/{id}",
        uriParams: {
            id: appointmentId,
        },
        method: "DELETE",
        token: access_token,
    })
        .then((response) =>
            dispatch({
                type: types.DELETE_PATIENT_APPOINTMENT_SUCCESS,
                data: response,
            })
        )
        .catch((error) =>
            dispatch({
                type: types.DELETE_PATIENT_APPOINTMENT_FAILURE,
                data: error,
            })
        );
};

//EN COURS
export const tryFetchPatientTasks = (patientId) => (dispatch) => {
    const {
        auth: { access_token },
        patients: { patientTasks, patientTasksLoaded, patientTasksPatientId },
    } = store.getState();

    if (patientTasksLoaded && patientTasksPatientId === patientId) {
        return Promise.resolve(patientTasks);
    }

    dispatch({ type: types.FETCH_PATIENT_TASKS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/tasks" : "/dap-hcp-patient/api/v1/customers/patients/tasks",
        queryParams: {
            patient: patientId,
        },
        token: access_token,
    })
        .then((response) =>
            dispatch({
                type: types.FETCH_PATIENT_TASKS_SUCCESS,
                data: response["hydra:member"],
                patientId,
            })
        )
        .catch((error) => dispatch({ type: types.FETCH_PATIENT_TASKS_FAILURE, data: error }));
};

export const tryCreatePatientTask = (task) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.CREATE_PATIENT_TASK_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/tasks" : "/dap-hcp-patient/api/v1/customers/patients/tasks",
        method: "POST",
        token: access_token,
        data: task,
    })
        .then((response) =>
            dispatch({
                type: types.CREATE_PATIENT_TASK_SUCCESS,
                data: response,
            })
        )
        .catch((error) => dispatch({ type: types.CREATE_PATIENT_TASK_FAILURE, data: error }));
};

export const tryEditPatientTask = (task) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.EDIT_PATIENT_TASK_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/tasks/{id}" : "/dap-hcp-patient/api/v1/customers/patients/tasks/{id}",
        uriParams: {
            id: task.id,
        },
        method: "PUT",
        token: access_token,
        data: task,
    })
        .then((response) => dispatch({ type: types.EDIT_PATIENT_TASK_SUCCESS, data: response }))
        .catch((error) => dispatch({ type: types.EDIT_PATIENT_TASK_FAILURE, data: error }));
};

export const tryFetchTaskLabels = () => (dispatch) => {
    const {
        auth: { access_token },
        patients: { taskLabels, taskLabelsLoaded },
    } = store.getState();

    if (taskLabelsLoaded) {
        return Promise.resolve(taskLabels);
    }

    dispatch({ type: types.FETCH_TASK_LABELS_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/task_labels" : "/dap-hcp-patient/api/v1/customers/patients/tasks-labels",
        token: access_token,
    })
        .then((response) =>
            dispatch({
                type: types.FETCH_TASK_LABELS_SUCCESS,
                data: response["hydra:member"],
            })
        )
        .catch((error) => dispatch({ type: types.FETCH_TASK_LABELS_FAILURE, data: error }));
};

export const tryFetchPotentialPatient = (potentialPatientId) => (dispatch) => {
    const {
        auth: { access_token },
    } = store.getState();

    dispatch({ type: types.POTENTIAL_PATIENT_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/potential_patients/{id}" : "/dap-hcp-patient/api/v1/break-score-questionnaires/potential-patients/{id}",
        uriParams: {
            id: potentialPatientId,
        },
        method: "GET",
        token: access_token,
    })
        .then((response) => {
            dispatch({ type: types.POTENTIAL_PATIENT_FETCH_SUCCESS, data: response, potentialPatientId });
            return response;
        })
        .catch((error) => dispatch({ type: types.POTENTIAL_PATIENT_FETCH_FAILURE, data: error }));
};
