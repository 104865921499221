import React from "react";

import PropTypes from "prop-types";
import * as queryString from "query-string";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
    DefaultLoader,
    PractitionerLayoutAnonymous,
    PractitionerLayoutAsideAnonymous,
    redirectUrlAfterAuthPath,
} from "@rdcs/dap-front-library";

import { tryGigyaGetAccountInfo, tryGigyaRetrieveTokenFromCode } from "../../actions/auth";

const LoginSuccessScreen = ({ auth, tryGigyaGetAccountInfo, tryGigyaRetrieveTokenFromCode }) => {
    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        const urlParams = queryString.parse(location.search);

        if (auth.user !== null || !urlParams.code) {
            navigate(redirectUrlAfterAuthPath("/mes-patients"));
        }

        tryGigyaRetrieveTokenFromCode(urlParams.code)
            .then(() => navigate(redirectUrlAfterAuthPath("/mes-patients")))
            .catch((error) => {
                // User exists in Gigya but not in DAP
                if (auth.access_token && !auth.user) {
                    // If the user is disabled by checking value of error (endpoint /userinfo fail)
                    if (
                        !error ||
                        !error.body ||
                        !error.body.message ||
                        error.body.message !== "error.user_not_enabled"
                    ) {
                        tryGigyaGetAccountInfo(auth.access_token).then(() => navigate("/inscription"));
                    }
                }
            });
    }, []);

    return (
        <PractitionerLayoutAnonymous>
            <div className="grid credentials__container">
                <PractitionerLayoutAsideAnonymous />
                <main className="col-sm-50 credentialsMain__container">
                    <div className="credentialsMain">
                        <div className="credentials__title">
                            Connectez-vous à <br />
                            Diabète Adhérence Plateforme
                        </div>
                        <div>
                            {auth.retrieveTokenFromCodeError ? (
                                <div>
                                    <div className="bgError radius shadow mt-4 mb-2 pt-2 pl-3 pb-2 pr-3">
                                        {auth.retrieveTokenFromCodeError}
                                    </div>
                                    <Link to="/" className="button button__primaryButton mx-0 mt-2">
                                        Retourner à l'accueil
                                    </Link>
                                </div>
                            ) : (
                                <DefaultLoader />
                            )}
                        </div>
                    </div>
                </main>
            </div>
        </PractitionerLayoutAnonymous>
    );
};

LoginSuccessScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    tryGigyaGetAccountInfo: PropTypes.func.isRequired,
    tryGigyaRetrieveTokenFromCode: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = {
    tryGigyaGetAccountInfo,
    tryGigyaRetrieveTokenFromCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginSuccessScreen);
