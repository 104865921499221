import * as types from "../actions/types";

import store from "../store";
import TokenData from "../utils/TokenData";

import { toast } from "react-toastify";

export const userCan = roles => {
    const rolesArray = roles instanceof String ? [roles] : roles;
    const userRoles = store.getState().auth.user ? store.getState().auth.user.roles : [];
    for (let i = 0; i < userRoles.length; i++) {
        if (rolesArray.includes(userRoles[i])) return true;
    }
    return false;
};

export const userRole = () => {
    switch (true) {
        case userCan('ROLE_DOCTOR'):
            return 'doctors';
        case userCan('ROLE_NURSE'):
            return 'nurses';
        case userCan('ROLE_PHARMACIST'):
            return 'pharmacists';
        default:
            return 'others';
    }
}

const initialState = {
    ...TokenData.retrieve(),
    gigyaUser: null,
    retrieveTokenFromCodeLoading: false,
    retrieveTokenFromCodeError: false,
    refreshTokenLoading: false,
    refreshTokenError: false,
    searchBySsnLoading: false,
    searchBySsnResult: null
};

function auth(state = initialState, action) {
    switch (action.type) {
        // Retrieve token from code gigya
        case types.GIGYA_RETRIEVE_TOKEN_FROM_CODE_REQUESTED: {
            return Object.assign({}, state, {
                retrieveTokenFromCodeLoading: true,
                retrieveTokenFromCodeError: false
            });
        }
        case types.GIGYA_RETRIEVE_TOKEN_FROM_CODE_SUCCESS: {
            TokenData.store(action.data);
            return Object.assign({}, state, {
                retrieveTokenFromCodeLoading: false,
                ...TokenData.retrieve()
            });
        }
        case types.GIGYA_RETRIEVE_TOKEN_FROM_CODE_FAILURE: {
            return Object.assign({}, state, {
                retrieveTokenFromCodeLoading: false,
                retrieveTokenFromCodeError: action.data.body["hydra:description"]
            });
        }
        // Refresh token gygia
        case types.GIGYA_REFRESH_TOKEN_REQUESTED: {
            return Object.assign({}, state, {
                refreshTokenLoading: true,
                refreshTokenError: false
            });
        }
        case types.GIGYA_REFRESH_TOKEN_SUCCESS: {
            TokenData.store({ ...action.data, user: TokenData.retrieve().user });
            return Object.assign({}, state, {
                refreshTokenLoading: false,
                ...TokenData.retrieve()
            });
        }
        case types.GIGYA_REFRESH_TOKEN_FAILURE: {
            return Object.assign({}, state, {
                refreshTokenLoading: false,
                refreshTokenError: true
            });
        }
        // Get userinfo
        case types.USERINFO_REQUESTED: {
            return Object.assign({}, state, {
                userInfoLoading: true,
                userInfoError: false
            });
        }
        case types.USERINFO_SUCCESS: {
            TokenData.store({ ...TokenData.retrieve(), user: action.data });
            return Object.assign({}, state, {
                userInfoLoading: false,
                ...TokenData.retrieve()
            });
        }
        case types.USERINFO_FAILURE: {
            return Object.assign({}, state, {
                userInfoLoading: false,
                userInfoError: action.data && action.data.body ? action.data.body.message : true
            });
        }
        // USER INFO UPDATE
        case types.USERINFO_UPDATE: {
            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    'avatar': action.avatar,
                    'firstName': action.firstName,
                    'lastName': action.lastName,
                }
            });
        }
        // Get account info from Gigya
        case types.GIGYA_GET_ACCOUNT_INFO_REQUESTED: {
            return Object.assign({}, state, {});
        }
        case types.GIGYA_GET_ACCOUNT_INFO_SUCCESS: {
            return Object.assign({}, state, {
                gigyaUser: action.data
            });
        }
        case types.GIGYA_GET_ACCOUNT_INFO_FAILURE: {
            return Object.assign({}, state, {
                gigyaUser: null
            });
        }
        // Search patient by ssn
        case types.GIGYA_SEARCH_BY_SSN_REQUESTED: {
            return Object.assign({}, state, {
                searchBySsnLoading: true,
                searchBySsnResult: null
            });
        }
        case types.GIGYA_SEARCH_BY_SSN_SUCCESS: {
            return Object.assign({}, state, {
                searchBySsnLoading: false,
                searchBySsnResult: action.data
            });
        }
        case types.GIGYA_SEARCH_BY_SSN_FAILURE: {
            return Object.assign({}, state, {
                searchBySsnLoading: false
            });
        }
        // Logout
        case types.LOGOUT: {
            TokenData.reset();
            toast.info("Vous avez été déconnecté");
            return Object.assign({}, state, {
                ...TokenData.retrieve()
            });
        }
        default:
            return state;
    }
}

export default auth;
