import React from "react";
import PropTypes from 'prop-types';

import moment from "moment";

const Task = ({ task }) => (
    <>
        <span className="tasksListItem__label">{task.label}</span>
        <div className="tasksList__practitioner">
            {task.done ? (
                <div className="tasksListItem__date">
                    Réalisée par{" "}
                    {task.doneBy ? task.doneBy.firstName + " " + task.doneBy.lastName : "Praticien anonyme"}
                </div>
            ) : task.responsible ? (
                <div className="tasksListItem__date">
                    À réaliser par le {task.responsible.firstName} {task.responsible.lastName}
                </div>
            ) : (
                <div className="tasksListItem__date">
                    Crée par{" "}
                    {task.createdBy
                        ? task.createdBy.firstName + " " + task.createdBy.lastName
                        : "Praticien anonyme"}
                </div>
            )}
            &nbsp;
            <span className="tasksListItem__date mr-1">
                {task.done
                    ? "le " + moment(task.doneAt).format("DD/MM/YYYY")
                    : task.dueAt
                    ? task.responsible
                        ? "avant le " + moment(task.dueAt).format("DD/MM/YYYY") + "."
                        : ", à réaliser avant le " + moment(task.dueAt).format("DD/MM/YYYY") + "."
                    : null}
            </span>
            {task.responsible && !task.done && (
                <>
                    <div className="tasksListItem__date">
                        Crée par {task.createdBy.firstName} {task.createdBy.lastName}
                    </div>
                    &nbsp;
                    <span className="tasksListItem__date">
                        le {moment(task.createdAt).format("DD/MM/YYYY")}
                    </span>
                </>
            )}
        </div>
    </>
);

Task.propTypes = {
    task: PropTypes.shape({
        label: PropTypes.string.isRequired,
        doneBy: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
        }),
        responsible: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
        }),
        createdBy: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
        }),
        done: PropTypes.bool.isRequired,
        doneAt: PropTypes.string,
        dueAt: PropTypes.string,
    }).isRequired,
};

export default React.memo(Task);