import * as types from "./types";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from '../store';

export const tryFetchRecommandations = () => dispatch => {
    const {
        auth: {
            access_token,
        },
        recommandations: {
            list,
            listLoaded
        }
    } = store.getState();

    if (listLoaded) {
        return Promise.resolve(list);
    }

    dispatch({ type: types.RECOMMANDATIONS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/recommendations" : "/dap-hcp-patient/api/v1/clinical-data/recommendations",
        queryParams: {
            pagination: false,
            active: true
        },
        token: access_token
    })
    .then(response => {
        dispatch({ type: types.RECOMMANDATIONS_FETCH_SUCCESS, data: response["hydra:member"] });
        return response["hydra:member"];
    })
    .catch(error => {
        dispatch({ type: types.RECOMMANDATIONS_FETCH_FAILURE, data: error });
        throw error;
    });
};
