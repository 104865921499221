import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, getPrivateFileUrl, Icon, InputDocument } from "@rdcs/dap-front-library";

import useState from "./useState";

import API from "../../../utils/API";
import { isLocalAPI } from "../../../utils/utils";

const ProfileJustificatoryForm = ({ currentJustificatory, uid, status }) => {
    const navigate = useNavigate();

    const [
        { justificatory, justificatoryError, submitLoading, justificatoryUrl },
        { setJustificatoryUrl, setJustificatory, setJustificatoryError, justificatoryLoad },
    ] = useState();

    React.useEffect(() => {
        currentJustificatory &&
            getPrivateFileUrl(currentJustificatory.filename)
                .then(({ url }) => setJustificatoryUrl(url))
                .catch(() => setJustificatoryError("La récupération du justificatif à échoué."));
    }, []);

    const uploadDocumentAction = (file) =>
        API.uploadFile(file)
            .then((item) => setJustificatory(item["@id"]))
            .catch(() => {
                setJustificatory(null);
                throw new Error("L'upload a échoué pour une raison inconnue");
            });

    const handleSubmit = (e) => {
        e.preventDefault();

        if (justificatory) {
            justificatoryLoad();
            API.request({
                path: isLocalAPI
                    ? "/practitioner_submit_justificatory" // Maybe use PUT /doctors(or whatever roles)/id ?
                    : "/dap-hcp-patient/api/v1/customers/practitioners/submit-justificatory",
                method: "POST",
                data: {
                    uid: uid,
                    justificatoryId: justificatory,
                },
            })
                .then(() => {
                    toast.success("Votre justificatif a été soumis");
                    navigate("/");
                })
                .catch(({ body: { error } }) => setJustificatoryError(error));
        } else {
            setJustificatoryError("Le justificatif est requis");
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2 className="title-2 mb-2">Télécharger un justificatif</h2>
            {status === "waiting" ? (
                <p className="mb-2">Votre justificatif est en cours de validation.</p>
            ) : status === "valid" ? (
                <p className="mb-2">Votre justificatif a été validé.</p>
            ) : status === "rejected" ? (
                <p className="mb-2">Votre justificatif a été refusé, veuillez mettre en ligne un nouveau.</p>
            ) : (
                status === "empty" && (
                    <p className="mb-2">Vous n'avez pas téléchargé de justificatif, veuillez en mettre un en ligne.</p>
                )
            )}
            {status === "waiting" || status === "valid" ? (
                <div>
                    {justificatoryError ? (
                        <p className="form__errorMessage">{justificatoryError}</p>
                    ) : (
                        <a
                            className="buttonUploadFile__currentFile"
                            href={justificatoryUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                        >
                            <Icon name="notes" /> Mon justificatif
                        </a>
                    )}
                </div>
            ) : (
                <>
                    <InputDocument
                        label="Charger un justificatif"
                        documentName="Mon justificatif"
                        legend="Justificatif accepté : carte professionnelle, certificat de scolarité, en-tête d'ordonnance, en-tête de fiche de paie."
                        uploadAction={uploadDocumentAction}
                        error={justificatoryError}
                        defaultFilename={currentJustificatory?.filename}
                        required
                    />
                    <div className="text-center">
                        <Button type="submit" className="button__primaryButton" isLoading={submitLoading}>
                            Enregistrer
                        </Button>
                    </div>
                </>
            )}
        </form>
    );
};

ProfileJustificatoryForm.propTypes = {
    currentJustificatory: PropTypes.shape({
        "@id": PropTypes.string.isRequired,
        filename: PropTypes.string.isRequired,
    }),
    uid: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
};

const mapStateToProps = ({
    auth: {
        user: { username },
    },
    profile: {
        profile: { justificatory: currentJustificatory, justificatoryValid },
    },
}) => ({
    currentJustificatory,
    uid: username,
    status:
        currentJustificatory !== null && justificatoryValid === null
            ? "waiting"
            : justificatoryValid === false
            ? "rejected"
            : justificatoryValid === true
            ? "valid"
            : currentJustificatory === null && "empty",
});

export default connect(mapStateToProps)(ProfileJustificatoryForm);
