import React from 'react';

import { StatusBadge } from "@rdcs/dap-front-library";

const recommendationFilters = [
    {
        label: 'Toutes',
        noFilter: true
    },
    {
        label: nbElems => <StatusBadge color='green' textClassName='timkl__color--black-important'>Planifiées - {nbElems}</StatusBadge>,
        value: 'green'
    },
    {
        label: nbElems => <StatusBadge color='orange' textClassName='timkl__color--black-important'>À planifier - {nbElems}</StatusBadge>,
        value: 'orange'
    },
    {
        label: nbElems => <StatusBadge color='red' textClassName='timkl__color--black-important'>En retard - {nbElems}</StatusBadge>,
        value: 'red'
    },
];

export default recommendationFilters;