import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Icon } from "@rdcs/dap-front-library";

const LookLink = ({ link, children }) => (
    <Link to={link} className='spo__color--gray-8 text-uppercase txtSizeExtraSmall d-inline-flex align-items-center'>
        <Icon name='eye' size={24} className='d-block' /> <span className='d-block' style={{ marginLeft: '2px' }}>{children}</span>
    </Link>
);

LookLink.propTypes = {
    link: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]).isRequired,
};

export default LookLink;