import * as types from "./types";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from '../store';

/**
 * Practitioners FETCH
 */
export const tryFetchPractitioners = filter => dispatch => {
    const {
        auth: {
            access_token,
        },
    } = store.getState();

    dispatch({ type: types.PRACTITIONERS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/search_practitioners" : "/dap-hcp-patient/api/v1/customers/practitioners/search",
        queryParams: {
            discr: filter.type,
            city: filter.city,
            fullName: filter.fullName,
            page: filter.page,
            itemsPerPage: filter.itemsPerPage
        },
        token: access_token
    })
    .then(response => {
        dispatch({ type: types.PRACTITIONERS_FETCH_SUCCESS, data: response });
        return response;
    })
    .catch(error => dispatch({ type: types.PRACTITIONERS_FETCH_FAILURE, data: error }));
};

export const clearPractitioners = () => dispatch => dispatch({ type: types.PRACTITIONERS_FETCH_CLEAR });
