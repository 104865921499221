import React from 'react';

const defaultState = {
    rpps: null,
    rppsError: false,
    rppsLoading: false,
    practitioner: null,
};

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'SET_RPPS':
            return {
                ...prevState,
                rpps: action.rpps,
            }
        case 'RPPS_LOAD':
            return {
                ...prevState,
                rppsLoading: true,
                rppsError: false,
            }
        case 'SET_PRACTITIONER_FROM_RPPS':
            return {
                ...prevState,
                practitioner: action.practitioner,
                rppsError: null,
                rppsLoading: false,
            }
        case 'SET_RPPS_ERROR':
            return {
                ...prevState,
                rppsLoading: false,
                rppsError: "Numéro invalide",
            }
        case 'FORGET_PRACTITIONER':
            return {
                ...prevState,
                practitioner: null,
            }
        default:
            return prevState;
    }
};

const useState = () => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(() => ({

        setRpps: (rpps) => dispatch({ type: 'SET_RPPS', rpps }),

        rppsLoad: () => dispatch({ type: 'RPPS_LOAD' }),
        
        setPractitionerFromRpps: (practitioner) => dispatch({ type: 'SET_PRACTITIONER_FROM_RPPS', practitioner }),

        setRppsError: () => dispatch({ type: 'SET_RPPS_ERROR' }),

        forgetPractitioner: () => dispatch({ type: 'FORGET_PRACTITIONER' }),

    }), []);

    return [state, methods];
};

export default useState;