import * as types from "./types";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from '../store';

export const tryGigyaRetrieveTokenFromCode = code => dispatch => {
    dispatch({ type: types.GIGYA_RETRIEVE_TOKEN_FROM_CODE_REQUESTED });
    return API.request({
        method: "POST",
        path: "/ciam/api/v1/access/token",
        data: {
            apiKey: window.env.AUTH_API_KEY,
            redirectUri: window.env.AUTH_REDIRECT_URI,
            tokenType: "CODE",
            token: code
        }
    })
    .then(authData => {
        dispatch({ type: types.GIGYA_RETRIEVE_TOKEN_FROM_CODE_SUCCESS, data: authData });
        return authData;
    })
    .catch(error => {
        dispatch({ type: types.GIGYA_RETRIEVE_TOKEN_FROM_CODE_FAILURE, data: error });
        throw error;
    })
    .then(authData => {
        dispatch({ type: types.USERINFO_REQUESTED });
        return API.request({
            method: "GET",
            path: isLocalAPI ? "/userinfo" : "/dap-hcp-patient/api/v1/customers/userinfo",
            token: authData.access_token
        }).then(user => {
            dispatch({ type: types.USERINFO_SUCCESS, data: user });
            return user;
        })
        .catch(error => {
            dispatch({ type: types.USERINFO_FAILURE, data: error });
            throw error;
        });
    })
}

export const tryUpdateUserInfos = (avatar, firstName, lastName) => dispatch => {
    dispatch({ type: types.USERINFO_UPDATE, avatar, firstName, lastName });
}

export const tryGigyaGetAccountInfo = token => dispatch => {
    dispatch({ type: types.GIGYA_GET_ACCOUNT_INFO_REQUESTED });
    return API.request({
        method: "POST",
        path: "/ciam/api/v1/identity/get-account-info",
        token: token,
        data: {
            apiKey: window.env.AUTH_API_KEY,
            token: token
        }
    })
    .then(response => dispatch({ type: types.GIGYA_GET_ACCOUNT_INFO_SUCCESS, data: response }))
    .catch(error => dispatch({ type: types.GIGYA_GET_ACCOUNT_INFO_FAILURE, data: error }));
}

export const tryGigyaSearchBySsn = ssn => dispatch => {
    const {
        auth: {
            access_token
        }
    } = store.getState();

    dispatch({ type: types.GIGYA_SEARCH_BY_SSN_REQUESTED });
    return API.request({
        method: "GET",
        path: isLocalAPI
            ? "/gigya/search-by-ssn/{ssn}"
            : "/dap-hcp-patient/api/v1/ciam/identities/search-by-ssn/{ssn}",
        uriParams: {
            ssn
        },
        token: access_token
    })
    .then(response => {
        dispatch({ type: types.GIGYA_SEARCH_BY_SSN_SUCCESS, data: response });
        return response;
    })
    .catch(error => {
        dispatch({ type: types.GIGYA_SEARCH_BY_SSN_FAILURE, data: error });
        throw error;
    });
}

export const tryGigyaRefreshToken = refreshToken => dispatch => {
    dispatch({ type: types.GIGYA_REFRESH_TOKEN_REQUESTED });
    return API.request({
        method: "POST",
        path: "/ciam/api/v1/access/token",
        data: {
            apiKey: window.env.AUTH_API_KEY,
            redirectUri: window.env.AUTH_REDIRECT_URI,
            tokenType: "REFRESH",
            token: refreshToken
        }
    })
    .then(response => dispatch({ type: types.GIGYA_REFRESH_TOKEN_SUCCESS, data: response }))
    .catch(error => {
        dispatch({ type: types.GIGYA_REFRESH_TOKEN_FAILURE, data: error });
        dispatch(tryLogout());
    });
}

export const tryLogout = () => dispatch => {
    dispatch({ type: types.LOGOUT });
}
