import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "@rdcs/dap-front-library";

import DoctorDrawing from "../../assets/img/illu_doctors.png";

const NoPastQuestionnaire = ({ setFillNewQuestionnaire }) => {
    return (
        <div className="d-flex flex-column align-items-center">
            <h3 className="text-center title-3-nunito timkl__color--blue-dark mt-2">Aucun questionnaire de rupture n’a été saisi pour ce patient.</h3>
            <Button className="button__primaryButton d-flex justify-center align-items-center mt-3" onClick={() => setFillNewQuestionnaire(true)}>
                <Icon name="document" size={21} className="mr-1" />
                <span className="button-text-3-nunito">Remplir le questionnaire</span>
            </Button>
            <div className="mt-7 mb-3" style={{ maxHeight: "392px", maxWidth: "531px" }}>
                <img src={DoctorDrawing} />
            </div>
        </div>
    );
};

NoPastQuestionnaire.propTypes = {
    setFillNewQuestionnaire: PropTypes.func.isRequired,
};

export default NoPastQuestionnaire;
