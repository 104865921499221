import React from "react";
import PropTypes from 'prop-types';

import parse from "html-react-parser";
import {
    constant,
    Button,
    InputNumber,
    InputPassword,
    InputImage,
    InputText,
    InputEmail,
    InputTel,
    Select,
    InputDocument,
    InputCheckbox,
    Grecaptcha
} from "@rdcs/dap-front-library";

import useState from './useState';

const FinalizeSignInForm = ({
    consents,
    practitioner,
    submit,
    loading,
    violations,
}) => {
    const [form, {
        setEmail,
        setPlainPassword,
        setConfirmPassword,
        setLastName,
        setFirstName,
        setTitle,
        setEstablishment,
        setAddress,
        setZipcode,
        setCity,
        setPhoneNumber1,
        setPhoneNumber2,
        uploadAvatarAction,
        uploadDocumentAction,
        toggleConsents
    }, {
        checkPassword
    }] = useState(practitioner);
    const {
        rppsNumber,
        titre,
        firstName,
        lastName,
        profession,
        speciality,
        establishment,
        address,
        zipcode,
        city,
        email,
        phoneNumber1,
        phoneNumber2,
        confirmPasswordError,
    } = form;
    const gRecaptchaRef = React.useRef();
    const passwordRef = React.useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (checkPassword()) {
            const finalForm = { ... form };

            delete finalForm.confirmPasswordError;

            if (form.justificatory === null) {
                delete finalForm.justificatory;
            }

            submit(finalForm);
        }
        else {
            passwordRef.current?.scrollIntoView();
        }
    };

    return (
        <form className="credentialsMain--inscriptionStep2" onSubmit={handleSubmit}>
            <Grecaptcha ref={gRecaptchaRef} />
            <InputEmail
                label="Votre email"
                placeholder="Saisissez votre email"
                legend="Cette adresse nous sert à vous communiquer des informations importantes. Nous ne la communiquerons à aucun tiers."
                onChange={setEmail}
                error={violations.email}
                defaultValue={email}
                required
            />
            <div className="grid grid__padding-10">
                <div className="col-xs-50">
                    <InputPassword
                        label="Votre mot de passe"
                        onChange={setPlainPassword}
                        required
                    />
                </div>
                <div className="col-xs-50">
                    <InputPassword
                        label="Confirmation"
                        onChange={setConfirmPassword}
                        required
                    />
                </div>
                {(violations.plainPassword || confirmPasswordError) && (
                    <div className="col-xs-100">
                        {violations.plainPassword && (
                            <p className="form__errorMessage">{violations.plainPassword}</p>
                        )}
                        {confirmPasswordError && (
                            <p className="form__errorMessage">{confirmPasswordError}</p>
                        )}
                    </div>
                )}
            </div>
            <InputText
                label="Votre nom"
                placeholder="Saisissez votre nom"
                name="lastName"
                onChange={setLastName}
                error={violations.lastName}
                defaultValue={lastName}
                required
            />
            <InputText
                label="Votre prénom"
                placeholder="Saisissez votre prénom"
                name="firstName"
                onChange={setFirstName}
                error={violations.firstName}
                defaultValue={firstName}
                required
            />
            <div className="grid grid__padding-10">
                {practitioner.creationEndpoint === "doctors" && (
                    <div className="col-xs-40">
                        <Select
                            label="Votre titre"
                            options={[
                                { label: "Aucun", value: "" },
                                ...constant("titres").map(titre => ({
                                    label: constant("titres", titre),
                                    value: titre
                                }))
                            ]}
                            onChange={setTitle}
                            error={violations.titre}
                            defaultValue={titre}
                        />
                    </div>
                )}
                <div
                    className={`${
                        practitioner.creationEndpoint === "doctors" ? "col-xs-60" : "col-xs-100"
                    }`}
                >
                    <InputText
                        label="Votre profession"
                        placeholder="Saisissez votre profession"
                        name="profession"
                        disabled={true}
                        onChange={() => {}}
                        error={violations.profession}
                        defaultValue={profession}
                        required
                    />
                </div>
            </div>
            {practitioner.creationEndpoint === "doctors" && (
                <InputText
                    label="Votre spécialité"
                    placeholder="Aucune"
                    name="speciality"
                    disabled={true}
                    onChange={() => {}}
                    error={violations.speciality}
                    defaultValue={speciality}
                    required
                />
            )}
            <InputText
                label="Votre établissement"
                placeholder="Saisissez votre établissement"
                name="establishment"
                onChange={setEstablishment}
                error={violations.establishment}
                defaultValue={establishment}
            />
            <div>
                <InputNumber
                    label="Votre numéro RPPS"
                    placeholder="Saisissez votre numéro RPPS"
                    name="rpps"
                    disabled={true}
                    onChange={() => {}}
                    error={violations.rppsNumber}
                    defaultValue={parseInt(rppsNumber)}
                    required
                />
                <InputDocument
                    label="Charger un justificatif"
                    documentName="Mon justificatif"
                    legend="Justificatif accepté : carte professionnelle, certificat de scolarité, en-tête d'ordonnance, en-tête de fiche de paie."
                    required={false}
                    uploadAction={uploadDocumentAction}
                    error={violations.justificatory}
                    gRecaptchaRef={gRecaptchaRef}
                />
            </div>
            <InputText
                label="Votre adresse"
                placeholder="Saisissez votre adresse"
                name="address"
                onChange={setAddress}
                error={violations.address}
                defaultValue={address}
                required
            />
            <div className="grid grid__padding-10">
                <div className="col-xs-50">
                    <InputNumber
                        label="Code postal"
                        placeholder="Saisissez votre code postal"
                        name="zip"
                        onChange={setZipcode}
                        error={violations.zipcode}
                        defaultValue={parseInt(zipcode)}
                        required
                    />
                </div>
                <div className="col-xs-50">
                    <InputText
                        label="Votre ville"
                        placeholder="Saisissez votre ville"
                        name="city"
                        onChange={setCity}
                        error={violations.city}
                        defaultValue={city}
                        required
                    />
                </div>
            </div>
            <div className="grid grid__padding-10">
                <div
                    className={`${
                        practitioner.creationEndpoint === "doctors" ? "col-xs-50" : "col-xs-100"
                    }`}
                >
                    <InputTel
                        label="Votre téléphone"
                        placeholder="Saisissez votre téléphone"
                        name="phone"
                        onChange={setPhoneNumber1}
                        error={violations.phoneNumber1}
                        defaultValue={phoneNumber1}
                        required
                    />
                </div>
                {practitioner.creationEndpoint === "doctors" && (
                    <div className="col-xs-50">
                        <InputTel
                            label="Votre téléphone 2"
                            placeholder="Saisissez votre téléphone"
                            name="phone-2"
                            onChange={setPhoneNumber2}
                            error={violations.phoneNumber2}
                            defaultValue={phoneNumber2}
                        />
                    </div>
                )}
            </div>
            <InputImage
                label="Votre photo de profil"
                text="Télécharger"
                uploadAction={uploadAvatarAction}
                error={violations.avatar}
                gRecaptchaRef={gRecaptchaRef}
            />
            <br />
            {consents.map(consent => (
                <InputCheckbox
                    key={consent.id}
                    label={parse(consent.legalStatements.fr.purpose)}
                    onChange={val => toggleConsents(consent.id, val)}
                    required={consent.required}
                />
            ))}
            <div className="credentialsMain__validate">
                <Button type="submit" className="button__primaryButton" isLoading={loading}>
                    Valider mon inscription
                </Button>
            </div>
        </form>
    );
}

FinalizeSignInForm.propTypes = {
    consents: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        required: PropTypes.bool,
        legalStatements: PropTypes.shape({
            fr: PropTypes.shape({
                purpose: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    })).isRequired,
    practitioner: PropTypes.shape({
        rppsNumber: PropTypes.string.isRequired,
        titre: PropTypes.string,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        profession: PropTypes.string,
        speciality: PropTypes.string,
        establishment: PropTypes.string,
        address: PropTypes.string,
        zipcode: PropTypes.string,
        city: PropTypes.string,
        email: PropTypes.string,
        phoneNumber1: PropTypes.string,
        phoneNumber2: PropTypes.string,
        creationEndpoint: PropTypes.string,
    }).isRequired,
    submit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    violations: PropTypes.object,
}

export default FinalizeSignInForm;
