import * as types from "./types";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from '../store';

export const tryFetchPointsOfAttention = () => dispatch => {
    const {
        auth: {
            access_token,
        },
        pointsOfAttention: {
            list,
            listLoaded
        }
    } = store.getState();

    if (listLoaded) {
        return Promise.resolve(list);
    }

    dispatch({ type: types.POINTS_OF_ATTENTION_FETCH_REQUESTED });
    return (
        API.request({
            path: isLocalAPI ? "/point_of_attentions" : "/dap-hcp-patient/api/v1/customers/patients/point-of-attentions",
            queryParams: {
                pagination: false
            },
            token: access_token
        })
        .then(response => {
            dispatch({ type: types.POINTS_OF_ATTENTION_FETCH_SUCCESS, data: response["hydra:member"] });
            return response["hydra:member"];
        })
        .catch(error => {
            dispatch({ type: types.POINTS_OF_ATTENTION_FETCH_FAILURE, data: error });
            throw error;
        })
    );
};


export const tryFetchPatientPointsOfAttention = patientId => dispatch => {
    const {
        auth: {
            access_token,
        },
        pointsOfAttention: {
            datas,
            datasPatientId,
            loaded
        }
    } = store.getState();

    if (loaded && datasPatientId === patientId) {
        return Promise.resolve(datas);
    }

    dispatch({ type: types.PATIENT_POINTS_OF_ATTENTION_FETCH_REQUESTED });
    return (
        API.request({
            path: isLocalAPI ? "/patient_point_of_attentions" : "/dap-hcp-patient/api/v1/customers/patients/patient-point-of-attentions",
            queryParams: {
                pagination: false,
                patient: "/patients/" + patientId,
            },
            token: access_token
        })
        .then(response => {
            dispatch({ type: types.PATIENT_POINTS_OF_ATTENTION_FETCH_SUCCESS, data: response["hydra:member"], patientId });
            return response["hydra:member"];
        })
        .catch(error => {
            dispatch({ type: types.PATIENT_POINTS_OF_ATTENTION_FETCH_FAILURE, data: error });
            throw error;
        })
    );
};

export const trySeePatientPointsOfAttention = id => dispatch => {
    const {
        auth: {
            access_token,
        },
    } = store.getState();

    return (
        API.request({
            path: isLocalAPI ? "/patient_point_of_attentions/{id}/seen" : "/dap-hcp-patient/api/v1/customers/patients/patient-point-of-attentions/{id}/seen",
            uriParams: {
                id
            },
            method: "PATCH",
            token: access_token,
            data: {}
        })
        .then(() => {
            dispatch({ type: types.PATIENT_POINTS_OF_ATTENTION_SEE_SUCCESS, data: id });
        })
    );
};