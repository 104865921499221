import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Animated } from "react-animated-css";
import { Button } from "@rdcs/dap-front-library";

const PatientSearchItem = ({ item, patientStatus, addPatient, addPatientLoading }) => (
    <Animated animationIn="fadeIn">
        <div className="card mb-2">
            <div>
                <div>
                    {patientStatus === "none" ? (
                        item.firstName + " " + item.lastName
                    ) : (
                        <Link to={`/mes-patients/${item.id}`}>{item.firstName + " " + item.lastName}</Link>
                    )}
                </div>
                <div className="mb-1">
                    <span>
                        {item.zipcode} {item.city}{" "}
                    </span>
                </div>
                {(() => {
                    switch (patientStatus) {
                        case "enabled":
                            return <div className="txtSuccess txtBold">Ce patient figure déjà parmi vos patients</div>;
                        default:
                            return (
                                <Button
                                    className="button button__primaryButton py-1 px-3 mx-0 my-0"
                                    isLoading={addPatientLoading}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        addPatient(item);
                                    }}
                                >
                                    Ajouter à mes patients
                                </Button>
                            );
                    }
                })()}
            </div>
        </div>
    </Animated>
);

PatientSearchItem.propTypes = {
    item: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        zipcode: PropTypes.string,
        city: PropTypes.string,
        id: PropTypes.number,
    }).isRequired,
    patientStatus: PropTypes.string,
    addPatient: PropTypes.func.isRequired,
    addPatientLoading: PropTypes.bool.isRequired,
};

export default PatientSearchItem;
