import * as types from "../actions/types";

const initialState = {
    list: [],
    listLoaded: 0,
    listLoading: false,

    datas: [],
    loaded: 0,
    loading: false,
    datasPatientId: -1,
};

function pointsOfAttention(state = initialState, action) {
    switch (action.type) {
        // FETCH LIST POINTS OF ATTENTION
        case types.POINTS_OF_ATTENTION_FETCH_REQUESTED: {
            return {
                ...state,
                list: [],
                listLoaded: 0,
                listLoading: true,
            };
        }
        case types.POINTS_OF_ATTENTION_FETCH_SUCCESS: {
            return {
                ...state,
                list: action.data,
                listLoaded: Date.now(),
                listLoading: false,
            };
        }
        case types.POINTS_OF_ATTENTION_FETCH_FAILURE: {
            return {
                ...state,
                listLoaded: 0,
                listLoading: false
            };
        }

        // FETCH PATIENT'S POINTS OF ATTENTION
        case types.PATIENT_POINTS_OF_ATTENTION_FETCH_REQUESTED: {
            return {
                ...state,
                datas: [],
                loaded: 0,
                loading: true,
                datasPatientId: -1,
            };
        }
        case types.PATIENT_POINTS_OF_ATTENTION_FETCH_SUCCESS: {
            return {
                ...state,
                datas: action.data,
                datasPatientId: action.patientId,
                loaded: Date.now(),
                loading: false,
            };
        }
        case types.PATIENT_POINTS_OF_ATTENTION_FETCH_FAILURE: {
            return {
                ...state,
                loaded: 0,
                loading: false,
                datasPatientId: -1,
            };
        }

        // FETCH PATIENT'S POINTS OF ATTENTION SEE OPERATION
        case types.PATIENT_POINTS_OF_ATTENTION_SEE_SUCCESS: {
            return {
                ...state,
                datas: state.datas.map(data => ({
                    ...data,
                    seenByCurrentUser: data.id === action.data ? true : data.seenByCurrentUser
                }))
            };
        }

        // OTHER EVENTS THAT INVALIDATE CURRENT STATE
        case types.REPORTS_CREATE_SUCCESS:
        case types.REPORT_REMOVE_SUCCESS:
            return {
                ...state,
                loaded: 0,
            };
        
        // DEFAULT
        default:
            return state;
    }
}

export default pointsOfAttention;
