import * as types from "../actions/types";

const initialState = {
    patientLastDiagnosis: null,
    patientLastDiagnosisLoading: false,
    patientLastDiagnosisPatientId: -1,
    patientLastDiagnosisLoaded: 0,

    patientUncompletedDiagnosis: null,
    patientUncompletedDiagnosisLoading: null,
    patientUncompletedDiagnosisPatientId: -1,
    patientUncompletedDiagnosisLoaded: 0,

    recos: [],
    recosLoading: false,
    recosDiagnosisId: -1,
    recosLoaded: 0,

    diagnosisEditLoading: false,
    diagnosisEditError: null,

    diagnosisCreateLoading: false,
    diagnosisCreateError: null,

    checkUpQuestions: [],
    checkUpQuestionsLoaded: 0,

    consultationQuestions: [],
    consultationQuestionsLoaded: 0,

    consultationAnswers: [],
    consultationAnswersLoaded: 0,
    consultationAnswersDiagnosticId: -1,

    editedRecoLoading: false,
    editedRecoError: null
};

function diagnosis(state = initialState, action) {
    switch (action.type) {
        // FETCH RECOS
        case types.DIAGNOSIS_RECOS_FETCH_REQUESTED: {
            return Object.assign({}, state, {
                recosLoading: true,
                recosLoaded: 0,
            });
        }
        case types.DIAGNOSIS_RECOS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                recos: action.data,
                recosLoading: false,
                recosDiagnosisId: action.diagnosisId,
                recosLoaded: Date.now(),
            });
        }
        case types.DIAGNOSIS_RECOS_FETCH_FAILURE: {
            return Object.assign({}, state, {
                recos: [],
                recosLoading: false,
                recosDiagnosisId: -1
            });
        }
        // CREATE
        case types.DIAGNOSIS_CREATE_REQUESTED: {
            return Object.assign({}, state, { diagnosisCreateLoading: true });
        }
        case types.DIAGNOSIS_CREATE_SUCCESS: {
            return Object.assign({}, state, {
                diagnosisCreateLoading: false,
                patientUncompletedDiagnosis: action.data,
                patientUncompletedDiagnosisPatientId: -1,
                recosLoaded: 0,
            });
        }
        case types.DIAGNOSIS_CREATE_FAILURE: {
            return Object.assign({}, state, {
                diagnosisCreateLoading: false,
                diagnosisCreateError: action.data
            });
        }
        // EDIT
        case types.DIAGNOSIS_EDIT_REQUESTED: {
            return Object.assign({}, state, { diagnosisEditLoading: true });
        }
        case types.DIAGNOSIS_EDIT_SUCCESS: {
            return Object.assign({}, state, {
                diagnosisEditLoading: false,
                patientLastDiagnosis: action.data,
                diagnosisEditError: null,
                recosLoaded: 0,
            });
        }
        case types.DIAGNOSIS_EDIT_FAILURE: {
            return Object.assign({}, state, {
                diagnosisEditLoading: false,
                diagnosis: null,
                diagnosisEditError: action.data
            });
        }
        // FETCH PATIENT LAST DIAGNOSIS
        case types.PATIENT_LAST_DIAGNOSIS_REQUESTED: {
            return Object.assign({}, state, {
                patientLastDiagnosis: null,
                patientLastDiagnosisLoading: true,
                patientLastDiagnosisPatientId: -1,
                patientLastDiagnosisLoaded: 0,
                
            });
        }
        case types.PATIENT_LAST_DIAGNOSIS_SUCCESS: {
            return Object.assign({}, state, {
                patientLastDiagnosisLoading: false,
                patientLastDiagnosis: action.data,
                patientLastDiagnosisPatientId: action.patientId,
                patientLastDiagnosisLoaded: Date.now(),
            });
        }
        case types.PATIENT_LAST_DIAGNOSIS_FAILURE: {
            return Object.assign({}, state, {
                patientLastDiagnosisLoading: false
            });
        }
        // FETCH PATIENT UNCOMPLETED DIAGNOSIS
        case types.PATIENT_UNCOMPLETED_DIAGNOSIS_REQUESTED: {
            return Object.assign({}, state, {
                patientUncompletedDiagnosisLoading: true,
                patientUncompletedDiagnosis: null,
                patientUncompletedDiagnosisPatientId: -1,
                patientUncompletedDiagnosisLoaded: 0,
            });
        }
        case types.PATIENT_UNCOMPLETED_DIAGNOSIS_SUCCESS: {
            return Object.assign({}, state, {
                patientUncompletedDiagnosisLoading: false,
                patientUncompletedDiagnosis: action.data.length > 0 ? action.data[0] : null,
                patientUncompletedDiagnosisPatientId: action.patientId,
                patientUncompletedDiagnosisLoaded: Date.now()
            });
        }
        case types.PATIENT_UNCOMPLETED_DIAGNOSIS_FAILURE: {
            return Object.assign({}, state, {
                patientUncompletedDiagnosisLoading: false
            });
        }

        // FETCH DIAGNOSIS CHECKUP QUESTIONS
        case types.DIAGNOSIS_CHECKUP_QUESTIONS_REQUESTED: {
            return Object.assign({}, state, {
                checkUpQuestions: [],
                checkUpQuestionsLoaded: 0,
            });
        }
        case types.DIAGNOSIS_CHECKUP_QUESTIONS_SUCCESS: {
            return Object.assign({}, state, {
                checkUpQuestions: action.data,
                checkUpQuestionsLoaded: Date.now()
            });
        }
        case types.DIAGNOSIS_CHECKUP_QUESTIONS_FAILURE: {
            return Object.assign({}, state, {});
        }

        // FETCH DIAGNOSIS CONSULTATION QUESTIONS
        case types.DIAGNOSIS_CONSULTATION_QUESTIONS_REQUESTED: {
            return Object.assign({}, state, {
                consultationQuestions: [],
                consultationQuestionsLoaded: 0,
            });
        }
        case types.DIAGNOSIS_CONSULTATION_QUESTIONS_SUCCESS: {
            return Object.assign({}, state, {
                consultationQuestions: action.data,
                consultationQuestionsLoaded: Date.now()
            });
        }
        case types.DIAGNOSIS_CONSULTATION_QUESTIONS_FAILURE: {
            return Object.assign({}, state, {});
        }

        // FETCH DIAGNOSIS CONSULTATION ANSWERS
        case types.DIAGNOSIS_CONSULTATION_ANSWERS_REQUESTED: {
            return Object.assign({}, state, {
                consultationAnswers: [],
                consultationAnswersDiagnosticId: -1,
                consultationAnswersLoaded: 0,
            });
        }
        case types.DIAGNOSIS_CONSULTATION_ANSWERS_SUCCESS: {
            return Object.assign({}, state, {
                consultationAnswers: action.data,
                consultationAnswersDiagnosticId: action.diagnosticId,
                consultationAnswersLoaded: Date.now(),
            });
        }
        case types.DIAGNOSIS_CONSULTATION_ANSWERS_FAILURE: {
            return state;
        }

        // EDIT RECOS
        case types.DIAGNOSIS_RECO_AVAILABILITY_REQUESTED: {
            return Object.assign({}, state, { editedRecoLoading: true });
        }
        case types.DIAGNOSIS_RECO_AVAILABILITY_SUCCESS: {
            return Object.assign({}, state, {
                recos: state.recos.map(reco => (reco["@id"] === action.data["@id"] ? action.data : reco)),
                editedRecoLoading: false
            });
        }
        case types.DIAGNOSIS_RECO_AVAILABILITY_FAILURE: {
            return Object.assign({}, state, {
                editedReco: {},
                editedRecoLoading: false,
                editedRecoError: action.data
            });
        }

        default:
            return state;
    }
}

export default diagnosis;
