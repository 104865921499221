import React from "react";

import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import {
    AppointmentRealised,
    AppointmentRecommended,
    AppointmentScheduled,
    DefaultLoader,
    Icon,
    PageTitle,
} from "@rdcs/dap-front-library";

import { tryFetchDiagnosisRecos } from "../../../actions/diagnosis";
import {
    tryCreatePatientAppointment,
    tryEditPatientAppointment,
    tryFetchPatientAppointments,
} from "../../../actions/patients";
import { tryFetchPractitioners } from "../../../actions/practitioners";

import PatientNavLayout from "../../../components/Patients/PatientNavLayout";

const PatientsNavExamsScreen = ({
    diagnosis,
    patients,
    tryCreatePatientAppointment,
    tryEditPatientAppointment,
    tryFetchDiagnosisRecos,
    tryFetchPatientAppointments,
    tryFetchPractitioners,
}) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);

    const [loading, setLoading] = React.useState(true);
    const [diagnosisRecosLoading, setDiagnosisRecosLoading] = React.useState(true);

    const onLoad = React.useCallback(({ patient: { lastDiagnostic } }) => {
        tryFetchDiagnosisRecos(lastDiagnostic).finally(() => setDiagnosisRecosLoading(false));
    }, []);

    // Retrive all examination's appointments.
    const examenAppointments = React.useMemo(
        () =>
            patients.patientAppointments.filter(({ recommendations }) =>
                recommendations.find(({ type }) => type === "examination")
            ),
        [patients.patientAppointments]
    );

    const examRecos = React.useMemo(() => {
        const now = moment().unix();

        return (
            diagnosis.recos
                // Keep consulations only
                .filter(({ recommendation: { type } }) => type === "examination")
                // Bind Diagnostic recos with appointments
                .map((reco) => {
                    for (const examenAppointment of examenAppointments) {
                        if (
                            examenAppointment.recommendations.find(
                                (recommendation) => recommendation.id === reco.recommendation.id
                            )
                        ) {
                            return {
                                ...reco,
                                appointment: examenAppointment,
                            };
                        }
                    }
                    return { ...reco, statusColor: now < moment(reco.dueAt).unix() ? "orange" : "red" };
                })
                .filter(({ appointment = null }) => appointment === null)
                .sort((a, b) => new Date(a.appointment?.date || a.dueAt) - new Date(b.appointment?.date || b.dueAt))
        );
    }, [diagnosis.recos, examenAppointments]);

    const [examAppointmentsPlanned, examAppointmentsDone] = React.useMemo(
        () => [
            examenAppointments
                .filter((item) => item.doneAt === null)
                .sort((a, b) => new Date(b.doneAt) - new Date(a.doneAt)),
            examenAppointments.filter((item) => item.doneAt !== null).sort((a, b) => (a.dueAt > b.dueAt ? -1 : 1)),
        ],
        [examenAppointments]
    );

    React.useEffect(() => {
        tryFetchPatientAppointments(patientId).finally(() => setLoading(false));
    }, []);

    return (
        <PageTitle title="Timkl - Mes Patients">
            <PatientNavLayout patientId={patientId} onLoad={onLoad}>
                {
                    <div className="dashboardContent__element">
                        <div className="consultsList">
                            {diagnosisRecosLoading ? (
                                <DefaultLoader />
                            ) : (
                                examRecos.map((recoInstance) => (
                                    <AppointmentRecommended
                                        key={recoInstance.id}
                                        dueAt={recoInstance.dueAt}
                                        statusColor={recoInstance.statusColor}
                                        recommendation={recoInstance.recommendation}
                                        showDescription={true}
                                        exams={diagnosis.recos.filter(
                                            (r) =>
                                                r.recommendation.consultationName === recoInstance.recommendation.title
                                        )}
                                        patientIri={"/patients/" + patientId}
                                        searchPractitionerAction={tryFetchPractitioners}
                                        createAppointmentLoading={patients.createAppointmentLoading}
                                        createAppointmentAction={tryCreatePatientAppointment}
                                        //disableCreateAppointment={userCan("ROLE_DOCTOR")}
                                    />
                                ))
                            )}
                        </div>
                        <br />
                        <br />
                        {/* Examens planifiés */}
                        <div className="contentHolder__title form__group d-flex align-items-center">
                            <Icon name="examens" className="contentHolder__title-icon mb-0 mr-1" />
                            <h1 className="title-1">Examens planifiés</h1>
                        </div>
                        <div className="consultsList">
                            {loading ? (
                                <DefaultLoader />
                            ) : (
                                examAppointmentsPlanned &&
                                examAppointmentsPlanned.length > 0 &&
                                examAppointmentsPlanned.map((appointment) => (
                                    <AppointmentScheduled
                                        key={appointment.id}
                                        appointment={appointment}
                                        confirmAppointmentLoading={patients.editAppointmentLoading}
                                        confirmAppointmentAction={tryEditPatientAppointment}
                                    />
                                ))
                            )}
                        </div>
                        <br />
                        <br />
                        <div className="contentHolder__title form__group d-flex align-items-center">
                            <Icon name="examens" className="contentHolder__title-icon mb-0 mr-1" />
                            <h1 className="title-1">Examens réalisés</h1>
                        </div>
                        <div className="consultsList">
                            {loading ? (
                                <DefaultLoader />
                            ) : (
                                examAppointmentsDone.map((appointment) => (
                                    <AppointmentRealised key={appointment.id} appointment={appointment} />
                                ))
                            )}
                        </div>
                    </div>
                }
            </PatientNavLayout>
        </PageTitle>
    );
};

PatientsNavExamsScreen.propTypes = {
    diagnosis: PropTypes.object.isRequired,
    patients: PropTypes.object.isRequired,
    tryCreatePatientAppointment: PropTypes.func.isRequired,
    tryEditPatientAppointment: PropTypes.func.isRequired,
    tryFetchDiagnosisRecos: PropTypes.func.isRequired,
    tryFetchPatientAppointments: PropTypes.func.isRequired,
    tryFetchPractitioners: PropTypes.func.isRequired,
};

const mapStateToProps = ({ diagnosis, patients }) => ({ diagnosis, patients });

const mapDispatchToProps = {
    tryCreatePatientAppointment,
    tryEditPatientAppointment,
    tryFetchDiagnosisRecos,
    tryFetchPatientAppointments,
    tryFetchPractitioners,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientsNavExamsScreen);
