import React from "react";

import BaseScreen from './BaseScreen';

const DataPolicyScreen = () => (
    <BaseScreen
        title='DataPolicy'
        content='Data policy'
    />
);

DataPolicyScreen.propTypes = {};

export default DataPolicyScreen;
