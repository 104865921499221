import React from "react";

import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import { DefaultLoader, Icon, InputCheckbox, Modal, PageTitle } from "@rdcs/dap-front-library";

import Task from "./Task";

import {
    tryCreatePatientTask,
    tryEditPatientTask,
    tryFetchPatientTasks,
    tryFetchTaskLabels,
    trySinglePatient,
} from "../../../../actions/patients";

import PatientNavLayout from "../../../../components/Patients/PatientNavLayout";
import PatientTaskForm from "../../../../components/Patients/PatientTaskForm";

const PatientsNavTasksScreen = ({
    createTaskLoading,
    patientPractitioners,
    patientTasks,
    taskLabels,
    tryCreatePatientTask,
    tryEditPatientTask,
    tryFetchPatientTasks,
    tryFetchTaskLabels,
    trySinglePatient,
}) => {
    const { id } = useParams();
    const patientId = React.useMemo(() => parseInt(id), [id]);

    const createTaskRef = React.useRef();

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        Promise.all([tryFetchPatientTasks(patientId), trySinglePatient(patientId), tryFetchTaskLabels()]).finally(() =>
            setLoading(false)
        );
    }, []);

    return (
        <PageTitle title="Timkl - Mes Patients">
            <PatientNavLayout patientId={patientId}>
                {loading ? (
                    <DefaultLoader />
                ) : (
                    <>
                        <div className="dashboardContent__element">
                            <div className="dashboardContent__title">
                                <div className="dashboardContent__titleItem">
                                    <Icon name="actions" size="30px" />
                                    <span className="dashboardContent__titleText">Liste des actions</span>
                                </div>
                            </div>
                            <div className="tasksList">
                                {patientTasks
                                    .sort((a, b) => {
                                        if (a.done && !b.done) return 1;
                                        if (!a.done && b.done) return -1;
                                        return new Date(a.dueAt).getTime() - new Date(b.dueAt).getTime();
                                    })
                                    .map((task) => (
                                        <InputCheckbox
                                            key={task.id}
                                            label={<Task task={task} />}
                                            defaultChecked={task.done}
                                            onChange={(done) =>
                                                tryEditPatientTask({
                                                    id: task.id,
                                                    done: done,
                                                })
                                            }
                                        />
                                    ))}
                            </div>
                            <div>
                                <Modal
                                    buttonContent={
                                        <div className="reportsListItem__add">
                                            <Icon name="add" size="30px" />
                                            <span className="reportsListItem__add-text">Ajouter une action</span>
                                        </div>
                                    }
                                    ref={createTaskRef}
                                    isSmall
                                >
                                    <div className="modal__title modal__title--small">Ajouter une action</div>
                                    <PatientTaskForm
                                        patientId={patientId}
                                        patientPractitioners={patientPractitioners}
                                        taskLabels={taskLabels}
                                        isLoading={createTaskLoading}
                                        onSubmit={(form) => {
                                            tryCreatePatientTask(form).then(() => {
                                                createTaskRef.current.toggleModal();
                                            });
                                        }}
                                    />
                                </Modal>
                            </div>
                        </div>
                    </>
                )}
            </PatientNavLayout>
        </PageTitle>
    );
};

PatientsNavTasksScreen.propTypes = {
    createTaskLoading: PropTypes.bool.isRequired,
    patientPractitioners: PropTypes.arrayOf(PropTypes.object).isRequired,
    patientTasks: PropTypes.arrayOf(PropTypes.object).isRequired,
    taskLabels: PropTypes.arrayOf(PropTypes.object).isRequired,
    tryCreatePatientTask: PropTypes.func.isRequired,
    tryEditPatientTask: PropTypes.func.isRequired,
    tryFetchPatientTasks: PropTypes.func.isRequired,
    tryFetchTaskLabels: PropTypes.func.isRequired,
    trySinglePatient: PropTypes.func.isRequired,
};

const mapStateToProps = ({ patients: { createTaskLoading, patientTasks, singlePatient, taskLabels } }) => ({
    createTaskLoading,
    patientPractitioners: singlePatient?.accessedBy || [],
    patientTasks,
    taskLabels,
});

const mapDispatchToProps = {
    tryCreatePatientTask,
    tryEditPatientTask,
    tryFetchPatientTasks,
    tryFetchTaskLabels,
    trySinglePatient,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientsNavTasksScreen);
