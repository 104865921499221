import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    Button,
    CheckboxGroup,
    constant,
    DefaultLoader,
    Icon,
    InputText,
    Modal,
    PageTitle,
} from "@rdcs/dap-front-library";

import getTreatmentListOption from "./getTreatmentListOption";
import useState from "./useState";

import { tryFetchRecommandations } from "../../../actions/recommandations";
import { tryFetchTreatments } from "../../../actions/treatments";

import AppLayout from "../../../components/Layout/AppLayout";

const breadcrumbs = [{ label: "Recommandations" }];

const RecommandationsScreen = ({ recommandations, treatments, tryFetchRecommandations, tryFetchTreatments }) => {
    const modalRef = React.useRef();

    const [
        { filteredList, search, pathologiesClicked, treatmentsClicked, dataLoading },
        { init, endLoad, setFilteredList, setSearch, setPathologiesClicked, setTreatmentsClicked },
    ] = useState();

    const treatmentListOption = React.useMemo(() => getTreatmentListOption(treatments.list), [treatments.list]);

    const searchCallback = (event) => {
        event.preventDefault();
        const mySearchToLowerCase = search.toLowerCase();

        // String filter
        let myFilteredList = recommandations.list.filter((item) => {
            // Get all treatments and pathologies in an array
            const allTreatments = item.treatments.map((element) => element.name.toLowerCase());
            const allPathologies = constant("pathologies")
                .filter((pathology) => {
                    const key = Object.keys(item).find((k) => k.toLowerCase().endsWith(pathology.toLowerCase()));
                    return item[key];
                })
                .map((pathology) => constant("pathologies", pathology).toLowerCase());

            /* Condition, check if string is in :
                - pathology
                - treatment
                - title
                - description
                If empty show everything
            */
            switch (true) {
                case mySearchToLowerCase === "":
                    return true;
                case mySearchToLowerCase !== "" && allPathologies.indexOf(mySearchToLowerCase) !== -1:
                    return true;
                case mySearchToLowerCase !== "" && allTreatments.indexOf(mySearchToLowerCase) !== -1:
                    return true;
                case mySearchToLowerCase !== "" && item.title.toLowerCase().indexOf(mySearchToLowerCase) !== -1:
                    return true;
                case mySearchToLowerCase !== "" &&
                    item.description !== null &&
                    item.description.toLowerCase().indexOf(mySearchToLowerCase) !== -1:
                    return true;
                default:
                    break;
            }
            return false;
        });

        // Pathology Filter
        myFilteredList = myFilteredList.filter((item) => {
            const allPathologies = constant("pathologies").filter((pathology) => {
                const key = Object.keys(item).find((k) => k.toLowerCase().endsWith(pathology.toLowerCase()));
                return item[key];
            });
            // Condition, check if there's a checkbox clicked in <CheckboxGroup />
            if (pathologiesClicked.length !== 0) {
                // Condition, check if pathology clicked match with pathology's recommandations
                return pathologiesClicked.map((pathology) => allPathologies.includes(pathology)).includes(true);
            }
            return true;
        });

        // Treatments Filter
        myFilteredList = myFilteredList.filter((item) =>
            // Condition, check if there's a checkbox clicked in <CheckboxGroup />
            treatmentsClicked.length !== 0
                ? // Check if treatments clicked match with treatments' recommandations (treatmentDidMatch is an array with [true, false, true etc.])
                  item.treatments.map((treatment) => treatmentsClicked.includes(treatment["@id"])).includes(true)
                : true
        );

        // Put filtered (string, pathology then treatment) to state
        setFilteredList(myFilteredList);
    };

    React.useEffect(() => {
        Promise.all([tryFetchRecommandations(), tryFetchTreatments()])
            .then(([recommandationsList]) => init(recommandationsList))
            .catch(endLoad);
    }, []);

    return (
        <PageTitle title="Timkl - Recommandations de la HAS">
            <AppLayout navActive={1} breadcrumbs={breadcrumbs}>
                <header className="headerPage">
                    <div className="headerPage__title">
                        <h1 className="title-1">Recommandations de la HAS</h1>
                    </div>

                    <div className="headerPage__actions">
                        <Modal
                            buttonClass="headerPage__actions-item button button__primaryButton"
                            buttonContent={<span>Filtrer</span>}
                            ref={modalRef}
                        >
                            <div className="modal__title">Filtrer</div>
                            <form
                                action="#search"
                                className="formSearch blockList__header-action blockList__header-search"
                                onSubmit={searchCallback}
                            >
                                <InputText
                                    label="Rechercher dans le tableau"
                                    name="search"
                                    placeholder="Rechercher..."
                                    onChange={setSearch}
                                />
                                <CheckboxGroup
                                    options={constant("pathologies").map((pathology) => ({
                                        label: constant("pathologies", pathology),
                                        value: pathology,
                                    }))}
                                    label="Pathologies"
                                    onChange={setPathologiesClicked}
                                />
                                <CheckboxGroup
                                    options={treatmentListOption}
                                    label="Traitements"
                                    onChange={setTreatmentsClicked}
                                />
                                <div className="form__group text-center">
                                    <Button
                                        type="submit"
                                        className="button__secondaryButton text-center"
                                        onClick={() => modalRef.current.toggleModal()}
                                    >
                                        <span className="mr-2">Chercher</span> <Icon name="search" />
                                    </Button>
                                </div>
                            </form>
                        </Modal>
                    </div>
                </header>

                <div>
                    <table className="recommendationsList">
                        <thead>
                            <tr className="recommendationsList__headRow">
                                <th className="recommendationsList__case recommendationsList__case--small">DT</th>
                                <th className="recommendationsList__case">Traitements</th>
                                <th className="recommendationsList__case">Titre</th>
                                <th className="recommendationsList__case">Description</th>
                                <th className="recommendationsList__case recommendationsList__case--small">Lien</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataLoading ? (
                                <tr className="recommendationsList__row">
                                    <td className="recommendationsList__case" colSpan="5">
                                        <DefaultLoader />
                                    </td>
                                </tr>
                            ) : filteredList.length === 0 ? (
                                <tr className="recommendationsList__row">
                                    <td className="recommendationsList__case" colSpan="5">
                                        Aucune recommandation
                                    </td>
                                </tr>
                            ) : (
                                filteredList.map((recommendation) => (
                                    <tr key={recommendation["@id"]} className="recommendationsList__row">
                                        <td className="recommendationsList__case recommendationsList__case--small">
                                            {constant("pathologies")
                                                .filter((pathology) => {
                                                    const key = Object.keys(recommendation).find((k) =>
                                                        k.toLowerCase().endsWith(pathology.toLowerCase())
                                                    );
                                                    return recommendation[key];
                                                })
                                                .map((pathology, index) => (
                                                    <p key={index}>{constant("pathologies", pathology)}</p>
                                                ))}
                                        </td>
                                        <td className="recommendationsList__case">
                                            {recommendation.treatments.length > 0
                                                ? recommendation.treatments.map((item, index) => (
                                                      <p key={index}>- {item.name}</p>
                                                  ))
                                                : ""}
                                        </td>
                                        <td className="recommendationsList__case">{recommendation.title}</td>
                                        <td className="recommendationsList__case">
                                            {recommendation.goal
                                                ? "Inférieur à " + recommendation.goal + "%"
                                                : recommendation.description}
                                        </td>
                                        <td className="recommendationsList__case recommendationsList__case--small">
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={recommendation.source}
                                                title={"Source : " + recommendation.source}
                                            >
                                                <Icon name="pdf" />
                                            </a>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </AppLayout>
        </PageTitle>
    );
};

RecommandationsScreen.propTypes = {
    recommandations: PropTypes.object.isRequired,
    treatments: PropTypes.object.isRequired,
    tryFetchRecommandations: PropTypes.func.isRequired,
    tryFetchTreatments: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, recommandations, treatments }) => ({ auth, recommandations, treatments });

const mapDispatchToProps = {
    tryFetchRecommandations,
    tryFetchTreatments,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecommandationsScreen);
