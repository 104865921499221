import * as types from "./types";

import { toast } from "react-toastify";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from '../store';

const getNote = (access_token, patientId) => (
    API.request({
        path: isLocalAPI ? "/notes" : "/dap-hcp-patient/api/v1/customers/patients/notes",
        queryParams: {
            patient: patientId
        },
        token: access_token
    })
);

export const tryFetchNotes = patientId => dispatch => {
    const {
        auth: {
            access_token,
        },
        notes: {
            list,
            listLoaded,
            listPatientId
        }
    } = store.getState();

    if (listLoaded && listPatientId === patientId) {
        return Promise.resolve(list);
    }

    dispatch({ type: types.NOTES_FETCH_REQUESTED });
    return getNote(access_token, patientId)
    .then(response => dispatch({ type: types.NOTES_FETCH_SUCCESS, data: response["hydra:member"], patientId }))
    .catch(error => dispatch({ type: types.NOTES_FETCH_FAILURE, data: error }));
};

export const tryCreateNote = data => dispatch => {
    const {
        auth: {
            access_token,
        },
    } = store.getState();

    dispatch({ type: types.NOTES_CREATE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/notes" : "/dap-hcp-patient/api/v1/customers/patients/notes",
        method: "POST",
        token: access_token,
        data: data
    })
    .then(response => dispatch({ type: types.NOTES_CREATE_SUCCESS, data: response }))
    .catch(error => dispatch({ type: types.NOTES_CREATE_FAILURE, data: error }));
};

export const tryEditNote = (id, form) => dispatch => {
    const {
        auth: {
            access_token,
        },
    } = store.getState();

    dispatch({ type: types.EDIT_NOTE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/notes/{id}" : "/dap-hcp-patient/api/v1/customers/patients/notes/{id}",
        uriParams: {
            id
        },
        token: access_token,
        method: "PUT",
        data: form
    })
    .then(({ patient: { id } }) => {
        toast.success("La note a été modifié");
        getNote(access_token, id)
        .then((response) => dispatch({ type: types.EDIT_NOTE_SUCCESS, data: response["hydra:member"], patientId: id }))
        .catch(() => {
            toast.error("Une erreur s'est produite lors de la récupération de la note.");
        });
    })
    .catch(error => {
        toast.error("La note n'a pas pu être modifiée");
        dispatch({ type: types.EDIT_NOTE_FAILURE, data: error });
        throw error;
    })
};

export const tryRemoveNote = id => dispatch => {
    const {
        auth: {
            access_token,
        },
    } = store.getState();

    dispatch({ type: types.NOTE_REMOVE_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/notes/{id}" : "/dap-hcp-patient/api/v1/customers/patients/notes/{id}",
        uriParams: {
            id
        },
        token: access_token,
        method: "PUT",
        data: { active: false }
    })
    .then(response => dispatch({ type: types.NOTE_REMOVE_SUCCESS, data: response }))
    .catch(error => dispatch({ type: types.NOTE_REMOVE_FAILURE, data: error }));
};
