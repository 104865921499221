let activeRequests = [];

export const addActiveRequest = (request) => {
    activeRequests.push(request);
};

export const cancelActiveRequests = () => {
    activeRequests.forEach((request) => request.cancel());
    activeRequests = [];
};
