import React from 'react';

const CredentialsDataUsage = () => (
    <div className="credentialsDataUsage">
        <div className="credentialsDataUsage__content mb-0 mt-2">
            <p>
                Timkl SAS (565 rue Aristide Bergès – 38330 Montbonnot-Saint-Martin) a la qualité
                de prestataire de service et distributeurs de matériels au sens de l’article
                L.5232-3 du Code de la Santé Publique. Il est responsable de traitement au sens
                du Règlement Général sur la Protection des Données (RGPD) concernant vos données
                collectées dans le cadre de la plateforme « Diabetics Adherence Platform »
                éditée par le groupe Roche.
            </p>
            <p>
                L’objet de ces traitements est de permettre une coordination du parcours de soin
                des patients diabétiques par une infirmière Timkl conformément aux
                recommandations de la Haute Autorité de Santé.
            </p>
            <p>
                Les données contenues dans la plateforme proviennent des professionnels de santé
                impliqués dans le parcours de soin et de la part du patient directement. Elles
                ont vocation à permettre :<br />
                1) De vous identifier en tant que patient afin d’éviter tout doublon puis
                lorsque vous accédez et effectuez des actions sur la plateforme (conformément à
                nos obligations légales de sécurité) : vos données d’identification sont
                conservées pour la durée d’activité de votre compte augmentée d’un an et les
                logs sont conservés cinq ans à compter de la collecte.
                <br />
                2) De générer votre parcours de soin et permettre votre suivi et de communiquer
                avec vous (y compris par contact téléphonique ou e-mail) par l’infirmière
                coordinatrice Timkl (dans la mesure nécessaire à l’administration des soins) :
                les données de votre compte sont conservées pour la durée d’activité de ce
                dernier puis archivées 10 ans sauf indication contraire de votre part.
                <br />
                3) De partager ces données avec les professionnels que vous choisissez (avec
                votre consentement au partage qui vous sera demandé au cas par cas) : le partage
                sera actif jusqu’à ce que vous choisissiez de désactiver les accès à l’issue de
                la prise en charge.
                <br />
                4) De générer des statistiques quant aux actions techniques sur la plateforme de
                façon non individuelle (uniquement dans le cadre de notre intérêt légitime
                d’amélioration des services et avec votre consentement qui vous sera demandé en
                cas d’accès non nécessaire à votre appareil): ces données seront traitées pour
                une durée n’excédant pas quelques dizaines de mois.
            </p>
            <p>
                Pour pouvoir vous fournir nos services, nous collaborons régulièrement avec des
                tiers internes ou externes au groupe Roche en Europe, par exemple des affiliées
                de Roche qui appartiennent au groupe Roche/F. Hoffmann-La Roche Ltd. Les
                prestations d’authentification sont réalisées par Gigya, un service fourni par
                SAP Inc. en Irlande; les données de santé sont hébergées en Allemagne par AWS
                (infrastructure) et en Espagne par Accenture (administration) qui sont certifiés
                à cette fin au regard des exigences du Code de la Santé Publique. Nous pouvons
                partager les données traitées sur la Plateforme avec les forces de l’ordre,
                organismes publics ou des tiers autorisés au sens du RGPD en réponse à une
                demande en ce sens ayant fait l’objet d’une vérification, ainsi qu’avec les
                autorités de santé françaises autorisées et la caisse d’assurance maladie.
            </p>
            <p>
                Vous disposez des droits d’accès, de rectification et d’effacement de vos
                données à caractère personnel, des droits de vous opposer ou de limiter le
                traitement de vos données à caractère personnel, du droit à la portabilité des
                données à caractère personnel, de retirer votre consentement à tout moment et du
                droit de définir des directives relatives au sort de vos données à caractère
                personnel après votre mort, directement sur la Plateforme ou en vous adressant à
                dpo.timkl@timkl.fr. Vous avez le droit d’adresser une réclamation à l’autorité
                de contrôle compétente : Commission nationale de l'informatique et des libertés
                - 3 Place de Fontenoy – 75007 Paris.
                <br />
            </p>
        </div>
    </div>
);

CredentialsDataUsage.propTypes = {};

export default React.memo(CredentialsDataUsage);