import React from "react";
import PropTypes from "prop-types";

import { HeaderCard, ButtonUpload } from "@rdcs/dap-front-library";

const ConcentUpload = ({ onUpload }) => (
    <HeaderCard
        title='Télécharger le consentement'
        subtitle='Pour cela, scanner ce document rempli et signé par le patient.'
        icon='consentement'
    >
        <ButtonUpload 
            required={true}
            uploadAction={onUpload}
            icon='document-import'
        >
            Charger un consentement signé
        </ButtonUpload>
    </HeaderCard>
);

ConcentUpload.propTypes = {
    onUpload: PropTypes.func.isRequired,
};

export default ConcentUpload;
