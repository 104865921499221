import React from "react";
import PropTypes from 'prop-types';

import { connect } from "react-redux";
import { tryEditNote, tryRemoveNote } from "../../actions/notes";

import { getViolations, NoteCard } from "@rdcs/dap-front-library";

import PatientNoteForm from "../Patients/PatientNoteForm";

const NoteItem = ({
    patientId,
    note,
    userId,
    notes, 
    tryEditNote,
    tryRemoveNote
}) => (
    <NoteCard
        note={note}
        userId={userId}
        editNoteForm={(noteId, modalRef) => (
            <PatientNoteForm
                patientId={patientId}
                note={note}
                onSubmit={form => 
                    tryEditNote(noteId, form).then(() => {
                        modalRef.current?.toggleModal();
                    })
                }
                isLoading={notes.editLoading}
                errors={getViolations(notes.editErrors)}
            />
        )}
        deleteLoading={notes.deleteLoading}
        onDelete={tryRemoveNote}
    />
);

NoteItem.propTypes = {
    patientId: PropTypes.number.isRequired,
    note: PropTypes.object.isRequired,
    userId: PropTypes.number.isRequired,
    notes: PropTypes.object.isRequired, 
    tryEditNote: PropTypes.func.isRequired,
    tryRemoveNote: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { user: { id } }, notes }) => ({ userId: id, notes });

const mapDispatchToProps = {
    tryEditNote,
    tryRemoveNote
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteItem);
