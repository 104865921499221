import React from "react";
import PropTypes from 'prop-types';

import { Animated } from "react-animated-css";
import { Icon, Form, InputCheckbox, StepButtons, HeaderCard } from "@rdcs/dap-front-library";

import PDFConsults from "../PDFConsults";

import sortArrayByDueAtAndPosition from './sortArrayByDueAtAndPosition';

import DiagnosticReco from './DiagnosticReco';

import useState from './useState';

const maxRecoShown = 5;

const DiagnosisStep4 = ({
    recos,
    patient,
    hcp,
    nextStep,
    prevStep,
    isLoading,
}) => {
    const [allRecoHide, hideAllReco] = React.useState(true);

    const diagnosticRecoMandatory = React.useMemo(() => sortArrayByDueAtAndPosition(
        recos.filter(r => r.recommendation.mandatory)
    ), [recos]);
    const diagnosticRecoNotMandatory = React.useMemo(() => {
        let diagnosticRecoNotMandatoryLocal = sortArrayByDueAtAndPosition(recos.filter(r => !r.recommendation.mandatory));

        if (allRecoHide) {
            diagnosticRecoNotMandatoryLocal = diagnosticRecoNotMandatoryLocal.slice(0, maxRecoShown);
        }

        return diagnosticRecoNotMandatoryLocal;
    }, [recos, allRecoHide]);

    const [{
        isInit,
        allChecked,
        refs,
    }, {
        initAllChecked,
        updateAllChecked,
    }] = useState(diagnosticRecoNotMandatory);

    const diagnostics = React.useMemo(() => sortArrayByDueAtAndPosition(recos.filter(r => r.active === true)), [recos]);
    const checkAll = React.useCallback(val => {
        if (val !== allChecked) {
            for (const index in refs) {
                const current = refs[index].current;

                if (val !== current.checked) {
                    current.setChecked(val);
                }
            }
        }
    }, [allChecked, refs]);

    return (
        <Animated animationIn="fadeIn">
            <HeaderCard
                title='Recommandations générées'
                subtitle='En fonction des indications que vous avez données sur votre patient, les recommandations ci-dessous sont générées sur la base des guides des parcours de soin HAS.'
                icon='questionnaire'
            >
                <PDFConsults diagnostics={diagnostics} patient={patient} hcp={hcp} nbOfMonths={3} />
                <PDFConsults diagnostics={diagnostics} patient={patient} hcp={hcp} nbOfMonths={12} />
            </HeaderCard>
            <div className="mt-4">
                <h2 className="title-2">Suivi recommandé par la HAS</h2>
                {diagnosticRecoMandatory.map(reco => (
                    <DiagnosticReco
                        key={reco.id}
                        reco={reco}
                        //renderRightSection={<RecoRightSection />}
                        disabled
                        ref={refs[reco.id]}
                    />
                ))}
            </div>
            <Form onSubmit={nextStep} onLoad={initAllChecked} onUpdateField={updateAllChecked}>
                <div className="mt-4">
                    <h2 className="title-2">Recommandations optionnelles</h2>
                    {isInit &&
                        <InputCheckbox
                            name='all'
                            label={allChecked ? 'Tout décocher' : 'Tout cocher'}
                            onChange={checkAll}
                            checked={allChecked}
                        />}
                    {diagnosticRecoNotMandatory.map(reco => (
                        <DiagnosticReco
                            key={reco.id}
                            reco={reco}
                            ref={refs[reco.id]}
                        />
                    ))}
                    {allRecoHide &&
                        <div
                            className='mt-4 cursorPointer txtChildUnderlineHover d-inline-flex items-align-center spo__color--gray-8 text-uppercase'
                            onClick={() => hideAllReco(false)}
                        >
                            <Icon name='arrow-down' className='mr-1' size={24} />
                            <p className='childTransform--underline'>afficher tous les recours spécialistes</p>
                        </div>}
                </div>
                <StepButtons
                    prevStep={prevStep}
                    isLoadingNext={isLoading}
                    isLast
                    prevButtonClassName='text-uppercase'
                    nextButtonClassName='text-uppercase'
                />
            </Form>
        </Animated>
    );
};

DiagnosisStep4.propTypes = {
    recos: PropTypes.arrayOf(PropTypes.shape({
        active: PropTypes.bool,
    })).isRequired,
    patient: PropTypes.object.isRequired,
    hcp: PropTypes.object.isRequired,
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default DiagnosisStep4;
