import React from 'react';

const defaultState = {
    loading: true,
    patients: [],
    page: 1,
};

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'CHANGE_PAGE':
            return {
                ...prevState,
                loading: true,
                page: action.page,
            }
        case 'LOAD_PATIENTS_PAGE':
            return {
                ...prevState,
                loading: false,
                patients: action.patients,
            }
        case 'ERROR_LOAD':
            return {
                ...prevState,
                loading: false,
                patients: [],
            }
        default:
            return prevState;
    }
};

const useState = () => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const methods = React.useMemo(() => ({

        changePage: (page) => dispatch({ type: 'CHANGE_PAGE', page }),

        loadPatientsPage: (currentPage, patients) => dispatch({ type: 'LOAD_PATIENTS_PAGE', patients, currentPage }),

        errorLoad: (currentPage) => dispatch({ type: 'ERROR_LOAD', currentPage }),

    }), []);

    return [state, methods];
};

export default useState;