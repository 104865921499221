import React from "react";
import PropTypes from 'prop-types';

const BloodSugarHeaderContent = ({ title, hasSeparator = true, children }) => (
    <div className="dashboardAction">
        <section className="bloodSugarHeader__content">
            {hasSeparator ? <div className='bloodSugarHeader__separator' /> : undefined}
            {title ? <nobr className="dashboardAction__title">{title}</nobr> : undefined}
            {children}
        </section>
    </div>
);

BloodSugarHeaderContent.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]),
    hasSeparator: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]).isRequired,
};

export default BloodSugarHeaderContent;