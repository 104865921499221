import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { PrivateRoute as PrivateRouteLib } from "@rdcs/dap-front-library";

import AccessDeniedExceptionScreen from "../../screens/Exceptions/AccessDeniedException";

const PrivateRoute = (props) => <PrivateRouteLib {...props} notAllowedFallback={<AccessDeniedExceptionScreen />} />;

PrivateRoute.propTypes = {
    allowedRoles: PropTypes.arrayOf(PropTypes.string.isRequired),
    user: PropTypes.shape({
        roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }),
};

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(PrivateRoute);
