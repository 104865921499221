import React from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { tryCreateProfile, tryFetchProfileConsents } from "../../../actions/profile";

import {
    Button,
    getViolations,
    Icon,
    InputNumber,
    PageTitle,
    PractitionerLayoutAnonymous,
    PractitionerLayoutAsideAnonymous,
} from "@rdcs/dap-front-library";

import SignInForm from "../../../components/SignIn/SignInForm";

import { isLocalAPI } from "../../../utils/utils";
import API from "../../../utils/API";

import useState from "./useState";

const SignInScreen = ({ auth, profile, tryCreateProfile, tryFetchProfileConsents }) => {
    const navigate = useNavigate();
    const [
        { rpps, rppsError, rppsLoading, practitioner },
        { setRpps, rppsLoad, setPractitionerFromRpps, setRppsError, forgetPractitioner },
    ] = useState();

    React.useEffect(() => {
        tryFetchProfileConsents();
    }, []);

    const findPractitionerByRPPS = (e) => {
        e.preventDefault();

        rppsLoad();

        API.request({
            path: isLocalAPI ? "/rpps_practitioners" : "/dap-hcp-patient/api/v1/customers/practitioners/rpps",
            queryParams: {
                rppsNumber: rpps,
            },
        }).then((response) => {
            if (response["hydra:member"].length > 0) {
                setPractitionerFromRpps(response["hydra:member"][0]);
            } else {
                setRppsError();
            }
        });
    };

    const createProfile = (form) => {
        tryCreateProfile(form, practitioner.creationEndpoint, auth.access_token)
            .then(() => {
                navigate("/");
            })
            .catch(() => undefined);
    };

    return (
        <PageTitle title="Timkl - Inscription">
            <PractitionerLayoutAnonymous>
                {!practitioner ? (
                    <div className="grid credentials__container">
                        <PractitionerLayoutAsideAnonymous />
                        <main className="col-sm-50 credentialsMain__container">
                            <form className="credentialsMain" onSubmit={findPractitionerByRPPS}>
                                <Link to="/connexion" className="credentialsMain__back">
                                    <Icon name="arrow-left" /> &nbsp; Retour
                                </Link>
                                <h1 className="credentials__title">S'inscrire</h1>
                                {auth.gigyaUser && (
                                    <div className="bgNotice radius shadow mt-4 mb-2 pt-2 pl-3 pb-2 pr-3">
                                        Inscription avec votre compte Gigya : {auth.gigyaUser.profile.email}
                                    </div>
                                )}
                                <p>
                                    Accompagnez facilement vos patients diabétiques en appliquant les recommandations de
                                    la HAS.
                                </p>
                                <InputNumber
                                    label="Votre numéro RPPS ou ADELI"
                                    legend="Cela permet de nous assurer de votre qualité de praticien référencé et autorisé à pratiquer."
                                    placeholder="Saisir votre numéro"
                                    name="rpps"
                                    error={rppsError}
                                    onChange={setRpps}
                                    required
                                />
                                <div className="buttons__callToAction">
                                    <Button type="submit" className="button__primaryButton" isLoading={rppsLoading}>
                                        Continuer
                                    </Button>
                                </div>
                            </form>
                        </main>
                    </div>
                ) : (
                    <SignInForm
                        gigyaUser={auth.gigyaUser}
                        consents={profile.profileConsents}
                        practitioner={practitioner}
                        submit={createProfile}
                        loading={profile.profileCreateLoading}
                        violations={getViolations(profile.profileCreateError)}
                        cancel={forgetPractitioner}
                    />
                )}
            </PractitionerLayoutAnonymous>
        </PageTitle>
    );
};

SignInScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    tryCreateProfile: PropTypes.func.isRequired,
    tryFetchProfileConsents: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, profile }) => ({ auth, profile });

const mapDispatchToProps = {
    tryCreateProfile,
    tryFetchProfileConsents,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInScreen);
