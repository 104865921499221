import * as types from "./types";

import { toast } from "react-toastify";

import API from "../utils/API";
import { isLocalAPI } from "../utils/utils";

import store from '../store';
import moment from "moment";

export const tryFetchPartners = () => dispatch => {
    const {
        auth: {
            access_token,
        },
        partners: {
            list,
            listLoaded,
        }
    } = store.getState();

    if (listLoaded) {
        return Promise.resolve(list);
    }

    dispatch({ type: types.PARTNERS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/partners" : "/dap-hcp-patient/api/v1/partners",
        token: access_token
    })
    .then(response => dispatch({ type: types.PARTNERS_FETCH_SUCCESS, data: response["hydra:member"] }))
    .catch(error => dispatch({ type: types.PARTNERS_FETCH_FAILURE, data: error }));
};

export const sendEmailToPartnerForRecommendation = (recommendationId, patientId) => dispatch => {
    const {
        auth: {
            access_token,
        },
        partners: {
            emailIsSending,
        }
    } = store.getState();

    if (emailIsSending) {
        return Promise.reject(false);
    }
    dispatch({ type: types.PARTNERS_EMAIL_SEND_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/send-email-to-partner-for-recommendation" : "/dap-hcp-patient/api/v1/send-email-to-partner-for-recommendation",
        token: access_token,
        method: "POST",
        data: {
            recommendationId: recommendationId.toString(),
            patientId: patientId.toString()
        }
    })
    .then(response => {
        toast.success("La demande du suivi partenaire a été envoyé avec succès.");
        dispatch({ type: types.PARTNERS_EMAIL_SEND_SUCCESS, data: response['hydra:member'] });
        return response;
    })
    .catch(error => {
        toast.error("Une erreur s'est produite lors de l'envoi de la demande du suivi partenaire.");
        dispatch({ type: types.PARTNERS_EMAIL_SEND_FAILURE });
        return error;
    });
}

export const tryFetchCurrentPartnerCheckUps = patientId => dispatch => {
    const {
        auth: {
            access_token,
        },
        partners: {
            checkUps,
            checkUpsLoaded,
            checkUpsPatientId,
            checkUpsLoading
        }
    } = store.getState();

    if (checkUpsLoading) {
        return Promise.resolve([]);
    }

    if (checkUpsLoaded && checkUpsPatientId === patientId) {
        return Promise.resolve(checkUps);
    }

    dispatch({ type: types.PARTNER_CHECK_UPS_FETCH_REQUESTED });
    return API.request({
        path: isLocalAPI ? "/partner_check_ups" : "/dap-hcp-patient/api/v1/partner-check-ups",
        token: access_token,
        queryParams: {
            "createdAt[after]": moment().subtract(11, 'months').format('YYYY-MM-DD'),
            patient: patientId
        }
    })
    .then(response => dispatch({ type: types.PARTNER_CHECK_UPS_FETCH_SUCCESS, data: response["hydra:member"], patientId }))
    .catch(error => dispatch({ type: types.PARTNER_CHECK_UPS_FETCH_FAILURE, data: error }));
};