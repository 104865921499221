import { useNavigate } from "react-router-dom";

const useRemoveError = () => {
    const navigate = useNavigate();

    const removeError = () => {
        navigate(".", { replace: true, state: { userDisabled: null } });
    };

    return removeError;
};

export default useRemoveError;
